import React from "react";
import {
  NewUserCreation,
  ProExtension,
  GetUserTest,
  ProUserData,
  IeltsProUsers,
  DashboardCustomization,
} from "./IeltsUtilities";
import { useNavigate } from "react-router-dom";
import { useStore } from "../Hooks/use-store";

const IELTSMarketingUtilities = () => {
  const [actionType, setActionType] = React.useState();

  const { userStore } = useStore();

  const navigate = useNavigate();

  const handleActionChange = (e: any) => {
    let value = e.target.value;
    setActionType(value);
  };

  const logoutHandler = () => {
    userStore.clearStoredDate();
    navigate("/login");
    localStorage.clear();
  };

  const customComponent = (action: any) => {
    switch (action) {
      case "newUser":
        return <NewUserCreation />;
      case "proExtension":
        return <ProExtension />;
      case "getTests":
        return <GetUserTest />;
      case "proUsers":
        return <ProUserData />;
      case "ieltsProUsers":
        return <IeltsProUsers />;
      case "dashboardChange":
        return <DashboardCustomization />;
    }
  };

  const isPromotionalUser = userStore.operationalUser;

  return (
    <>
      { isPromotionalUser ? (
        <>
          <header className="w-full bg-white flex justify-between py-5 px-10 text-3xl border-b">
            <p className="">Utilities</p>
            <button onClick={logoutHandler} className="cursor-pointer">
              Logout
            </button>
          </header>
          <body className="m-10">
            <div className="text-xl">
              <p>Choose an action</p>
              <select
                className="w-72 border rounded px-2 py-1"
                value={actionType}
                onChange={(e) => handleActionChange(e)}
              >
                <option>--select an option--</option>
                <option value="newUser">Pro plan for New User </option>
                <option value="proExtension">Pro plan for Old User</option>
                <option value="dashboardChange">User Module Access</option>
                <option value="getTests">User Test's count</option>
                <option value="proUsers">IELTS Dashboard</option>
                <option value="ieltsProUsers">Ielts All Pro Users</option>
              </select>
            </div>
            <div className="my-10 relative">{customComponent(actionType)}</div>
          </body>
        </>
      ) : (
        <div className="h-screen flex">
          <h2 className="text-xl w-1/3 px-5 py-10 border border-[#E31837] rounded-xl m-auto">
            Sorry, It seems you don't have access to this page,. please {""}{" "}
            <button
              className="text-blue-500 cursor-pointer underline"
              onClick={() => logoutHandler()}
            >
              log in
            </button>
            {""} to your account
          </h2>
        </div>
      )}
    </>
  );
};

export default IELTSMarketingUtilities;
