  import React from "react";
import { observer } from "mobx-react-lite";
import SingleChoiceQuestions from "./questions/SingleChoiceQuestions";
import MultiAnswerQuestions from "./questions/MultiAnswerQuestions";
import SenetenceCompletion from "./questions/SenetenceCompletion";
import TableCompletion from "./questions/TableCompletion";
import ShortAnswer from "./questions/ShortAnswer";
import NoteCompletionListening from "../Listening/questions/NoteCompletion";
import PlanDiagram from "./questions/PlanDiagram";

interface RenderQuestionsProps {
  item: any;
  handleRadioChange?: (qId: any, answer: any) => void;
  handleRadioChangeTaskIdentifying?: (qId: any, answer: any) => void;
  handleCheckBoxChange?: (qId: any, answer: any, isChecked: any) => void;
  handleChangeShortAnswer?: (qId: any, answer: any) => void;
  replaceWithInputFields?: (
    text: string,
    indexOfSentence: any,
    qId: any
  ) => void; // Adjust this type based on your requirements
  replaceWithInputFieldsSentenceCompletion?: (
    text: string,
    indexOfSentence: any,
    qId: any
  ) => void;
  twoWordAnswerSummaryCompletionInputValues?: (qId: any) => void;
  handleChangeSummaryCompletionOneWordAnswers?: (qId: any, answer: any) => void;
  handlePlanAnswerChange?: (evt: any, key: any) => void;
  handleDragOver: any;
  handleDragStart: any;
  handleDrop: any;
  handleDragStartFromAnswer: any;
  handleDropFromDisplayedItem: any;
  handleSentenceDragStart: any;
  handleDragSentenceStartFromAnswer: any;
  handleDropSentenceFromDisplayedItem: any;
  handleSentenceDrop: any;
  darggedCurrentItem: any;
  dragAnswers: any;
  setDragAnswers: any;
  setDragSentenceAnswers: any;
  darggedCurrentSentence: any;
  setDarggedCurrentSentence: any;
  dragSentenceAnswers: any;
  inputHandler?: (qId: any, answer: any) => void;
  singleAnswers: any;
  multipleAnswers: any;
  tableCompletionAnswer: any;
  taskIdentifyingItemAnswers: any;
  shortAnswerInputValues:any;
  PlanDiagramAnswer: any;
}

const RenderingQuestionsListening: React.FC<RenderQuestionsProps> = ({
  item,
  singleAnswers,
  multipleAnswers,
  tableCompletionAnswer,
  handleRadioChange,
  handleCheckBoxChange,
  replaceWithInputFields,
  handlePlanAnswerChange,
  replaceWithInputFieldsSentenceCompletion,
  inputHandler,
  handleChangeShortAnswer,
  twoWordAnswerSummaryCompletionInputValues,
  handleChangeSummaryCompletionOneWordAnswers,
  handleRadioChangeTaskIdentifying,
  PlanDiagramAnswer,
  shortAnswerInputValues,
  taskIdentifyingItemAnswers,
  handleDragOver,
  handleDragStart,
  handleDrop,
  handleDragStartFromAnswer,
  handleDropFromDisplayedItem,
  handleSentenceDragStart,
  handleDragSentenceStartFromAnswer,
  handleDropSentenceFromDisplayedItem,
  handleSentenceDrop,
  darggedCurrentItem,
  dragAnswers,
  setDragAnswers,
  setDragSentenceAnswers,
  darggedCurrentSentence,
  setDarggedCurrentSentence,
  dragSentenceAnswers,
}) => {
  switch (item["questionType"]) {
    case "Multiple choice":
    case "multiple choice":
      return (
        <SingleChoiceQuestions
          item={item}
          handleRadioChange={handleRadioChange}
          singleAnswers={singleAnswers}
        />
      );
    case "Multiple choice (More than one answer)":
    case "multiple choice (more than one answer)":
      return (
        <MultiAnswerQuestions
          item={item}
          handleCheckBoxChange={handleCheckBoxChange}
          multipleAnswers={multipleAnswers}
        />
      );
    case "Short Answer":
    case "short answer":
      return (
        <ShortAnswer
          item={item}
          handleChangeShortAnswer={handleChangeShortAnswer}
          shortAnswerInputValues={shortAnswerInputValues}
        />
      );
    case "Sentence Completion":
    case "sentence completion":
      return (
        <SenetenceCompletion
          item={item}
          replaceWithInputFieldsSentenceCompletion={
            replaceWithInputFieldsSentenceCompletion
          }
        />
      );
    case "Note Completion":
    case "note completion":
      return (
        <NoteCompletionListening
          item={item}
          replaceWithInputFields={replaceWithInputFields}
        />
      );
    case "Table Completion":
    case "table completion":
      return (
        <TableCompletion
          item={item}
          inputHandler={inputHandler}
          tableCompletionAnswer={tableCompletionAnswer}
        />
      );
    case "plan/map/diagram labelling":
      return (
        <PlanDiagram
          item={item}
          handlePlanAnswerChange={handlePlanAnswerChange}
          PlanDiagramAnswer={PlanDiagramAnswer}
        />
      );
    default:
      return null;
  }
};
export default observer(RenderingQuestionsListening);
