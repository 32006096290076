import { useEffect } from "react";
import { useStore } from "../../Hooks/use-store";
import { useLocation } from "react-router";

const Timer = ({ time, setTime }: any) => {
  const {startTestStore} = useStore();
  const location = useLocation();
  
  

  useEffect(() => {
    let targetTime: number ;
    if(location.pathname === "/part2" ||
    (location.pathname === "/fulltest" && startTestStore.partType === "Part 2")){
      targetTime = 120;
    }else{
      targetTime = 240;
    }
    const interval = setInterval(() => {
      setTime((prevTime: number) => {
        if (prevTime === targetTime) {
          clearInterval(interval);
          startTestStore.setTime(prevTime)
          return prevTime;
        }
        startTestStore.setTime(prevTime+1)
        return prevTime + 1;
      });
    }, 1000);
  }, []);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return (
    <div>
      <p className="text-[#E31837] font-bold">
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </p>
    </div>
  );
};

export default Timer;
