
import { observer } from 'mobx-react-lite';
import React from 'react'

const SenetenceCompletion = ({item,replaceWithInputFieldsSentenceCompletion}:any) => {
  return (
   <>
    <div className="text-[#3C424C] text-sm my-2">
        {" "}
        Complete the Sentences. Write{" "}
        <span className="font-semibold">NO MORE THAN TWO WORDS</span> in each field
      </div>
      <div className="text-[#3C424C] text-lg font-semibold mt-2" >
        {item["questionDescription"]}
      </div>
      {item["listOfQuestions"].length > 0 &&
        item["listOfQuestions"].map((question: any, index: any) => {
          return (
            <div key={question.questionI} className="text-[#3C424C] text-base text-justify">
              <li>
                {replaceWithInputFieldsSentenceCompletion(question.questionName, index, question.questionId)}
              </li>
            </div>
          );
        })}
   </>
  )
}

export default observer(SenetenceCompletion)