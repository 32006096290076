import { makeAutoObservable} from "mobx";
import {makePersistable} from "mobx-persist-store";
import SpeakingIcon from '../icons/Speaking.svg';
import SpeakingActiveIcon from '../icons/SpeakingActive.svg'
import ListeningIcon from '../icons/Listening.svg';
import ListeningActiveIcon from '../icons/ListeningActive.svg';

import WritingIcon from '../icons/Writing.svg';
import WritingActiveIcon from '../icons/WritingActive.svg'
import ReadingIcon from '../icons/Reading.svg';
import ReadingActiveIcon from '../icons/ReadingActive.svg'
export class DashboardStore{

    dashboardNavigation = [
        {name: 'Listening', href: '/listening', icon: ListeningIcon, current: false , isVisible: false ,activeIcon:ListeningActiveIcon},
        {
          name: 'Writing',
          href: '/writing',
          icon: WritingIcon,
          current: false,
          isVisible : false,
          activeIcon:WritingActiveIcon
        },
        {
          name: 'Reading',
          href: '/reading',
          icon: ReadingIcon,
          current: false,
          isVisible : false,
          activeIcon:ReadingActiveIcon
        },
        {
          name: 'Speaking',
          href: '/speaking',
          icon: SpeakingIcon,
          current: true,
          isVisible : true,
          activeIcon:SpeakingActiveIcon
        }
        
      ]

    showDashboardSideBar: boolean = false
    screenName: string = 'Speaking'
      showProfile : boolean = false;
      profiePageTabIndex:number=0;
      isMobile:boolean = false;
      deleteAlert:boolean = false;
      
    
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {name: 'DashboardStore', properties: ['showProfile','isMobile','dashboardNavigation','screenName'], storage: window.localStorage});
  }

  
  setShowDashboardSideBar(isShow :boolean) {
    this.showDashboardSideBar = isShow;
  }

 async showProfilePage(bool:boolean){
  this.showProfile = bool;
 }

  toggleShowDashboardSideBar() {
    this.showDashboardSideBar = !(this.showDashboardSideBar)
  }
   

  updateCurrentNavBar(currentNavItem :any){
    this.dashboardNavigation.forEach((item,index) => {
        if(item.name === currentNavItem){
            item.current = true;
            this.screenName = currentNavItem;
        }else{
            item.current = false;
        }
    })

  }
  setProfilePageTabIndex(index:number){
    this.profiePageTabIndex=index;
  }

  setIsMobile(bool:boolean){
    this.isMobile = bool;
  }

  setDeleteAlert(bool:boolean){
    this.deleteAlert = bool;
  }
}