import React, { useEffect } from "react";
import { useStore } from "../../../Hooks/use-store";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import downArrow from "../../../icons/downArrowAIfeedback.png";
import upArrow from "../../../icons/upArrowAIfeedback.png";
import LockedContentOverlay from "../../common/lockedContentoverlay";

const Tab = ({ label, onClick, isActive }: any) => {
  return (
    <button
      className={`cursor-pointer px-4 pt-4 border-b-[5px] rounded-sm font-extrabold ${
        isActive ? "border-red-600 text-[#E31837] " : "border-transparent"
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

const Tabs = observer(({ tabs, partType }: any) => {
  const { resultsStoreWriting } = useStore();

  const handleTabClick = (index: any) => {
    resultsStoreWriting.setActiveTabAiFeedback(index);
    resultsStoreWriting.showContent(false);
    resultsStoreWriting.controlAccordian("all", false);
  };

  const getTabComponent = () => {
    if (partType === "part1") {
      return (
        <Tab
          key={0}
          label="Part 1"
          onClick={() => handleTabClick(0)}
          isActive="true"
        />
      );
    } else if (partType === "part2") {
      return (
        <Tab
          key={1}
          label="Part 2"
          onClick={() => handleTabClick(1)}
          isActive="true"
        />
      );
    } else {
      return tabs.map((tab: any, index: any) => (
        <Tab
          key={tab}
          label={tab}
          onClick={() => handleTabClick(index)}
          isActive={index === resultsStoreWriting.activeTabAiFeedback}
        />
      ));
    }
  };

  return <div className="flex  rounded-t-[8px]  pl-4">{getTabComponent()}</div>;
});

const AiFeedbackWriting = observer(() => {
  const navigate = useNavigate();
  const tabs = ["Part 1", "Part 2"];
  const { resultsStoreWriting, userStore, dashboardStore } = useStore();
  let {
    getQuestionAnalysisPartOne,
    getQuestionAnalysisPartTwo,
    partType,
    activeTabAiFeedback,
    showless,
    accordian,
  } = resultsStoreWriting;
  let { whatWentWrong, improvements, idealAnswer } = accordian;

  function showContent(content: any) {
    if (content) {
      if (showless) {
        return (
          <div>
            {content.length > 0 && content}
            <button
              className="text-[#E31837] font-semibold cursor-pointer"
              onClick={() => resultsStoreWriting.showContent(false)}
            >
              show less
            </button>
          </div>
        );
      } else {
        return (
          <div>
            {content.length > 0 && content?.slice(0, 370)}
            <button
              className="text-[#E31837] font-semibold cursor-pointer"
              onClick={() => resultsStoreWriting.showContent(true)}
            >
              ...see more
            </button>
          </div>
        );
      }
    }
  }

  const startHandler = () => {
    dashboardStore.setProfilePageTabIndex(1);
    navigate("/profile");
  };

  useEffect(() => {
    resultsStoreWriting.showContent(false);
    if (partType === "part1") {
      resultsStoreWriting.setActiveTabAiFeedback(0);
    }
    if (partType === "part2") {
      resultsStoreWriting.setActiveTabAiFeedback(1);
    }
  }, []);

  const renderMyAnswer = () => {
    if (
      (activeTabAiFeedback === 0 &&
        getQuestionAnalysisPartOne.MY_ANSWER === "Not Attempted") ||
      (activeTabAiFeedback === 1 &&
        getQuestionAnalysisPartTwo.MY_ANSWER === "Not Attempted")
    ) {
      return (
        <div className="py-3 text-[#737E93] border-b border-[#E3E5E9]  font-sans text-base font-medium text-center">
          Not Answered
        </div>
      );
    } else {
      return (
        <div className="py-3 text-[#292D35] border-b border-[#E3E5E9]  font-sans text-base text-justify ">
          {activeTabAiFeedback == 0
            ? showContent(getQuestionAnalysisPartOne.MY_ANSWER)
            : showContent(getQuestionAnalysisPartTwo.MY_ANSWER)}
        </div>
      );
    }
  };

  const renderWhatWentWrongOverview = () => {
    if(activeTabAiFeedback == 0){
      return (getQuestionAnalysisPartOne?.AI_FEEDBACK.WHAT_WENT_WRONG?.overview)
    }
    else{
      return(getQuestionAnalysisPartTwo?.AI_FEEDBACK.WHAT_WENT_WRONG?.overview)
    }

  }
  const renderWhatWentWrongTaskResponse = () => {
    if(activeTabAiFeedback == 0){
      return (getQuestionAnalysisPartOne?.AI_FEEDBACK?.WHAT_WENT_WRONG["1.Task_Response"])
    }
    else{
      return(getQuestionAnalysisPartTwo?.AI_FEEDBACK?.WHAT_WENT_WRONG["1.Task_Response"])
    }

  }
  const renderWhatWentWrongCoherenceAndCohesion = () => {
    if(activeTabAiFeedback == 0){
      return (getQuestionAnalysisPartOne?.AI_FEEDBACK?.WHAT_WENT_WRONG["2.Coherence_and_Cohesion"])
    }
    else{
      return( getQuestionAnalysisPartTwo?.AI_FEEDBACK?.WHAT_WENT_WRONG["2.Coherence_and_Cohesion"])
    }

  }
  const renderIdealAnswer = () => {
    if(activeTabAiFeedback == 0){
      return (getQuestionAnalysisPartOne?.AI_FEEDBACK?.THE_IDEAL_ANSWER)
    }
    else{
      return(getQuestionAnalysisPartTwo?.AI_FEEDBACK?.THE_IDEAL_ANSWER)
    }

  }

  return (
    <div className="border border-[#E3E5E9] bg-[#FFFFFF] ">
      <div className="h-[2.8rem] bg-[#F8F9FA] border-b border-[#E3E5E9] ">
        <Tabs tabs={tabs} partType={partType} />
      </div>
      <div className="relative">
        <div className=" bg-[#FFFFFF]  px-8 flex flex-col">
          <div>
            <div className="text-[#737E93] font-normal pt-6 ">MY ANSWER</div>
            {renderMyAnswer()}
            <div>
              <div className="text-[#737E93] font-normal pt-6 pb-3">
                AI FEEDBACK
              </div>
              <div>
                <div className="text-[#E31837] text-lg font-semibold flex cursor-pointer">
                  WHAT WENT WRONG{" "}
                  <button
                    onClick={() =>
                      resultsStoreWriting.controlAccordian(
                        "whatWentWrong",
                        !whatWentWrong
                      )
                    }
                    className={`${whatWentWrong ? "pb-2" : "pt-2"} pl-2 focus:outline-none bg-transparent border-none`}
                  >
                    <img
                      src={whatWentWrong ? upArrow : downArrow}
                      alt="arrow"
                    />
                  </button>
                </div>
                {whatWentWrong && (
                  <div>
                    <div className="py-3 text-[#292D35]  font-sans text-base text-justify">
                      {renderWhatWentWrongOverview()}
                    </div>
                    <div className="py-3 border-b border-[#E3E5E9] h-[40%] flex flex-col text-justify">
                      <div className="pb-4">
                        <span className="text-[#E31837] text-md font-sans font-semibold ">{" "}
                          1.Task Response
                        </span>:<span className="text-[#292D35] font-sans text-base ">
                          {renderWhatWentWrongTaskResponse()}
                        </span>
                      </div>
                      <div className="pb-4">
                        <span className="text-[#E31837] text-md font-sans font-semibold">
                          2.Coherence and Cohesion:
                        </span>{" "}
                        <span className="text-[#292D35] font-sans text-base">
                          {renderWhatWentWrongCoherenceAndCohesion()}
                        </span>
                      </div>
                      <div className="pb-4">
                        <span className="text-[#E31837] text-md font-sans font-semibold">
                          3.Lexical Resource:
                        </span>
                        <span className="text-[#292D35] font-sans text-base">
                          {activeTabAiFeedback == 0
                            ? getQuestionAnalysisPartOne?.AI_FEEDBACK
                                ?.WHAT_WENT_WRONG["3.Lexical_Resource"]
                            : getQuestionAnalysisPartTwo?.AI_FEEDBACK
                                ?.WHAT_WENT_WRONG["3.Lexical_Resource"]}
                        </span>
                      </div>
                      <div className="pb-4">
                        <span className="text-[#E31837] text-md font-sans font-semibold">
                          {" "}
                          4.Grammatical Range and Accuracy:
                        </span>
                        <span className="text-[#292D35] font-sans text-base">
                          {activeTabAiFeedback == 0
                            ? getQuestionAnalysisPartOne?.AI_FEEDBACK
                                ?.WHAT_WENT_WRONG[
                                "4.Grammatical_Range_and_Accurancy"
                              ] ||
                              getQuestionAnalysisPartOne?.AI_FEEDBACK
                                ?.WHAT_WENT_WRONG[
                                "4.Grammatical_Range_and_Accuracy"
                              ]
                            : getQuestionAnalysisPartTwo?.AI_FEEDBACK
                                ?.WHAT_WENT_WRONG[
                                "4.Grammatical_Range_and_Accurancy"
                              ] ||
                              getQuestionAnalysisPartTwo?.AI_FEEDBACK
                                ?.WHAT_WENT_WRONG[
                                "4.Grammatical_Range_and_Accuracy"
                              ]}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div className="text-[#E31837] text-lg cursor-pointer font-semibold pt-5 flex">
                  WHAT IMPROVEMENTS CAN BE DONE{" "}
                  <button
                    onClick={() =>
                      resultsStoreWriting.controlAccordian(
                        "improvements",
                        !improvements
                      )
                    }
                    className={`${improvements ? "pb-2" : "pt-2"} pl-2 focus:outline-none bg-transparent border-none`}
                  >
                    <img src={improvements ? upArrow : downArrow} alt="arrow" />
                  </button>
                </div>
                {improvements && (
                  <div>
                    <div className="py-2 font-normal text-[#292D35] font-sans text-base pt-6">
                      {activeTabAiFeedback == 0
                        ? getQuestionAnalysisPartOne?.AI_FEEDBACK
                            ?.WHAT_IMPROVEMENTS_CAN_BE_DONE?.heading
                        : getQuestionAnalysisPartTwo?.AI_FEEDBACK
                            ?.WHAT_IMPROVEMENTS_CAN_BE_DONE?.heading}
                    </div>
                    <div className="py-3 border-b border-[#E3E5E9] text-[#292D35] font-sans text-base">
                      {activeTabAiFeedback == 0
                        ? getQuestionAnalysisPartOne?.AI_FEEDBACK?.WHAT_IMPROVEMENTS_CAN_BE_DONE?.points?.map(
                            (item: any) => (
                              <li key={item} className="pb-4">
                                {item}
                              </li>
                            )
                          )
                        : getQuestionAnalysisPartTwo?.AI_FEEDBACK?.WHAT_IMPROVEMENTS_CAN_BE_DONE?.points?.map(
                            (item: any) => (
                              <li key={item} className="pb-4">
                                {item}
                              </li>
                            )
                          )}
                    </div>
                  </div>
                )}
              </div> 
              <div>
                <div className="text-[#E31837] cursor-pointer text-lg font-semibold pt-5 flex">
                  THE IDEAL ANSWER{" "}
                  <button
                    onClick={() =>
                      resultsStoreWriting?.controlAccordian(
                        "idealAnswer",
                        !idealAnswer
                      )
                    }
                    className={`${idealAnswer ? "pb-2" : "pt-2"} pl-2 focus:outline-none bg-transparent border-none`}
                  >
                    <img src={idealAnswer ? upArrow : downArrow} alt="arrow" />
                  </button>
                </div>
                {idealAnswer && (
                  <div className="py-3 border-b border-[#E3E5E9] text-[#292D35] font-sans text-base text-justify">
                    {renderIdealAnswer()}
                  </div>
                )}
              </div>
            </div>  
          </div>
        </div>
        {!userStore.isProUser && (
          <LockedContentOverlay startHandler={startHandler} />
        )}
      </div>
    </div>
  );
});

export default AiFeedbackWriting;
