import { useEffect } from "react";
import TaskCard from "../../../components/common/taskCard";
import Part1Test from "../../parts/part1/part1Test";
import PulsateImage from "../../../components/speaking/pulsateImage";
import PartTestCompleted from "../../parts/partTestCompleted";
import Part2Test from "../../parts/part2";
import Part3Test from "../../parts/part3";
import InstructionCard from "../instructionCard";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../Hooks/use-store";
import Loader from "../../../Loader";
import SelectionTopic from "../../speaking/selectTopic";


const FullTestStartIntroCard = observer(({ testType ,handleClose}: any) => {
  const { startTestStore ,userStore} = useStore();

  const startHandler = async () => {
    try {
       await startTestStore.startFreeTest();  
      if(testType === 'part2'){
        startTestStore.setStep(2)
      }else if(testType === 'part3'){
        startTestStore.setStep(3)

      }else{
        startTestStore.setStep(startTestStore.step + 1);
        startTestStore.setStartPartone(true);
      }
     
    } catch (error) {
      console.log(error, "start-test-error");
    }
  };


  const startHandlerPart1 =async () => {
    try{
      let res= await startTestStore.generateTestPro(testType);
      if(res.status === 200){
        startHandler()
      }

    }catch(e){
      console.log(e,'catch error')
    }
  }
  let fullTestInstructions = [
    "1. Please sit at a quite place and take the test.",
    "2. There are 3 parts of the test",
    "3. You will hear the reviewer asking the questions once only",
    "4. Each question is followed by your answer.",
    "5. When you see a wave start speaking your answer & if completed earlier click next.",
    "6. You can review your answers and see your score breakdown for each section at the end of the test.",
  ];



  const continueHandler = async (part: string) => {
    try {
      await startTestStore.getFreeTestPartOneData(startTestStore.testId,part);
    } catch (error) {
      console.log(error, "error-freetest-partoOne");
    }
  };  
  const nextStep = () => {
    const { step, currentStep,testType } = startTestStore;
    let isPro = userStore.isProUser;
    if (step === 1) {
      if(isPro  && testType!=='full'){
        startTestStore.setPartType('Part 3')
        startTestStore.setStep(4);
        continueHandler("part1");
      }else{
        startTestStore.setNextStep("Step 2");
        continueHandler("part1");
        startTestStore.setStep(4);
      }   
    } else if (step === 2) {
      if(isPro  && testType!=='full'){
        startTestStore.setPartType('Part 2');
        continueHandler("part2");
        startTestStore.setStep(4);
      }else{
        startTestStore.setNextStep("Step 3");
        continueHandler("part2");
        startTestStore.setStep(4);
      }
    } else if (step === 3) {
      continueHandler("part3");
      startTestStore.setStep(4);
    } else if (step === 4 && currentStep === "step3") {
      startTestStore.setStep(5);
    } else if (step === 5) {
      if (currentStep === "step1") {
        startTestStore.setStep(2);
      } else if (currentStep === "step2") {
        startTestStore.setStep(3);
      }
    } else if (step === 6) {
     if(isPro  && testType!=='full'){
     // startTestStore.setPartType('Part 3')
     }
      startTestStore.setStep(4);
    } else {
      startTestStore.setStep(step + 1);
    }
  };

  let buttonText : any;
  if (testType !== 'part1') {
    buttonText = 'Start';
  } else if (Object.keys(startTestStore.selectedTopic).length > 0) {
    buttonText = 'Start Test With Selected Topic';
  } else {
    buttonText = 'Start Test With Random Topic';
  }

  const renderContent = () => {
    const { step } = startTestStore;
    if (step === 0) {
      if (startTestStore.isLoading) {
        return (
          <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
            <Loader />
          </div>
        );
      } else if (startTestStore.tryAgainButton) {
        return (
          <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
            <h1 className="text-2xl font-bold mb-4">Something went wrong...</h1>
            <button
              className="mx-auto bg-white rounded-full px-2 py-2 text-[#E31837] text-xl font-semibold border border-[#E31837] w-1/6"
              onClick={() => {
                startTestStore.generateTestPro(testType);
              }}
            >
              Please Try again
            </button>
          </div>
        );
      } else {
        return (
          <div>
            <div>
              {testType !=='part1' ? <InstructionCard
                testType={""}
                instructions={fullTestInstructions}
              /> : <SelectionTopic />}
            </div>
            <div className="flex justify-center mt-10">
              <button
                className={`mx-auto  ${startTestStore.isTestStarted ? "bg-gray-400" :" bg-red-500 cursor-pointer"} rounded-full  px-4 py-2 text-white`}
                onClick={testType !=='part1' ? startHandler : startHandlerPart1}

                disabled={startTestStore.isTestStarted}
              >
                {buttonText}
              </button>
            </div>
          </div>
        );
      }
    } else if (step === 1) {
     return startTestStore.isLoading ? (
        <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
          <Loader />
        </div>
      ) : ( <div>
          <Part1Test nextStep={nextStep} />
          {renderContinueButton(nextStep)}
        </div>
      );
    } else if (step === 2) {
      return startTestStore.isLoading ? (
        <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
          <Loader />
        </div>
      ) : (
        <div>
          <Part2Test nextStep={nextStep} />
          {renderContinueButton(nextStep)}
        </div>
      );
    } else if (step === 3) {
     return startTestStore.isLoading ? (
        <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
          <Loader />
        </div>
      ) : (
        <Part3Test nextStep={nextStep} />
      );
    } else if (step === 4) {
      if (startTestStore.isLoading) {
        return (
          <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
            <Loader />
          </div>
        );
      } else {
        return <PulsateImage testType={startTestStore.partType} nextStep={nextStep} handleClose={handleClose}/>;
      }
    } else if (step === 5) {
      return (
        <PartTestCompleted
          testType={startTestStore.partType}
          nextTest={startTestStore.nextStep}
          nextStep={nextStep}
          handleClose={handleClose}
        />
      );
    } else if (step === 6) {
      return <TaskCard nextStep={nextStep} />;
    }
  };

  const renderContinueButton = (onClick: () => void) => (
    <div className="flex justify-center mt-10">
      <button
        className="mx-auto bg-red-500 rounded-full px-4 py-2 text-white"
        onClick={onClick}
      >
        Continue
      </button>
    </div>
  );

  useEffect(() => {
    if (startTestStore.step === 1) {
      startTestStore.setPartType("Part 1");
    } else if (startTestStore.step === 2) {
      startTestStore.setPartType("Part 2");
    } else if (startTestStore.step === 3) {
      startTestStore.setPartType("Part 3");
    } else if (startTestStore.step === 0) {
      startTestStore.setPartType("full");
    }
  }, [startTestStore.step]);

  useEffect(() => {
    if (startTestStore.isTaskCardDisplay) {
      startTestStore.setStep(6);
    }
  }, [startTestStore.isTaskCardDisplay]);

  return (
      <div className="flex flex-col h-full overflow-hidden border-solid border-red-800 pb-20">
        {renderContent()}
      </div>
  );
});

export default FullTestStartIntroCard;
