import React from 'react'

const HomePageShimmer = () => {
  return (
    <div className="flex flex-col mt-4 px-[3vw] ">
   
    <div className="flex flex-col sm:flex-row justify-between gap-4">
    
          <div className="sm:w-1/2  h-[15rem] rounded my-8 shimmer ">
         
        </div>
        <div className="sm:w-1/2 h-[15rem] rounded my-8 shimmer ">
         
        </div>
      </div>
      <div className="flex flex-col sm:flex-row  w-3/4 gap-5">
    
          <div className="sm:w-1/4  h-[6.5rem] rounded my-8 shimmer ">
         
        </div>
        <div className="sm:w-1/4 h-[6.5rem] rounded my-8 shimmer mx-1">
         
        </div>
        <div className="sm:w-1/4  h-[6.5rem] rounded my-8 shimmer ">
         
        </div>
      </div>
      <div className="flex flex-col sm:flex-row  w-full gap-5">
    
          <div className="sm:w-1/4  h-[6.5rem] rounded my-8 shimmer ">
         
        </div>
        <div className="sm:w-1/4 h-[6.5rem] rounded my-8 shimmer mx-1">
         
        </div>
        <div className="sm:w-1/4  h-[6.5rem] rounded my-8 shimmer ">
         
        </div>
      </div>
      
    </div>

  )
}

export default HomePageShimmer