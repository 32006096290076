import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../Hooks/use-store";
import { be_url } from "../api/api-config";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material";

interface Plan {
  id: number;
  name: string;
  price: any;
  discount: any;
  duration: string;
  validity:number;
  planPlatform: string;
  moreInfo: Description;
}
interface Description {
  description: DescriptionTitle;
  services: Array<Features>;
  colorPrimary : string;
  colorSecondary: string;
  buttonText: string;
}

interface DescriptionTitle {
  title: string;
}

interface Features {
  feature : string;
  isOffered : boolean;
}

const EditFiled = ({
  field,
  item,
  planDetails,
  setPlanDetails,
  updatePlans,
}: any) => {
  let updateClassName =
    "border bg-green-200 text-green-600 px-4 py-1 rounded text-sm font-bold hover:bg-black hover:text-white";
  const toggleEditMode = () => {
    let planDetailsCopy = [...planDetails];

    let toggledPlans = planDetailsCopy.map((itm: any) => {
      if (itm.id === item.id) {
        if (itm[`isEdit${capitalizeFirstLetter(field)}`]) {
          let objItem = { ...itm };
          delete objItem.isEditDuration;
          delete objItem.isEditValidity;
          delete objItem.isEditName;
          delete objItem.isEditPrice;
          delete objItem.product_id;
          updatePlans(objItem);
        }
        return {
          ...itm,
          [`isEdit${capitalizeFirstLetter(field)}`]:
            !itm[`isEdit${capitalizeFirstLetter(field)}`],
        };
      }

      return itm;
    });

    setPlanDetails(toggledPlans);
  };

  const updateField = (e: any) => {
    let planDetailsCopy = [...planDetails];

    let toggledPlans = planDetailsCopy.map((itm: any) => {
      if (itm.id === item.id) {
        return {
          ...itm,
          [field]: e.target.value,
        };
      }
      return itm;
    });

    setPlanDetails(toggledPlans);
  };
  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);
  return (
    <div className="flex justify-center w-full">
      <div className="w-1/2">
        {item[`isEdit${capitalizeFirstLetter(field)}`] ? (
          <input
            type={field ==='validity' ? 'number' :'text'}
            value={item[field]}
            onChange={updateField}
            className="border outline-none border-gray-400 rounded px-2 py-1"
          />
        ) : (
          item[field]
        )}
      </div>
      <div className="w-1/2">
        {item[`isEdit${capitalizeFirstLetter(field)}`] ? (
          <button className={updateClassName} onClick={toggleEditMode}>
            Update {capitalizeFirstLetter(field)}
          </button>
        ) : (
          <button
            className="border bg-red-200 text-red-600 px-4 py-1 rounded  text-sm font-bold hover:bg-black hover:text-white"
            onClick={toggleEditMode}
          >
            Edit
          </button>
        )}
      </div>{" "}
    </div>
  );
};
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "16px",
    width: "100%",
  },
}));
const IELTSPaymentsUtility = () => {
  const [planDetails, setPlanDetails] = useState<Plan[]>([]);
  const [newPlan, setNewPlan] = useState<Plan>({
    id: 0,
    name: "",
    price: "",
    discount: "",
    duration: "",
    validity:0,
    planPlatform:"",
    moreInfo:{
      description: {
        title:""
      },
      services : [],
      colorPrimary : "",
      colorSecondary: "",
      buttonText: "",
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const [deletePlan, setDeletePlan] = useState(false);
  const [deleteCurrentItem,setDeleteCurrentItem] = useState({});
  const feildsDisabled = useRef(false);

  function openDialog() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
    feildsDisabled.current = false;
    setNewPlan({    
      id: 0,
      name: "",
      price: "",
      discount: "",
      duration: "",
      validity:0,
      planPlatform : "",
      moreInfo:{
        description: {
          title:""
        },
        services : [],
        colorPrimary : "",
        colorSecondary: "",
        buttonText: "",
      }
    })
  }
  const { userStore } = useStore();

  const getPlanDetails = async () => {
    try {
      let response = await fetch(`${be_url}/api/get-plans-v1`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userStore.token,
        },
      });
      if (response.ok) {
        let planData = await response.json();
        let updatedPlanData = planData?.map((item: any) => ({
          ...item,
          isEditName: false,
          isEditPrice: false,
          isEditDuration: false,
          isEditValidity:false,
        }));
        setPlanDetails(updatedPlanData);
      } else {
        console.error("Failed to fetch plans:", response.statusText);
      }
    } catch (e) {
      console.log(e, "Get Plan Details Error");
    }
  };
  const updatePlans = async (updatedPayload: any) => {
    try {
      let response = await fetch(`${be_url}/api/update-plans`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userStore.token,
        },
        body: JSON.stringify(updatedPayload),
      });
      if (response.ok) {
         toast("Plans Updated Successfully");
        getPlanDetails();
      } else {
        console.error("Failed to update plans:", response.statusText);
      }
    } catch (e) {
      console.log(e, "UpdatePlans");
    }
  };

  const addNewPlanDetails = (e: any) => {
    let { name, id, value } = e.target;
    let newData = {...newPlan};
    if(name === "title"){
      newData.moreInfo.description.title = value;
      setNewPlan(newData);
    }else if(name === "feature" || name.startsWith("isOffered-")){
      let index = Number(id);
      let services = newData.moreInfo.services[index];
      if (name === "feature") {
        services.feature = value;
      } else {
        services.isOffered = value === "true";
      }
      newData.moreInfo.services[index] = services;
      setNewPlan(newData);
    }else {
      setNewPlan((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    updatePlans(newPlan);
    // Clear the form
    setNewPlan({
      id: 0,
      name: "",
      price: "",
      discount: "",
      duration: "",
      validity:0,
      planPlatform:"",
      moreInfo:{
        description: {
          title:""
        },
        services : [],
        colorPrimary : "",
        colorSecondary: "",
        buttonText: "",
      }
    });
    close();
  };

  const addNewFeatureDescription = () => {
    let newData = {...newPlan};
    let newFeature : Features = {
      feature : "",
      isOffered : true,
    }
    newData.moreInfo.services.push(newFeature);
    setNewPlan(newData);
  }

  const handleFeatureDelete = (index : number) => {
    let newData = {...newPlan};
    newData.moreInfo.services.splice(index,1);
    setNewPlan(newData);
  }

  const openFeatureEditable = (plan : Plan) => {
    feildsDisabled.current=true;
    setNewPlan(plan);
    openDialog();
  }

  const handleDeletePlan =async() => {
    try{
        let response = await fetch(`${be_url}/api/soft_delete_plan`, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "bearer " + userStore.token,
            },
            body: JSON.stringify(deleteCurrentItem),
          });
          if (response.ok) {
            toast("Plan Deleted Successfully");
            getPlanDetails();
          } else {
            toast.error("Plan Deletion Failed");
            console.error("Failed to update plans:", response.statusText);
          }

    }catch(e){
    console.log(e,"ERROR")
    }

  }
  useEffect(() => {
    getPlanDetails();
  }, []);


  return (
    <>
      <StyledDialog open={isOpen} onClose={close}>
        <div className=" p-4 w-full flex flex-col px-16 py-6 rounded-2xl">
          <h1 className="text-center text-red-600 font-semibold text-lg">
            Add New Plan
          </h1>
          <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
            <div>
              <label htmlFor="plan-name"  className="block text-sm font-medium text-gray-700">
                Plan Name
              </label>
              <input
                type="text"
                id="plan-name"
                name="name"
                value={newPlan.name}
                onChange={addNewPlanDetails}
                disabled={feildsDisabled.current}
                className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md shadow-2xl focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                Price
              </label>
              <input
                type="text"
                name="price"
                id="price" 
                value={newPlan.price}
                onChange={addNewPlanDetails}
                disabled={feildsDisabled.current}
                className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md shadow-2xl focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="discount" className="block text-sm font-medium text-gray-700">
                Discount
              </label>
              <input
                name="discount"
                id="discount"
                value={newPlan.discount}
                onChange={addNewPlanDetails}
                type="text"
                disabled={feildsDisabled.current}
                className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md shadow-2xl focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              />
            </div>{" "}
            <div>
              <label htmlFor="duration" className="block text-sm font-medium text-gray-700">
                Duration
              </label>
              <input
                name="duration"
                id="duration" 
                placeholder="1 week/1 month/3 months"
                value={newPlan.duration}
                onChange={addNewPlanDetails}
                type="text"
                disabled={feildsDisabled.current}
                className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md shadow-2xl focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="vaildity" className="block text-sm font-medium text-gray-700">
                Validity
              </label>
              <input
                name="validity"
                id="validity"
                placeholder="Enter Number of days"
                value={newPlan.validity}
                onChange={addNewPlanDetails}
                type="number"
                disabled={feildsDisabled.current}
                className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md shadow-2xl focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="platform" className="block text-sm font-medium text-gray-700">
                Platform
              </label>
              <select
                id="platform"
                name="planPlatform"
                value={newPlan.planPlatform}
                onChange={addNewPlanDetails}
                disabled={feildsDisabled.current}
                className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md shadow-2xl focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              >
                <option value="" disabled>Select a platform</option>
                <option value="web">Web</option>
                <option value="mobile">Mobile</option>
              </select>
            </div>
            <div>
              <label htmlFor="description-title" className="block text-sm font-medium text-gray-700">
                Description - Title
              </label>
              <input
                name="title"
                id="description-title"
                placeholder="Enter description title"
                value={newPlan.moreInfo.description.title}
                onChange={addNewPlanDetails}
                type="text"
                className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md shadow-2xl focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              />
            </div>
            <div>
              {newPlan.moreInfo.services.map((item, index) => (
                <div key={item.feature} className="flex flex-row w-full">
                  <input
                    name="feature"
                    id={`${index}`}
                    placeholder={`Enter feature ${index + 1}`}
                    value={item.feature}
                    onChange={addNewPlanDetails}
                    type="text"
                    className="mt-1 w-1/2 block border border-gray-300 px-3 py-2 rounded-md shadow-2xl focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                  <div className="w-1/2 items-center flex justify-around px-3">
                    <div>
                      <input
                        type="radio"
                        id={`${index}`}
                        onChange={addNewPlanDetails}
                        checked={item.isOffered === true}
                        value={"true"}
                        name={`isOffered-${index}`}
                      />
                      <span className="mx-2">True</span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id={`${index}`}
                        onChange={addNewPlanDetails}
                        checked={item.isOffered === false}
                        value={"false"}
                        name={`isOffered-${index}`}
                      />
                      <span className="mx-2">False</span>
                    </div>
                    <button
                      onClick={() => handleFeatureDelete(index)}
                      className="bg-red-200 text-red-600 hover:bg-black hover:text-white px-2 py-1 my-2 rounded"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </form>
          <button
            className="bg-red-200 text-red-600 hover:bg-black hover:text-white px-2 py-1 my-2 rounded"
            onClick={() => addNewFeatureDescription()}
          >
            Add Feature
          </button>

          <section className="flex justify-between m-5 ">
            {" "}
            <button
              onClick={close}
              className="bg-red-200 text-red-600 hover:bg-black hover:text-white px-2 py-1 my-2 w-32 rounded"
            >
              Close
            </button>
            <button
              onClick={() => handleSubmit()}
              className="bg-red-200 text-red-600 hover:bg-black hover:text-white px-2 py-1 my-2 w-32 rounded"
            >
              Submit
            </button>
          </section>
        </div>
      </StyledDialog>
      <StyledDialog open={deletePlan} onClose={() => setDeletePlan(false)}>
        <div className="  w-full flex flex-col rounded-2xl my-10">

          <div className="text-center font-semibold">
            Are You Sure You want to delete the Plan ?
          </div>
          <div className="mx-auto mt-2">
            <button
              onClick={() => {
                handleDeletePlan();
                setDeletePlan(false);
              }}
              className="bg-red-200  text-red-600 hover:bg-black hover:text-white px-2 py-1 my-2 w-20 rounded"
            >
              Delete
            </button>
            <button
              onClick={() => setDeletePlan(false)}
              className="bg-red-200  mx-2 text-red-600 hover:bg-black hover:text-white px-2 py-1 my-2 w-20 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </StyledDialog>
      <div className="overflow-x-auto w-full">
        <button
          className="bg-red-200 border border-gray-300 px-2 py-1 rounded mb-2 font-semibold text-red-600 hover:bg-black hover:text-white"
          onClick={openDialog}
        >
          Add New Plan
        </button>

        <table className="table-auto min-w-full bg-white border-collapse border  border-gray-200">
          <thead>
            <tr className=" border border-gray-300 ">
              <th className="px-4 py-2 text-center">Plan Name</th>
              <th className="px-4 py-2 text-center">Price</th>
              <th className="px-4 py-2 text-center">Discount</th>
              <th className="px-4 py-2 text-center">Total Price</th>
              <th className="px-4 py-2 text-center">Plan Duration</th>
              <th className="px-4 py-2 text-center">Validity (In Days)</th>
              <th className="px-4 py-2 text-center">Features</th>
              <th className="px-4 py-2 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {planDetails?.length > 0 ? (
              planDetails.map((item: any) => (
                <tr key={item.id}>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    <EditFiled
                      field="name"
                      item={item}
                      planDetails={planDetails}
                      setPlanDetails={setPlanDetails}
                      updatePlans={updatePlans}
                    />
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    <EditFiled
                      field="price"
                      item={item}
                      planDetails={planDetails}
                      setPlanDetails={setPlanDetails}
                      updatePlans={updatePlans}
                    />
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    {item?.discount}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    {item?.price - item?.discount}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    <EditFiled
                      field="duration"
                      item={item}
                      planDetails={planDetails}
                      setPlanDetails={setPlanDetails}
                      updatePlans={updatePlans}
                    />
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    <EditFiled
                      field="validity"
                      item={item}
                      planDetails={planDetails}
                      setPlanDetails={setPlanDetails}
                      updatePlans={updatePlans}
                    />
                  </td>
                  <td className="border border-gray-300 px-4 py-2  ">
                    <p>Title : {item.moreInfo.description.title} </p>
                    <ul>
                      {
                        item.moreInfo.services.map((item : Features ) => <li key={item.feature} className={`${item.isOffered ? 'text-green-500' : 'text-red-500'}`}>{item.feature}</li>)
                      }
                    </ul>
                    <button  
                      className="border block bg-red-200 text-red-600 px-4 py-1 rounded  text-sm font-bold hover:bg-black hover:text-white"
                      onClick={() => openFeatureEditable(item)}
                    >
                      Edit
                    </button>
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    <button
                      onClick={() => {
                        setDeletePlan(true);
                        setDeleteCurrentItem(item);
                      }}
                      className="border bg-red-200 text-red-600 px-4 py-1 rounded  text-sm font-bold hover:bg-black hover:text-white"
                    >
                      Delete Plan
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="border border-gray-300 px-4 py-2 text-center"
                >
                  No Plan Details
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IELTSPaymentsUtility;
