import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import CheckCircle from '../../../icons/CheckCircle.png'
import crossCircle from "../../../icons/cross-circle-svgrepo-com.png"
import CustomLinerProgress from '../../common/Progressbar/customLinearProgressMui';
import { useStore } from "../../../Hooks/use-store";

const EndTestLoader = observer(({ store,module,handleClose}: any) => {
  
const {resultsStoreWriting,resultsStoreListening,resultsStoreReading,resultsStore} = useStore()

  const navigate = useNavigate();

  const getTestResult = () => {
    switch (store.storeName) {
      case "speaking":
        navigate("/speakingResults");
        resultsStore.getTestScore(store.testId);
        resultsStore.getQuestionAnalysis(store.testId, store.testType);
        resultsStore.setTestCompleted(store.testId, store.testType);
        handleClose(false);
        break;
      case "writing":
        navigate("/writingResults");
        resultsStoreWriting.getTestScoreWriting(store.testId, store.testType);
        resultsStoreWriting.getQuestionAnalysis(store.testId, store.testType);
        resultsStoreWriting.setTestCompleted(store.testId, store.testType);
        handleClose(false);
        break;
      case "reading":
        navigate("/readingResults");
        resultsStoreReading.getTestScoreReading(store.testId, store.testType);
        resultsStoreReading.getQuestionAnalysis(store.testId, store.testType);
        resultsStoreReading.setTestCompleted(store.testId, store.testType);
        handleClose(false);
        break;
      case "listening":
        navigate("/listeningResults");
        resultsStoreListening.getTestScoreListening(store.testId, store.testType);
        resultsStoreListening.getQuestionAnalysis(store.testId, store.testType);
        resultsStoreListening.setTestCompleted(store.testId, store.testType);
        handleClose(false);
        break;
    }
  }

  const renderContent = () => {
    if (store.isTestEnded) {
      return (
        <div className="w-[100%] h-64 flex flex-col justify-center">
          <p className="text-2xl text-center font-semibold text-[#737E93] mb-3">
            Submitting Your test
          </p>
          <div className="submitLoader mx-auto my-5"></div>
          <CustomLinerProgress />
        </div>
      );
    } else if (store?.completeTestFailed) {
      return (
        <div>
          <p className="text-[#4E5664] text-xl text-center font-semibold">
            OOPS...
          </p>
          <img
            src={crossCircle}
            alt="check circle"
            className="w-32 h-32 my-5 mx-auto"
          />
          <h1 className="font-[350] text-xl text-center">
            Test Submission Failed
          </h1>
          <button
            className="block mx-auto mt-5 text-white bg-[#E31837] text-md font-semibold w-44 rounded-[40px] h-12"
            onClick={() => {
              if (store.storeName === "reading") {
                navigate("/reading");
              } else if (store.storeName === "writing") {
                navigate("/writing");
                resultsStoreWriting.setStepManagement(
                  "confirm_your_details",
                  "",
                  ""
                );
              } else if (store.storeName === "listening") {
                navigate("/listening");
              } else if (store.storeName === 'speaking'){
                navigate('/speaking');
                store.setCounter(false);
                store.setGetTestV2results([]);
                store.setIsAnimated(false);
                store.setIsRecording(false);
                store.setIsLoading()
                store.setStep(0);
                store.setIsRecording(false);
                store.setIsTaskCard(false);
              }
              store?.setCompleteTestFailed(false);
              store?.setIsTestEnded(null);
            }}
          >
            Back to Home
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col justify-center items-center py-10 lg:py-20">
          <img src={CheckCircle} alt="check circle" />
          <h1 className="font-[350] text-xl mt-7 text-center">
            You have completed your Test
          </h1>
          <button className="text-[#E31837] text-md font-semibold mt-5" onClick={() => getTestResult()}>
            View test result
          </button>
        </div>
      );
    }
  };

  return (
    <>
      {renderContent()}
    </>
  );
});

export default EndTestLoader;
