import apiSauce from "./api-sauce";

export class TestResultsApi{
    async testResultsUpdate(params:any,page:number):Promise<any> {
        if(params){
            return await apiSauce.apisauce?.get(`/progress/v1?page=${page}&limit=9&type=${params}`) ///progress?page=${page}&limit=9&type=${params}
        }else{
            return await apiSauce.apisauce?.get(`/progress/v1?page=${page}&limit=9`) ///progress?page=${page}&limit=9
        }
        
    }


    async fetchFreeTestData():Promise<any>{
        return await apiSauce.apisauce?.get('/free-tests/v1')

    }

    async getAverageScoreData():Promise<any>{

        return await apiSauce.apisauce?.get('/get-chart')
    }

    async getTestScoreApi(tstId:any) :Promise<any>{
        return await apiSauce.apisauce?.get(`get-test-score?test_id=${tstId}`)
    }

     async getQuestionAnalysisApi(tsId:any,part:any) :Promise<any> {
        return await apiSauce.apisauce?.get(`get-question-analysis?test_id=${tsId}&type=${part}`)
     }

     async isFreeTestCompletedSpeaking (tId:any):Promise<any>{
        return await apiSauce.apisauce?.get(`is/test/completed?test_id=${tId}`)
     }
}


