import { useEffect } from "react";
import InstructionCard from "../../instructionsCard/instructionCard";
import { useStore } from "../../../Hooks/use-store";
import { observer } from "mobx-react-lite";

const Part3Test = observer((props: any) => {
  let { startTestStore } = useStore();
 

  let PartOneINstructions = [
    "1.Two-Way discussion. ",
    "2.In this part, examiner will further ask you questions connected to topic of Part 2.",
    "3.These questions are designed to give you an opportunity to discuss more abstract issues and ideas.    ",
  ];

  useEffect(() => {
    startTestStore.setCurrentStep("step3");
    startTestStore.setCounter(false);
  }, []);

  return (
    <div className="w-full mx-auto">
        <InstructionCard testType="Part 3" instructions={PartOneINstructions} />
        <div className="flex justify-center mt-10">
          {" "}
          <button
            className="mx-auto bg-red-500 rounded-full px-4 py-2 text-white"
            onClick={props?.nextStep}
          >
            Continue
          </button>
        </div>
      </div>
  );
});

export default Part3Test;
