import { observer } from "mobx-react-lite";
import React from "react";

const PlanDiagram = observer(
  ({ item, handlePlanAnswerChange, PlanDiagramAnswer }: any) => {
    const questionIds = (range: any) => {
      const [start, end] = range.split("-").map(Number);
      const result = [];

      for (let i = start; i <= end; i++) {
        result.push(i);
      }

      return result;
    };

    const qId = questionIds(item.questionRange);
    const keys = item.listOfQuestions[0].items.theader.filter(
      (key: any) => key !== ""
    );
   
    return (
      <>
        <h2 className="mt-2">Choose the correct label for each building.</h2>
        <div className="w-full flex my-10">
          <section className="w-1/2">
            <img
              src={item.listOfQuestions[0].questionImage[0]}
              alt="question"
              className="border"
            />
          </section>
          <section className="w-1/2 flex my-auto ml-5 overflow-x-auto">
            <table className="border border-[#E3E5E9] rounded-md px-5">
              <thead className="rounded">
                <tr className="bg-[#FFEAEA] ">
                  {item.listOfQuestions[0].items.theader.map(
                    (item: any, index: any) => {
                      console.log(item, "ITEMS"); // Move console.log here if you want to log during mapping
                      return (
                        <th
                          key={item.questionRefrenceId}
                          className="border py-4 px-4"
                        >
                          {item}
                        </th>
                      );
                    }
                  )}
                </tr>
              </thead>
              <tbody>
                {item.listOfQuestions[0].items.tbody.map(
                  (row: any, rowIndex: any) => (
                    <tr key={item.questionRefrenceId}>
                      <td className="border px-2 py-4 w-fit">
                        <p>
                          <span className="font-semibold">
                            {qId[rowIndex]}.{" "}
                          </span>
                          {row.planQuestion}
                        </p>
                      </td>
                      {keys.map((key: any,index:number) => {
                        const isChecked =
                          PlanDiagramAnswer?.some(
                            (item: any) =>
                              item.qId === qId[rowIndex] && item.answer === key
                          );
                        return (
                          <td key={item.questionRefrenceId} className="border px-4 py-4">
                            <input
                              type="radio"
                              id={key}
                              name={`${qId[rowIndex]}`}
                              checked={isChecked}
                              onClick={(e) =>
                                handlePlanAnswerChange(e, qId[rowIndex])
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </section>
        </div>
      </>
    );
  }
);

export default PlanDiagram;
