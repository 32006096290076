import { useEffect, useState } from "react";
import SearchIcon from "../../icons/Search.png";
import { useStore } from "../../Hooks/use-store";
import Loader from "../../Loader";
import { observer } from "mobx-react-lite";

const SelectionTopic = observer(() => {
  const [searchTopic, setSearchTopic] = useState("");
  const { startTestStore,dashboardStore } = useStore();
  const { categoriesList, setSelectedTopic, selectedTopic } = startTestStore;
  const [searchResults, setSearchResults] = useState<any>([]);


  const searchHandler = (e: any) => {
    let topic = e.target.value.trim();
    let categories = [...categoriesList];
    let searchItems =
      categories.length > 0 &&
      categories.filter((item: any) =>
        item.description.toLowerCase().includes(topic.toLowerCase())
      );
    setSearchResults(searchItems);
    setSearchTopic(topic);
  };
  const fetchResult = async () => {
    console.log(categoriesList.length,"Category,CT")
    try{
        if(categoriesList.length ===0){//Condition is to stop the api call more than once
            let res = await startTestStore.fetchCategories();
            if (res.status === 200) {
              setSearchResults(res.data);
            }
        }else{
            setSearchResults(categoriesList);
        }  
    }catch(e){
        console.log(e,"ERROR")
    }
   
  };

  useEffect(() => {
    fetchResult();
    setSelectedTopic({})
  }, []);

  return (
    <div className="flex mx-4 ">
      <div className="flex flex-col">
        <header className="flex flex-col gap-2 w-52 md:w-72 my-2">
          <p className="font-semibold ">Search topic of your choice for test</p>
          <div className="relative">
            <input
              type="text"
              placeholder="Enter Topic Name"
              value={searchTopic}
              onChange={searchHandler}
              className="border w-full   border-gray-400 rounded-lg pr-2 pl-8 py-2 outline-none"
            />
            <img
              src={SearchIcon}
              alt="search"
              className="absolute top-[0.7rem] left-2"
            />
          </div>
        </header>
        <div className="flex flex-col mt-5">
          <div className="text-[#737E93]">Available Topics</div>
          {startTestStore.isFetchDetails ? (
            <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
              <Loader />
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
              {searchResults.map((item: any) => {
                let isClicked = item.id === selectedTopic?.id;
                return (
                  <button
                    className="my-2 cursor-pointer"
                    key={item.id}
                    onClick={() => setSelectedTopic(item)}
                  >
                    <div
                      className={`border ${
                        isClicked
                          ? "border-[#E31837] bg-[#FFEAEA] hover:bg-[#FFEAEA]"
                          : "border-gray-400 hover:bg-gray-100"
                      }   rounded-lg  ${dashboardStore.showDashboardSideBar ? "w-48 md:w-52 xl:w-64 2xl:w-52 ":"w-52 md:w-64 xl:w-72 2xl:w-52"}   h-20 px-4 py-1 flex items-center  `}
                    >
                      <div className="flex flex-col text-left">
                        <p className="text-base font-semibold text-[#292D35]">
                          {item.description}
                        </p>
                        <p className="text-[#737E93]">Part1</p>
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default SelectionTopic;
