import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import { useStore } from '../Hooks/use-store';
import apiSauce from '../api/api-sauce';
import Loader from "../../src/Loader/index";


const TestView = () => {

  const { module,testId } = useParams();
  const { userStore } = useStore();
  const  navigate = useNavigate();
  const [testData,setTestData] = useState();
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState(false);

  useEffect(() => {
    getData();
  },[]);

  const getData = async() => {
    setLoading(true);
    try{
      let response : any = await  apiSauce.apisauce?.get(`/admin/fetch/user/tests?id=${testId}&module_name=${module}` )
      if(response?.status === 200){
        setTestData(response.data);
        setLoading(false);
        setError(false);
      }else{
        setLoading(false);
        setError(true);
      }
    }catch{
      setLoading(false);
      setError(true);
    }
  }

  const user = userStore.studentDetails.mobileNumber;

  const speakingTableRendering = (tableData : any) : React.ReactNode => {
    return (
        <table className="table-auto border-collapse mx-auto w-[80%] mt-16">
            <thead className="sticky -top-1 bg-white">
                <tr>
                    <th className="py-2 px-4 border">Test Id</th>
                    <th className="py-2 px-4 border">Question Id</th>
                    <th className="py-2 px-4 border">Test Type</th>
                    <th className="py-2 px-4 border">Question</th>
                </tr>
            </thead>
            <tbody>
                {
                tableData.map((item:any) => {
                    return (
                    <tr key={item.id}>
                        <td className="py-2 px-4 border text-center">{testId}</td>
                        <td className="py-2 px-4 border text-center">{item.id}</td>
                        <td className="py-2 px-4 border text-center">{item.type}</td>
                        <td className="py-2 px-4 border">{item.text}</td>
                    </tr>
                    )
                })
                }
            </tbody>
        </table>
    )
  }

  const readingTableRendering = (tableData : any) : React.ReactNode => {
    return (<div className='px-20'>
      {
        tableData?.partOneQuestion ? 
          <>
            <h1 className='font-bold text-2xl my-5 text-center'>Part 1</h1>
            <p className='my-3 text-center text-xl font-semibold'>{tableData.partOneQuestion.paragraph.title}</p>
            <p className='text-justify'>{tableData.partOneQuestion.paragraph.content}</p>
            <table className="table-auto border-collapse mx-auto w-[80%] mt-16">
              <thead className="sticky -top-1 bg-white">
                 <tr>
                    <th className="py-2 px-4 border">Test Id</th>
                    <th className="py-2 px-4 border">Question Type</th>
                    <th className="py-2 px-4 border">Question Id</th>
                    <th className="py-2 px-4 border">Question</th>
                    <th className="py-2 px-4 border">Options</th>
                 </tr>
              </thead>
              <tbody>
                {
                  tableData.partOneQuestion.questions.map( (questions : any) => {
                    let questionType = questions['question-type'];
                    switch (questionType) {
                      case "Identifying Information (True/False/Not Given)" : return identifyingInformationData(questions['list-of-questions']);
                      case "Note Completion" : return noteCompletionData(questions['list-of-questions']);
                      case "Sentence Endings" : return sentenceEndingsData(questions);
                      case "Multiple Choice (more than one answer)" : return multipleChoiceData(questions['list-of-questions']);
                      case "Multiple Choice" : return singleChoiceData(questions['list-of-questions']);

                    }
                  })
                }
              </tbody>
            </table>
          </> 
        : <></>
      }
      {
          tableData?.partTwoQuestion ? 
          <>
            <h1 className='font-bold text-2xl my-5 text-center'>Part 2</h1>
            <p className='my-3 text-center text-xl font-semibold'>{tableData.partTwoQuestion.paragraph.title}</p>
            <p className='text-justify'>{tableData.partTwoQuestion.paragraph.content}</p>
            <table className="table-auto border-collapse mx-auto w-[80%] mt-16">
              <thead className="sticky -top-1 bg-white">
                  <tr>
                    <th className="py-2 px-4 border">Test Id</th>
                    <th className="py-2 px-4 border">Question Type</th>
                    <th className="py-2 px-4 border">Question Id</th>
                    <th className="py-2 px-4 border">Question</th>
                    <th className="py-2 px-4 border">Options</th>
                  </tr>
              </thead>
              <tbody>
                {
                  tableData.partTwoQuestion.questions.map( (questions : any) => {
                    let questionType = questions['question-type'];
                    switch (questionType) {
                      case "Identifying Information (True/False/Not Given)" : return identifyingInformationData(questions['list-of-questions']);
                      case "Note Completion" : return noteCompletionData(questions['list-of-questions']);
                      case "Sentence Endings" : return sentenceEndingsData(questions);
                      case "Multiple Choice (more than one answer)" : return multipleChoiceData(questions['list-of-questions']);
                      case "Multiple Choice" : return singleChoiceData(questions['list-of-questions']);
                    }
                  })
                }
              </tbody>
            </table>
          </> 
        : <></> 
      }
      {
        tableData?.partThreeQuestion ? 
        <>
          <h1 className='font-bold text-2xl my-5 text-center'>Part 3</h1>
          <p className='my-3 text-center text-xl font-semibold'>{tableData.partThreeQuestion.paragraph.title}</p>
          <p className='text-justify'>{tableData.partThreeQuestion.paragraph.content}</p>
          <table className="table-auto border-collapse mx-auto w-[80%] mt-16">
            <thead className="sticky -top-1 bg-white">
               <tr>
                  <th className="py-2 px-4 border">Test Id</th>
                  <th className="py-2 px-4 border">Question Type</th>
                  <th className="py-2 px-4 border">Question Id</th>
                  <th className="py-2 px-4 border">Question</th>
                  <th className="py-2 px-4 border">Options</th>
               </tr>
            </thead>
            <tbody>
              {
                tableData.partThreeQuestion.questions.map( (questions : any) => {
                  let questionType = questions['question-type'];
                  switch (questionType) {
                    case "Identifying Information (True/False/Not Given)" : return identifyingInformationData(questions['list-of-questions']);
                    case "Note Completion" : return noteCompletionData(questions['list-of-questions']);
                    case "Sentence Endings" : return sentenceEndingsData(questions);
                    case "Multiple Choice (more than one answer)" : return multipleChoiceData(questions['list-of-questions']);
                    case "Multiple Choice" : return singleChoiceData(questions['list-of-questions']);

                  }
                })
              }
            </tbody>
          </table>
        </> 
      : <></>
      }
    </div>)
  }

  const renderWritingTables = (tableData : any) => {
    return tableData.map((item: any, index: number) => {
      if (item?.type === "part1") {
        return (
          <table className="table-auto border-collapse mx-auto w-[90%] mt-16 text-2xl" key={'part1'}>
            <thead className="sticky -top-1 bg-white">
              <tr>
                <th className="py-2 px-4 border">Type</th>
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">Task</th>
                <th className="py-2 px-4 border">Image</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4 border text-center">{item.type}</td>
                <td className="py-2 px-4 border text-center">{item.name}</td>
                <td className="py-2 px-4 border text-center">{item.task}</td>
                <td className="py-2 px-4 border text-center">
                  <img src={`https://ielts-app.s3.amazonaws.com/images/task1/${item.image}`} alt='test-question' className='w-[60rem] h-96'/>
                </td>
              </tr>
            </tbody>
          </table>
        );
      } else if (item?.type === "part2") {
        return (
          <table className="table-auto border-collapse mx-auto w-[90%] mt-16 text-2xl" key={'part2'}>
            <thead className="sticky -top-1 bg-white">
              <tr>
                <th className="py-2 px-4 border">Type</th>
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">Task</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4 border text-center">{item.type}</td>
                <td className="py-2 px-4 border text-center">{item.question_name}</td>
                <td className="py-2 px-4 border text-center">{item.task}</td>
              </tr>
            </tbody>
          </table>
        );
      } else {
        return <></>; // Handle other cases if needed
      }
    });
  };

  const writingTableRendering = (tableData : any) : React.ReactNode => {
    return (
      <div>
       {renderWritingTables(tableData)}
      </div>
    )
  }
  const identifyingInformationData = (questionData : any) => {
    return <> 
    {
      questionData.map((item: any) => (
      <tr key={testId}>
        <td className="py-2 px-4 border text-center">{testId}</td>
        <td className="py-2 px-4 border text-center">Multiple Choice</td>
        <td className="py-2 px-4 border text-center">{item.qId}</td>
        <td className="py-2 px-4 border">{item.qName}</td>
        <td className="py-2 px-4 border">{item.options.join(", ")}</td>
      </tr>
    ))
    }
    </>
  }

  const noteCompletionData = (questionData : any) => {
    return <> 
    {
      questionData.map((item: any) => (
      <tr key={testId}>
        <td className="py-2 px-4 border text-center">{testId}</td>
        <td className="py-2 px-4 border text-center">Note Completion</td>
        <td className="py-2 px-4 border text-center">{item.qId}</td>
        <td className="py-2 px-4 border">{item.qName}</td>
        <td className="py-2 px-4 border">N/A</td>
      </tr>
    ))
    }
    </>
  }

  const sentenceEndingsData = (questionData : any) => {
    return (
      <tr key={testId}>
        <td className="py-2 px-4 border text-center">{testId}</td>
        <td className="py-2 px-4 border text-center">Sentence Endings</td>
        <td className="py-2 px-4 border text-center">{questionData['question-range']}</td>
        <td className="py-2 px-4 border">{questionData['drag-and-drop-summary-completion-object'].passage}</td>
        <td className="py-2 px-4 border">{questionData['drag-and-drop-summary-completion-object'].answers.join(", ")}</td>
      </tr>) 
  }

  const multipleChoiceData = (questionData : any) => {
    return <> 
    {
      questionData.map((item: any) => (
       <tr key={testId}>
        <td className="py-2 px-4 border text-center">{testId}</td>
        <td className="py-2 px-4 border text-center">Multiple Choice</td>
        <td className="py-2 px-4 border text-center">{item.qId}</td>
        <td className="py-2 px-4 border">{item.qName}</td>
        <td className="py-2 px-4 border">{item.options.join(", ")}</td>
       </tr>))
    }
    </> 
  }

  const singleChoiceData = (questionData : any) => {
    return <> 
    {
      questionData.map((item: any) => (
      <tr key={testId}>
        <td className="py-2 px-4 border text-center">{testId}</td>
        <td className="py-2 px-4 border text-center">Single Choice</td>
        <td className="py-2 px-4 border text-center">{item.qId}</td>
        <td className="py-2 px-4 border">{item.qName}</td>
        <td className="py-2 px-4 border">{item.options.join(", ")}</td>
      </tr>))
    }
    </>
  }

  const renderTable = () => {
    if (!testData) {
      return <></>;
    }else if (module === "speaking") {
      return speakingTableRendering(testData);
    }else if (module === "reading") {
      return readingTableRendering(testData);
    }else if (module === "writing") {
      return writingTableRendering(testData);
    }
    return <></>;
  };

  const renderData = () => {
    if (user === "+918897823511" || user === "+919908479039") {
      if (loading) {
        return(
          <div className="w-[10%] mx-auto my-36">
            <Loader />
          </div>
        );
      } else if (error) {
        return (
          <p className="text-4xl flex items-center justify-center">Something went wrong</p>
        );
      } else {
        return (
          <div className='flex justify-center items-center mb-20'>{renderTable()}</div>
        );
      }
    } else {
      return(
        <div className='h-screen flex'>
          <h2 className='text-xl w-1/3 px-5 py-10 border border-[#E31837] rounded-xl m-auto'>
            Sorry, It seems you don't have access to this page,. please
            {' '}<button className='text-blue-500 cursor-pointer underline' onClick={() => navigate("/login")}>
              log in
            </button>
            {' '}to your account
          </h2>
        </div>
      );
    }
  }

  return (
    <div>
      {
        renderData()
      }
    </div>
  )
}

export default TestView