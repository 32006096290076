import { useStore } from "../../../Hooks/use-store";
import CheckCircle from "../../../icons/CheckCircle.png";
import { useEffect } from "react";
import EndTestLoader from "./endTestLoader";
import { observer } from "mobx-react-lite";

const PartTestCompleted = ({
  testType,
  nextTest,
  nextStep,
  handleClose
}: any) => {
  const { startTestStore } = useStore();
  let testId = startTestStore.testId;
  useEffect(() => {
    submiTest();
    async function submiTest() {
      if (testType === "Part 3") {
        if (testId) {
          //here you need to paste the code
          startTestStore.setIsAnimated(false);
          startTestStore.setIsRecording(false);
        }
      }
    }
    if(startTestStore.testType !== 'full'){
      startTestStore.setPartType('Part 3')
    }
    }, []);
  return (
    <div
        style={{
          background: "linear-gradient(216deg, #FFF9FA 1.21%, #FFF 97.42%)",
          border: "1px solid rgba(227, 24, 55, 0.30)",
        }}
        className="lg:w-[40%] w-[80%] mx-auto mt-8 rounded-md border border-solid border-[rgba(227, 24, 55, 0.30)] bg-gradient-to-br from-[#FFF9FA] via-transparent to-[#FFF] p-5 pb-10 h-auto mx-10 "
      >
        {testType !== "Part 3" ? (
          <div className="flex flex-col justify-center items-center ">
            <h1 className="font-extrabold text-xl">Woohooo !</h1>
            <h1 className="text-2xl font-[350] mb-6">
              {testType} is Completed
            </h1>
            <img src={CheckCircle} alt="check circle"/>
            <h1 className="text-center text-xl font-[350]">
              Click <span className="text-[#E31837]">Continue Test </span>to{" "}
              <br /> start {nextTest}
            </h1>
            <div className="flex justify-center mt-10">
              {" "}
              <button
                className="mx-auto bg-red-500 rounded-full px-4 py-2 text-white"
                onClick={() => {
                  nextStep();
                  startTestStore.setGetTestV2results([]);
                }}
              >
                Continue
              </button>
            </div>{" "}
          </div>
        ) : <EndTestLoader store ={startTestStore} handleClose={handleClose}  module="speaking"/>}
      </div>
  );
};

export default observer(PartTestCompleted);
