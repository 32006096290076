// DetailedResultsReading.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../Hooks/use-store';
import ReadingReport from './ReadingReport';
import Tabs from '../ieltsResultsTabs/Tabs';

const DetailedResultsReading: React.FC = () => {
  const { resultsStoreReading } = useStore();
  const { individualTestScorePart1, individualTestScorePart2, individualTestScorePart3, partType } = resultsStoreReading;

  const tabs = [
    { label: 'Part 1', content: <ReadingReport partDetails={individualTestScorePart1} /> },
    { label: 'Part 2', content: <ReadingReport partDetails={individualTestScorePart2} /> },
    { label: 'Part 3', content: <ReadingReport partDetails={individualTestScorePart3} /> },
  ];

  return (
    <div className="bg-[#E3E5E9]-500 border-2 rounded-[8px] w-[90%]">
      <header>
        <Tabs tabs={tabs} partType={partType} />
      </header>
    </div>
  );
};

export default observer(DetailedResultsReading);
