import { ReadingTestResultsApi } from "./../api/reading-test-results-api";
import { makePersistable } from "mobx-persist-store";
import { makeObservable, observable, action } from "mobx";
import { BaseTestResultsStore } from "./baseClass";

export class TestReadingResultsStore extends BaseTestResultsStore {
  graphData = {
    dates: Array<string>,
    scores: Array<number>,
  };
  individualTestScorePart3: any = {};
  aiFeedbackData: any = {};
  getQuestionAnalysisPartOne: any = {};
  getQuestionAnalysisPartTwo: any = {};
  ReadingPart1Answer = {
    text: "",
    img: "",
  };

  ReadingPart2Answer = {
    text: "",
    img: "",
  };
  isGrapghDataLoading = false;
  partType: any = "";

  constructor(private ReadingTestResultsApi: ReadingTestResultsApi) {
    super("readingResults");
    makeObservable(this, {
      graphData: observable,
      individualTestScorePart3: observable,
      aiFeedbackData: observable,
      getQuestionAnalysisPartOne: observable,
      getQuestionAnalysisPartTwo: observable,
      ReadingPart1Answer: observable,
      ReadingPart2Answer: observable,
      isGrapghDataLoading: observable,
      partType: observable,
      setReadingPart1Answers: action,
      setReadingPart2Answers: action,
      testResultsUpdate: action,
      setTestCompleted: action,
      freeTestCompletedClicked: action,
      getGraphData: action,
      getTestScoreReading: action,
      getQuestionAnalysis: action,
      isFreeTestCompleted: action,
    });
    makePersistable(this, {
      name: "TestWritingResultsStore",
      properties: [
        "individualTestScorePart3",
        "getQuestionAnalysisPartOne",
        "getQuestionAnalysisPartTwo",
        "partType",
        "aiFeedbackData",
      ],
      storage: window.localStorage,
    });
  }

  async setReadingPart1Answers(value: any, img: any) {
    this.ReadingPart1Answer.text = value;
    this.ReadingPart1Answer.img = img;
  }
  async setReadingPart2Answers(value: any, img: any) {
    this.ReadingPart2Answer.text = value;
    this.ReadingPart2Answer.img = img;
  }

  async testResultsUpdate(querryParam: string, page: number) {
    const response = await this.ReadingTestResultsApi.testResultsUpdateReading(
      querryParam,
      page
    );
    this.testResults = response.data.data;
    if (response.data.data.length === 0) {
      this.isTestsFound = true;
    } else {
      this.isTestsFound = false;
    }
    return response;
  }

  async setTestCompleted(tId: any, tType: any) {
    this.completedTestId = tId;
    this.completedTestType = tType;
  }

  async freeTestCompletedClicked(bool: boolean) {
    this.isFreeTestCompletedClicked = bool;
  }

  async getGraphData() {
    return await this.ReadingTestResultsApi.getAverageScoreDataReading();
  }

  // async getAverageTestScore() {
  //   try {
  //     this.isGrapghDataLoading = true;
  //     const res = await this.ReadingTestResultsApi.getAverageScoreDataReading();
  //     if (res.status === 200) {
  //       this.averageScore = res.data.avg_score
  //         ? res.data.avg_score.toFixed(2)
  //         : 0;
  //       const transformedData = res.data.data.reduce(
  //         (result: any, item: any) => {
  //           item.score = item.score.toFixed(2);
  //           let decimalPart = Number(
  //             (item.score - Math.floor(item.score)).toFixed(2)
  //           );
  //           let value;
  //           if (decimalPart > 0 && decimalPart <= 0.24) {
  //             value = Math.floor(item.score);
  //           } else if (decimalPart >= 0.25 && decimalPart <= 0.49) {
  //             value = Math.floor(item.score) + 0.5;
  //           } else if (decimalPart > 0.5 && decimalPart <= 0.74) {
  //             value = Math.floor(item.score) + 0.5;
  //           } else if (decimalPart >= 0.75 && decimalPart <= 0.99) {
  //             value = Math.ceil(item.score);
  //           } else {
  //             value = item.score;
  //           }
  //           result.scores.push(Number(value));
  //           const dateObject = new Date(item.date);
  //           result.dates.push(formatDateToCustomFormat(dateObject));
  //           return result;
  //         },
  //         { scores: [] as string[], dates: [] as number[] }
  //       );
  //       this.graphData = transformedData;
  //       this.isGrapghDataLoading = false;
  //       this.hasUserTakenAnyTest = res.data.data.length > 0;
  //       return res.data.avg_score;
  //     }
  //   } catch (e) {
  //     this.isGrapghDataLoading = false;
  //     console.log(e, "ERROR");
  //   }
  // }

  async getTestScoreReading(tstId: any, type: any) {
    this.isLoading = true;
    this.partType = type;
    this.isResultsLoading = true;
    try {
      const res = await this.ReadingTestResultsApi.getTestScoreApiReading(
        tstId
      );
      if (res.status === 200) {
        this.isResultsLoading = false;
        this.isLoading = false;
        if (type === "part1") {
          this.individualTestScorePart1 = res.data.part1;
        } else if (type === "part2") {
          this.individualTestScorePart2 = res.data.part2;
        } else if (type === "part3") {
          this.individualTestScorePart3 = res.data.part3;
        } else {
          this.individualTestScorePart1 = res.data.part1;
          this.individualTestScorePart2 = res.data.part2;
          this.individualTestScorePart3 = res.data.part3;
        }
      } else {
        this.isLoading = false;
      }
    } catch (e) {
      this.isLoading = false;
      console.log(e);
    }
  }

  async getQuestionAnalysis(tstId: any, typeOfTest: any) {
    try {
      const res =
        await this.ReadingTestResultsApi.getQuestionAnalysisApiReading(tstId);
      if (res.status === 200) {
        this.aiFeedbackData = res.data;
      }
    } catch (e) {
      console.log(e, "questionanalysispart");
    }
  }
  async isFreeTestCompleted(test_id: any) {
    this.isLoading = true;
    try {
      const res = await this.ReadingTestResultsApi.isFreeTestCompletedReading(
        test_id
      );
      if (res.status === 200) {
        return res.is_completed;
      }
      this.isLoading = false;
    } catch (e) {
      console.log(e, "idFreeTest Completed");
      this.isLoading = false;
    }
  }
}
