import { observer } from "mobx-react-lite";

const SingleChoiceQuestions = ({
  item,
  handleRadioChange,
  singleAnswers,
}: any) => {
 
  return (
    <div>
      <div className="text-[#3C424C] text-sm"> Choose the correct answer</div>
      <div className="flex flex-wrap w-full justify-between">
        {item["listOfQuestions"].length > 0 &&
          item["listOfQuestions"].map((question: any, ind: any) => {
            return (
              <div
                key={question.questionId}
                className="w-[45%] p-4 border border-[#E3E5E9] rounded-xl m-2 flex flex-col"
              >
                <div className="text-[#292D35] text-base">{`${question.questionId}. ${question.questionName}`}</div>
                <div>
                  {question.options.length > 0 &&
                    question.options.map((option: any, i: any) => {
                      const isChecked = singleAnswers?.some((item: any) => item.qId == question.questionId && item.answer === option) ?? false;
                      return (
                        <div key={question.questionId} className="text-[#737E93]">
                          <input
                            type="radio"
                            className="w-3 h-3 mr-1 "
                            name={question.questionId}
                            value={option}
                            checked={isChecked}
                            onChange={(e) =>
                              handleRadioChange(e.target.name, e.target.value)
                            }
                          />{" "}
                          {option}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default observer(SingleChoiceQuestions);
