import apiSauce from "./api-sauce";

export class StartTestReadingApi{

    async fetchFreeTestDataReading():Promise<any>{
        return await apiSauce.apisauce?.get('/free-reading-tests')

    }
    async generateTestReading(typeTest:any):Promise<any>{
        return await apiSauce.apisauce?.post(`/generate-reading-test?type=${typeTest}`)
    }

    async startFreeTestReading(id:any){
        return await apiSauce.apisauce?.post(`/start-reading-test?test_id=${id}`)
    }

    async getTestReading(payload:any){
        return await apiSauce.apisauce?.get(`/get-reading_test-v2?test_id=${payload.id}&type=${payload.type}`)
    }

    async submitAnswerApiReading(payload:any){
        return await apiSauce.apisauce?.post(`/submit-reading-answer`, payload)
    }

    async endTestReading(payload:any){
        return await apiSauce.apisauce?.post(`/end-reading-test`,payload)
    }

}