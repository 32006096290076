import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

const MatchingHeaders = observer(({ 
  item, 
  handleDragStartMatchingHeader,
  handleDragOver,
  handleDropMatchingHeading,
  setMatchingHeadingAnswers,
  matchingHeadingAnswers,
 }: any) => {
 
useEffect(() => {
  setMatchingHeadingAnswers(item[`drag-and-drop-heading-completion`][`headings`]);
},[]);

  return (
    <>
      <div className="text-[#3C424C] text-sm">
        Complete each sentence in the Paragraph. Choose the correct answer and{" "}
        <span className="font-semibold"> Drag and Drop it into the Field.</span>
      </div>
      <button //these have been changed from span tag to button to avoid sonar issue 
        className="min-h-[100px]"
        onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => handleDropMatchingHeading(e)}
      >
        {matchingHeadingAnswers.length > 0 ? matchingHeadingAnswers.map(
          (dragAnswer: any) => {
            return (
            <button //these have been changed from span tag to button to avoid sonar issue
              draggable
              key={dragAnswer}
              onDragStart={(e) => handleDragStartMatchingHeader(e, dragAnswer)}
              onDragOver={(e) => handleDragOver(e)}
              className="min-h-[2rem] cursor-pointer border rounded-lg px-4 py-2 my-1 bg-[#FFEAEA] w-full text-[#3C424C] text-justify border-[#E31837]">
              {dragAnswer}
            </button>);
          }
        ) :  null}
      </button>
    </>
  );
});

export default MatchingHeaders;
