import { makePersistable } from 'mobx-persist-store';
import {  makeAutoObservable } from "mobx";
import { StartTestApi } from "../api/start-test-api";

export class StartTestStore {
  audioFile: string = "";
  isAnimated: boolean = false;
  isRecording: boolean = false;
  blobURL: string = "";
  isBlocked = true;
  isTestCompleted = false;
  testId = null;
  testType =null;
  getTestV2Result:any =[];
  testSubmittedArray:any =[];
  counter :any = 0;
  isTaskCardDisplay:boolean = false;
  partTwoCardId:any;
  partTwoCardDetails:any = {};
  startPartOne:boolean = false;
  startPartTwo:boolean=false;
  startPartThree:boolean=false;
  isLoading:boolean = false;
  isTestStarted:any =null;
  step:number = 0;
  nextStep:string="";
  currentStep:string="";
  openCheckoutPlansDialog:boolean = false;
  partType:string = "";
  tryAgainButton:boolean=false;
  isTestEnded:any = null;
  completeTestFailed:boolean = false;
  progressBarWidth:number = 100;
  storeName:any = "speaking";
  pulsateImageTime:number =0;
  categoriesList:any =[];
  isFetchDetails:boolean= false;
  selectedTopic:any ={}


  setSelectedTopic =(topic:any)  => {
      this.selectedTopic = topic
  }

  async setTime(time:number){
    if(time){this.pulsateImageTime =time}

  }

async setStartPartone(bool:boolean) {
   this.startPartOne = bool
}

async setStartPartTwo(bool:boolean) {
  this.startPartTwo = bool
}

async setPartType(part:string){
  this.partType = part
}
async setStartPartThree(bool:boolean) {
  this.startPartThree = bool
}

  constructor(private startTestApi: StartTestApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'StartTestStore', properties: ['testId','testType','partType','isTestEnded','storeName','categoriesList','completeTestFailed'], storage: window.localStorage });
  }

 async  setTestId(id: null,type: null){
  this.testId = id;
  this.testType = type;

  }
  async setTestType(part:any){
    this.testType =part
  }

  async startFreeTest() {
    try{
      this.isTestStarted =true
      const response = await this.startTestApi.startFreeTest(this.testId);
      if(response?.status === 200){
        this.isTestStarted = null
      }
      
      return response;
    }catch(e){
      this.isTestStarted = null
      console.log(e,"StartTest ERROR")
    }
   
  }

  async getFreeTestPartOneData(tId: any, tType: any) {
    try{
    let payload = {
      id: tId,
      type: tType,
    };
    this.isLoading = true;
    const res = await this.startTestApi.getFreeTestPartOneDataApi(payload);
    const dataObjects = res.data.split("data:");
    dataObjects.shift();

  const cleanAndParseData =(str: any) => {
    try {
      // Replace True/False with true/false
      str = str.replace(/\bFalse\b/g, "false");
      str = str.replace(/\bTrue\b/g, "true");

      // Clean up specific problematic character
      str = str.replace("you've", "you have");

      // Remove single quotes around keys and values
      str = str.replace(/'([^']+)'/g, '"$1"');

      // Remove unescaped single quotes inside double-quoted strings
      str = str.replace(/'([^"]*[^\\])'([^"]*[^\\])'/g, '"$1$2"');

      const parsed = JSON.parse(str);
      console.log(parsed,"parsed data from get test v2 result")
      return parsed;
    } catch (error) {
      console.error("Invalid JSON:", str, "Error:", error);
      return {};
    }
  }
  
  // Convert the remaining strings to objects
  const objArr = dataObjects.map((str: any) => {
    return cleanAndParseData(str);
  });
 
    if (objArr && objArr.length > 0) {
      let cardPart2 = objArr.find((item :any) => item.data.card);
      if(cardPart2){
        this.partTwoCardId = cardPart2.data.id;
        this.partTwoCardDetails = cardPart2.data.card;
      }
      this.getTestV2Result = objArr;
      this.isLoading = false;
      return objArr;
    }
    }catch(e){
      console.log(e,"getFreeTestPartOneData")
    }
  }

  async fetchCategories(){
    try{
      this.isFetchDetails = true;
      let res = await this.startTestApi.generateCategories();
      if(res.status === 200){
        this.categoriesList = res.data
        this.isFetchDetails = false
      }
      return res
    }catch(e){
      this.isFetchDetails = false
      console.log(e,"ERROR")
    }
   
  }


  async generateTestPro(typeTst: any) {
    try{
      this.testType = typeTst;
      this.isLoading=true;
      this.isFetchDetails = true;

      let res = await this.startTestApi.generateProTestApi(typeTst,this.selectedTopic?.id ? this.selectedTopic.id :0);
      if (res.status === 200) {
        this.testId = res.data.test_id;
        this.isLoading = false;
        this.tryAgainButton = false;
        this.isFetchDetails = false
      } else {
        this.isLoading = false;
        this.tryAgainButton = true;
        this.isFetchDetails = false
      }
      return res
    }catch(e){
      console.log(e,"generateTestProApiErr");
      this.isFetchDetails = false
    }


  }

async setProgressBar(width:any){
  this.progressBarWidth = width;
}

async setIsLoading(){
  this.isLoading = false

}
  setCurrentStep(currentStep: string) {
    this.currentStep = currentStep;
  }

  setStep(stepNo: number) {
    this.step = stepNo;
  }

  setNextStep(nextStep:string){
    this.nextStep = nextStep;
  }

  setAudioFile(file: string) {
    this.audioFile = file;
  }

  setIsAnimated(bool: boolean) {
    this.isAnimated = bool;
  }

  setIsBlocked(bool: boolean) {
    this.isBlocked = bool;
  }
  setIsRecording(bool: boolean) {
    this.isRecording = bool;
  }

  setIsTaskCard(isTask:boolean){
    this.isTaskCardDisplay = isTask;
  }
  
  setTryAgainButton(bool:boolean){
    this.tryAgainButton=bool;
  }

  async setCompleteTestFailed(value : any){
    this.completeTestFailed = value;
  }

  async submitAnswer(payload: any) {
    try{
      this.isTestEnded = true; 
      this.tryAgainButton = false;

      let testTypeAtSubmit = this.testType;
      let partTypeAtSubmit = this.partType;
      let questionCounter = this.counter;
      let maxQuestions = this.getTestV2Result.length;
      let timerAtTestEnd = this.pulsateImageTime;
      let testIdAtSubmit = this.testId;

      const res = await this.startTestApi.submitAnswerApi(payload);
      if (res.status == 200) {
        //conditions to check wheather endTestAPI should be triggered or not
        const conditions =
          questionCounter >= maxQuestions ||
          timerAtTestEnd >= 240; 
        if (testTypeAtSubmit !== "full" && conditions) {
          this.testId && this.completeTest(this.testId);
        } else if (partTypeAtSubmit === "Part 3" && conditions) {
          if (testIdAtSubmit) {
            this.completeTest(testIdAtSubmit);
            this.setGetTestV2results([]); //if this fails move this code to index of partTestCompleted component
          }
        }
      } else {
        console.error("Speaking SubmitAnswer API Failed");
        this.isTestEnded = false;
        this.completeTestFailed = true;
      }
    }catch(e){
      console.log(e,"SubmitAnswer Failed");
      this.isTestEnded = false;
      this.completeTestFailed = true;
    }
  }
  

  setGetTestV2results(arr:[]){
    this.getTestV2Result=arr;
  }
  
  async setCounter(isInc :boolean){
    if(isInc){
      this.counter++
     
    }else{
      this.counter = 0;
    }

  }

async setIsTestEnded(bool:any){
      this.isTestEnded = null;
}

  async completeTest(test_Id :number){
    try{
      this.setCounter(false)
      let response = await this.startTestApi.endTestApi(test_Id);
      if(response.status === 200){
        this.isTestEnded = null;
        this.completeTestFailed = false;
        return response.data.message
      }else{
        this.isTestEnded = false;
        this.completeTestFailed = true;
      }
    }catch(e){
      this.completeTestFailed = true;
      this.isTestEnded = false;
    }
  }

  async choosePlansDialog(bool:boolean){
 this.openCheckoutPlansDialog = bool;
  }
}
