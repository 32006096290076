export interface ApiConfig {
  url: string

}

const get_profile = () => {
  const host = window.location.host;
  if (host === 'localhost:3000') return 'dev';
  if (host === 'ielts-staging.web.app') return 'staging';
  // Remaining host need to be added here going forth as per env setup
  return 'unknown';
};

let DOMAIN = 'https://ielts1.api.cambri.ai';
let OAuth ='https://ielts1.api.cambri.ai/api/auth/google'
const profile = get_profile();


switch (profile) {
  case 'dev':
    case 'staging':
    DOMAIN = 'https://staging.api.ielts.cambri.ai';
    OAuth ='https://staging.api.ielts.cambri.ai/api/auth/google'
    break;
  default:
    break;
}

export const be_url = DOMAIN;

export const commonHeaders = {
  'Content-Type': 'application/json',
};
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: `${DOMAIN}/api`,

}

export const OAuthUrl =OAuth
