import { useEffect } from "react";
import { useStore } from "../../Hooks/use-store";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../icons/closeTest.png";
import profileImage from "../../icons/UserCircle.png";
import clockIcon from "../../icons/Alarm.png";
import ConfirmYourDetails from "../common/confirmYourDetails";
import ReadingInstructions from "../Reading/readingInstructions";
import PartTestCompletedCard from "../common/PartTestCompletedCard";
import ReadingPart1 from "./readingParts/ReadingPart1";
import ReadingPart2 from "./readingParts/ReadingPart2";
import ReadingPart3 from "./readingParts/ReadingPart3";
import { observer } from "mobx-react-lite";
import WritingTimer from "../common/writingTimer";
import Loader from "../../Loader";
import Band_details from "../../constants/Band_details.json";
import { Shellwrapper } from "../Routing/RoutingC";
import ReadingErrorPage from "../../pages/errorPage";
import { caluculateScore, aiFeedbackPayload } from "../../utils";
import UserProfile from "../common/userProfile";

interface BandDetails {
  [key: string]: any;
}

interface Payload {
  data?: any;
  aiFeedback?: any;
}

function updatePayloadWithScoreDetails(
  payload: Payload,
  overall_score: any,
  formattedDateTime: string,
  BandDetails: BandDetails,
  partType : string
): any {
  // Round the overall score to get the band number
  let band_number = Math.round(overall_score);
  let band_number_string = "band_" + band_number;

  // Get the detailed report from BandDetails
  let detailedReport = BandDetails[band_number_string];

  // Ensure the payload object has the required properties
  if (!payload.data) {
    payload.data = {};
  }
  if (!payload.aiFeedback) {
    payload.aiFeedback = {};
  }

  // Update the payload with score details
  payload.data[partType] = detailedReport;
  payload.data.overallBand = overall_score || 0;
  payload.data[partType].overall_score = overall_score;
  payload.data.date = formattedDateTime;

  // Return the updated payload
  return payload;
}

const FullTestReading = observer(() => {
  const { startTestStoreReading, resultsStoreReading, userStore } = useStore();
  const { stepManagement } = resultsStoreReading;
  const { currentStepName, currentPartName, nextPartName } = stepManagement;
  const BandDetails: any = Band_details;
  const {
    readingErrorMessage,
    part1SingleAnswer,
    part1MultipleAnswer,
    part1NoteCompletionAnswer,
    part1SummaryCompletionOneWordAnswers,
    part1DragAndDropAnswers,
    part1TaskIdentifyingItemAnswers,
    part1SenetenceComplete,
    part1DraggedCurrentSentence,
    part1MatchingSentenceEndAnswers,
    part1TableCompletionAnswer,
    part1MatchingFeatureAnswers,
    part1MatchingHeadingAnswer,
    part2SingleAnswer,
    part2MultipleAnswer,
    part2NoteCompletionAnswer,
    part2SummaryCompletionOneWordAnswers,
    part2DragAndDropAnswers,
    part2TaskIdentifyingItemAnswers,
    part2SenetenceComplete,
    part2DraggedCurrentSentence,
    part2MatchingSentenceEndAnswers,
    part2TableCompletionAnswer,
    part2MatchingFeatureAnswers,
    part2MatchingHeadingAnswer,
    part3SingleAnswer,
    part3MultipleAnswer,
    part3NoteCompletionAnswer,
    part3SummaryCompletionOneWordAnswers,
    part3DragAndDropAnswers,
    part3TaskIdentifyingItemAnswers,
    part3SenetenceComplete,
    part3DraggedCurrentSentence,
    part3MatchingSentenceEndAnswers,
    part3TableCompletionAnswer,
    part3MatchingFeatureAnswers,
    part3MatchingHeadingAnswer,
    setPart1AllAnswers,
    setPart2AllAnswers,
    setPart3AllAnswers,
    setTimer,
    testType,
    setPartOneScore,
    setPartTwoScore,
    setPartThreeScore,
    part1Questions,
    part2Questions,
    part3Questions,
  } = startTestStoreReading;

  const navigate = useNavigate();

  const combineAnswers = (setPartAllAnswers: any, ...rest: any) => {
    setPartAllAnswers(rest.flat(Infinity));
  };

  const nextStep = () => {
    //set the mockdata of part1,2,3 questions after integration cleanup this

    // Based on the currentStepName sethe stepvalues like currentStepName,currentPartName,nextPartName and also tabs at the bottom

    if (currentStepName === "confirm_your_details") {
      resultsStoreReading.setStepManagement("writing_instructions", "", "");
    }

    if (currentStepName === "writing_instructions") {
      resultsStoreReading.setActiveTab(0);
      setTimer(3600);
      resultsStoreReading.setStepManagement("Part 1", "", "");
    }

    if (currentStepName === "Part 1") {
      if (testType !== "full") {
        resultsStoreReading.setStepManagement(
          "part_test_completed",
          "Part 1",
          ""
        );
      } else {
        resultsStoreReading.setStepManagement(
          "part_test_completed",
          "Part 1",
          "Part 2"
        );
      }
      combineAnswers(
        setPart1AllAnswers,
        part1SingleAnswer,
        part1MultipleAnswer,
        part1NoteCompletionAnswer,
        part1SummaryCompletionOneWordAnswers,
        part1DragAndDropAnswers,
        part1TaskIdentifyingItemAnswers,
        part1SenetenceComplete,
        part1DraggedCurrentSentence,
        part1MatchingSentenceEndAnswers,
        part1TableCompletionAnswer,
        part1MatchingFeatureAnswers,
        part1MatchingHeadingAnswer
      );
    }

    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 1"
    ) {
      resultsStoreReading.setActiveTab(1);
      resultsStoreReading.setStepManagement("Part 2", "Part 2", "Part 3");
    }

    if (currentStepName === "Part 2") {
      if (testType !== "full") {
        resultsStoreReading.setStepManagement(
          "part_test_completed",
          "Part 2",
          ""
        );
      } else {
        resultsStoreReading.setStepManagement(
          "part_test_completed",
          "Part 2",
          "Part 3"
        );
      }
      combineAnswers(
        setPart2AllAnswers,
        part2SingleAnswer,
        part2MultipleAnswer,
        part2NoteCompletionAnswer,
        part2SummaryCompletionOneWordAnswers,
        part2DragAndDropAnswers,
        part2TaskIdentifyingItemAnswers,
        part2SenetenceComplete,
        part2DraggedCurrentSentence,
        part2MatchingSentenceEndAnswers,
        part2TableCompletionAnswer,
        part2MatchingFeatureAnswers,
        part2MatchingHeadingAnswer
      );
    }

    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 2"
    ) {
      resultsStoreReading.setActiveTab(2);
      resultsStoreReading.setStepManagement("Part 3", "Part 3", " ");
    }

    if (currentStepName === "Part 3") {
      resultsStoreReading.setStepManagement(
        "part_test_completed",
        "Part 3",
        ""
      );
      combineAnswers(
        setPart3AllAnswers,
        part3SingleAnswer,
        part3MultipleAnswer,
        part3NoteCompletionAnswer,
        part3SummaryCompletionOneWordAnswers,
        part3DragAndDropAnswers,
        part3TaskIdentifyingItemAnswers,
        part3SenetenceComplete,
        part3DraggedCurrentSentence,
        part3MatchingSentenceEndAnswers,
        part3TableCompletionAnswer,
        part3MatchingFeatureAnswers,
        part3MatchingHeadingAnswer
      );
    }

    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 3"
    ) {
      resultsStoreReading.setActiveTab(3);
      resultsStoreReading.setStepManagement("confirm_your_details", "", "");
      setTimer(3600);
      navigate("/reading");
    }
  };

  const submitTest = () => {
    resultsStoreReading.setStepManagement("confirm_your_details", "", "");
    navigate("/writing");
  };

  const caluculateBandScorePartOne = (partMarks: any, part: any) => {
    let userScore = partMarks;
    let band_value;
    if (userScore ===0) {
      band_value = 0;
    } else if (userScore ===1) {
      band_value = 1.5;
    } else if (userScore ===2) {
      band_value = 3;
    } else if (userScore ===3) {
      band_value = 3.5;
    } else if (userScore ===4) {
      band_value = 4;
    } else if (userScore ===5) {
      band_value = 5;
    } else if (userScore ===6) {
      band_value = 5;
    } else if (userScore ===7) {
      band_value = 5.5;
    } else if (userScore ===8) {
      band_value = 6;
    } else if (userScore ===9) {
      band_value = 6.5;
    } else if (userScore ===10) {
      band_value = 7;
    } else if (userScore ===11) {
      band_value = 7.5;
    } else if (userScore === 12) {
      band_value = 8;
    } else if (userScore >= 13) {
      band_value = 9;
    }
    return band_value;
  };


  const submitAnswer = async () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}`;
    if (startTestStoreReading.testType === "full") {
      combineAnswers(
        setPart1AllAnswers,
        part1SingleAnswer,
        part1MultipleAnswer,
        part1NoteCompletionAnswer,
        part1SummaryCompletionOneWordAnswers,
        part1DragAndDropAnswers,
        part1TaskIdentifyingItemAnswers,
        part1SenetenceComplete,
        part1DraggedCurrentSentence,
        part1MatchingSentenceEndAnswers,
        part1TableCompletionAnswer,
        part1MatchingFeatureAnswers,
        part1MatchingHeadingAnswer
      );
      combineAnswers(
        setPart2AllAnswers,
        part2SingleAnswer,
        part2MultipleAnswer,
        part2NoteCompletionAnswer,
        part2SummaryCompletionOneWordAnswers,
        part2DragAndDropAnswers,
        part2TaskIdentifyingItemAnswers,
        part2SenetenceComplete,
        part2DraggedCurrentSentence,
        part2MatchingSentenceEndAnswers,
        part2TableCompletionAnswer,
        part2MatchingFeatureAnswers,
        part2MatchingHeadingAnswer
      );
      combineAnswers(
        setPart3AllAnswers,
        part3SingleAnswer,
        part3MultipleAnswer,
        part3NoteCompletionAnswer,
        part3SummaryCompletionOneWordAnswers,
        part3DragAndDropAnswers,
        part3TaskIdentifyingItemAnswers,
        part3SenetenceComplete,
        part3DraggedCurrentSentence,
        part3MatchingSentenceEndAnswers,
        part3TableCompletionAnswer,
        part3MatchingFeatureAnswers,
        part3MatchingHeadingAnswer
      );
    }
    if (startTestStoreReading.testType === "part1") {
      combineAnswers(
        setPart1AllAnswers,
        part1SingleAnswer,
        part1MultipleAnswer,
        part1NoteCompletionAnswer,
        part1SummaryCompletionOneWordAnswers,
        part1DragAndDropAnswers,
        part1TaskIdentifyingItemAnswers,
        part1SenetenceComplete,
        part1DraggedCurrentSentence,
        part1MatchingSentenceEndAnswers,
        part1TableCompletionAnswer,
        part1MatchingFeatureAnswers,
        part1MatchingHeadingAnswer
      );
    }
    if (startTestStoreReading.testType === "part2") {
      combineAnswers(
        setPart2AllAnswers,
        part2SingleAnswer,
        part2MultipleAnswer,
        part2NoteCompletionAnswer,
        part2SummaryCompletionOneWordAnswers,
        part2DragAndDropAnswers,
        part2TaskIdentifyingItemAnswers,
        part2SenetenceComplete,
        part2DraggedCurrentSentence,
        part2MatchingSentenceEndAnswers,
        part2TableCompletionAnswer,
        part2MatchingFeatureAnswers,
        part2MatchingHeadingAnswer
      );
    }
    if (startTestStoreReading.testType === "part3") {
      combineAnswers(
        setPart3AllAnswers,
        part3SingleAnswer,
        part3MultipleAnswer,
        part3NoteCompletionAnswer,
        part3SummaryCompletionOneWordAnswers,
        part3DragAndDropAnswers,
        part3TaskIdentifyingItemAnswers,
        part3SenetenceComplete,
        part3DraggedCurrentSentence,
        part3MatchingSentenceEndAnswers,
        part3TableCompletionAnswer,
        part3MatchingFeatureAnswers,
        part3MatchingHeadingAnswer
      );
    }

    let res = await startTestStoreReading.submitAnswerReading();
    if (res && res.status === 200) {
      if (startTestStoreReading.testType === "full") {
        const answerSheet = [
          ...res.data.answerSheet.partOneAnswerSheet, 
          ...res.data.answerSheet.partTwoAnswerSheet, 
          ...res.data.answerSheet.partThreeAnswerSheet, 
        ];

        if (startTestStoreReading.partType === "part3") {
          let part1Marks = caluculateScore(
            startTestStoreReading.part1Answers,
            res.data.answerSheet.partOneAnswerSheet //
          );
          let part2Marks = caluculateScore(
            startTestStoreReading.part2Answers,
            res.data.answerSheet.partTwoAnswerSheet //
          );
          let part3Marks = caluculateScore(
            startTestStoreReading.part3Answers,
            res.data.answerSheet.partThreeAnswerSheet //
          );
          let payload: any = {
            testId: startTestStoreReading.testId,
            score: {
              partType: startTestStoreReading.testType,
              part1UserScore: part1Marks,
              part2UserScore: part2Marks,
              part3UserScore: part3Marks,
              overAllUserScore: part1Marks + part2Marks + part3Marks,
              part1TotalScore: res.data.answerSheet.partOneAnswerSheet.length, //
              part2TotalScore: res.data.answerSheet.partTwoAnswerSheet.length, //
              part3TotalScore: res.data.answerSheet.partThreeAnswerSheet.length, //
              overallTotalScore: answerSheet.length,
            },
            //overallScore:overall
            test_type: startTestStoreReading.testType,
          };
          let overall_scorePart1 = caluculateBandScorePartOne(
            part1Marks,
            "part1"
          );
          let band_number_part1 =
            overall_scorePart1 && Math.round(overall_scorePart1);
          let band_number_stringPart1 = "band_" + band_number_part1;
          let detailedReportPart1 =
            band_number_stringPart1 &&
            BandDetails[`${band_number_stringPart1}`];

          let overall_score_part2 = caluculateBandScorePartOne(
            part2Marks,
            "part2"
          );
          let band_number_part2 =
            overall_score_part2 && Math.round(overall_score_part2);
          let band_number_string_part2 = "band_" + band_number_part2;
          let detailedReportPart2 =
            band_number_string_part2 &&
            BandDetails[`${band_number_string_part2}`];

          let overall_score_part3 = caluculateBandScorePartOne(
            part3Marks,
            "part3"
          );
          let band_number_part3 =
            overall_score_part3 && Math.round(overall_score_part3);
          let band_number_string_part3 = "band_" + band_number_part3;
          let detailedReportPart3 =
            band_number_string_part3 &&
            BandDetails[`${band_number_string_part3}`];
            
            const overall_score_full =
            overall_scorePart1 ?? overall_score_part2 ?? overall_score_part3 !== undefined
              ? ((overall_scorePart1 ?? 0) + (overall_score_part2 ?? 0) + (overall_score_part3 ?? 0)) / 3
              : 0;
          
          if (!payload["data"]) {
            payload["data"] = {};
          }
          if (!payload["aiFeedback"]) {
            payload["aiFeedback"] = {};
          }
          payload["data"]["date"] = formattedDateTime;
          payload["data"]["overallBand"] = overall_score_full;
          payload["data"]["part1"] = detailedReportPart1;
          payload["data"]["part1"]["overall_score"] = overall_scorePart1;
          payload["data"]["part1"]["date"] = formattedDateTime;
          payload["data"]["part2"] = detailedReportPart2;
          payload["data"]["part2"]["overall_score"] = overall_score_part2;
          payload["data"]["part2"]["date"] = formattedDateTime;
          payload["data"]["part3"] = detailedReportPart3;
          payload["data"]["part3"]["overall_score"] = overall_score_part3;
          payload["data"]["part3"]["date"] = formattedDateTime;

          setPartOneScore(part1Marks);
          setPartTwoScore(part2Marks);
          setPartThreeScore(part3Marks);
          let part1AiPayload = aiFeedbackPayload(
            startTestStoreReading.part1Answers,
            res.data.answerSheet.partOneAnswerSheet,
            "reading"
          );
          let part2AiPayload = aiFeedbackPayload(
            startTestStoreReading.part2Answers,
            res.data.answerSheet.partTwoAnswerSheet,
            "reading"
          );
          let part3AiPayload = aiFeedbackPayload(
            startTestStoreReading.part3Answers,
            res.data.answerSheet.partThreeAnswerSheet,
            "reading"
          );
          payload["aiFeedback"]["part1"] = {
            passage: part1Questions.paragraph,
            questions: part1AiPayload,
          };
          payload["aiFeedback"]["part2"] = {
            passage: part2Questions.paragraph,
            questions: part2AiPayload,
          };
          payload["aiFeedback"]["part3"] = {
            passage: part3Questions.paragraph,
            questions: part3AiPayload,
          };
          startTestStoreReading.completeTest(payload);
        }
      } else {
        let partTypeAnswers;
        if (startTestStoreReading.partType === "part1") {
          partTypeAnswers = startTestStoreReading.part1Answers;
          let part1Marks = caluculateScore(
            partTypeAnswers,
            res.data.answerSheet.partOneAnswerSheet //
          );
          let payload: any = {
            testId: startTestStoreReading.testId,
            score: {
              partType: "part1",
              part1UserScore: part1Marks,
              part1TotalScore: res.data.answerSheet.partOneAnswerSheet.length, //
            },
            test_type: "part1",
          };
          let overall_score = caluculateBandScorePartOne(part1Marks, "part1");
          payload = updatePayloadWithScoreDetails(payload,overall_score,formattedDateTime,BandDetails,"part1");


          setPartOneScore(part1Marks);
          let part1AiPayload = aiFeedbackPayload(
            startTestStoreReading.part1Answers,
            res.data.answerSheet.partOneAnswerSheet,
            "reading"
          );
          payload["aiFeedback"]["part1"] = {
            passage: part1Questions.paragraph,
            questions: part1AiPayload,
          };

          startTestStoreReading.completeTest(payload);
        } else if (startTestStoreReading.partType === "part2") {
          partTypeAnswers = startTestStoreReading.part2Answers;
          let part2Marks = caluculateScore(
            partTypeAnswers,
            res.data.answerSheet.partTwoAnswerSheet //
          );
          let payload: any = {
            testId: startTestStoreReading.testId,
            score: {
              partType: "part2",
              part2UserScore: part2Marks,
              part2TotalScore: res.data.answerSheet.partTwoAnswerSheet.length,
            },
            test_type: "part2", //
          };
          let overall_score = caluculateBandScorePartOne(part2Marks, "part2");

          payload = updatePayloadWithScoreDetails(payload,overall_score,formattedDateTime,BandDetails,"part2");

          setPartTwoScore(part2Marks);

          let part2AiPayload = aiFeedbackPayload(
            startTestStoreReading.part2Answers,
            res.data.answerSheet.partTwoAnswerSheet,
            "reading"
          );
          payload["aiFeedback"]["part2"] = {
            passage: part2Questions.paragraph,
            questions: part2AiPayload,
          };
          startTestStoreReading.completeTest(payload);
        } else {
          partTypeAnswers = startTestStoreReading.part3Answers;
          let part3Marks = caluculateScore(
            partTypeAnswers,
            res.data.answerSheet.partThreeAnswerSheet //
          );
          let payload: any = {
            testId: startTestStoreReading.testId,
            score: {
              partType: "part3",
              part3UserScore: part3Marks,
              part3TotalScore: res.data.answerSheet.partThreeAnswerSheet.length, //
            },
            test_type: "part3",
          };
          let overall_score = caluculateBandScorePartOne(part3Marks, "part3");
          let band_number = overall_score && Math.round(overall_score);
          let band_number_string = "band_" + band_number;
          let detailedReport =
            band_number_string && BandDetails[`${band_number_string}`];
          if (!payload["data"]) {
            payload["data"] = {};
          }
          if (!payload["aiFeedback"]) {
            payload["aiFeedback"] = {};
          }
          payload["data"]["part3"] = detailedReport;
          payload["data"]["overallBand"] = overall_score;
          payload["data"]["part3"]["overall_score"] = overall_score;
          payload["data"]["date"] = formattedDateTime;
          let part3AiPayload = aiFeedbackPayload(
            startTestStoreReading.part3Answers,
            res.data.answerSheet.partThreeAnswerSheet,
            "reading"
          );
          payload["aiFeedback"]["part3"] = {
            passage: part3Questions.paragraph,
            questions: part3AiPayload,
          };

          setPartThreeScore(part3Marks);
          startTestStoreReading.completeTest(payload);
        }
      }
    }
  };

  const handleClose = (navigation : boolean) => {
    //navigate to the reading home page
    navigation && navigate("/reading");
    startTestStoreReading.setCompleteTestFailed(false);
    startTestStoreReading.setIsTestEnded(null);
    //reset the step management to initial step
    resultsStoreReading.setStepManagement("confirm_your_details", "", "");
    // for full test st the timer to maximum time like 1hour
    setTimer(3600);
    //reset the testId,test Type
  };
  useEffect(() => {
    if (startTestStoreReading.completeTestFailed) {
      resultsStoreReading.setStepManagement(
        "part_test_completed",
        "Part 1",
        ""
      );
    }
  }, []);
  useEffect(() => {
    window.addEventListener("popstate", () => {
      resultsStoreReading.setStepManagement("confirm_your_details", "", "");
      setTimer(3600);
      navigate("/reading");
      localStorage.removeItem("reload");
    });
  });

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      localStorage.setItem("reload", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    if (localStorage.getItem("reload")) {
      startTestStoreReading.setLoading(true);
      localStorage.removeItem("reload");
      resultsStoreReading.setStepManagement("confirm_your_details", "", "");
      setTimer(3600);
      navigate("/reading");
    } else {
      localStorage.removeItem("reload");
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    if (testType) {
      switch (testType) {
        case "full":
          resultsStoreReading.setStepManagement("confirm_your_details", "", "");
          break;
        case "part1":
          resultsStoreReading.setStepManagement("Part 1", "", "");
          break;
        case "part2":
          resultsStoreReading.setStepManagement("Part 2", "", "");
          break;
        case "part3":
          resultsStoreReading.setStepManagement("Part 3", "", "");
          break;
        default:
      }
    }
  }, []);
  const renderContent = () => {
    if (startTestStoreReading.isLoading) {
      return (
        <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
          <Loader />
        </div>
      );
    } else if (startTestStoreReading.tryAgainButton) {
      return (
        <div>
          <Shellwrapper
            component={ReadingErrorPage}
            errorMessage={readingErrorMessage}
            onBackToHome={() => {
              navigate("/reading");
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <header className="fixed top-0 left-0 w-full flex justify-between border-b border-b-[#E3E5E9] bg-white text-white p-4">
            <UserProfile
              currentStepName={currentStepName}
              userName={userStore.studentDetails.studentName}
              profileImage={profileImage}
            />
            {(currentStepName === "Part 1" ||
              currentStepName === "Part 2" ||
              currentStepName === "Part 3") && (
              <div className="flex h-[25px] md:h-full my-auto">
                <img src={clockIcon} alt="timer" />
                <div className="items-center">
                  <WritingTimer
                    store={startTestStoreReading}
                    resultStore={resultsStoreReading}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-row h-[25px] md:h-full">
              {/* <img src={helpIcon} alt="help" className="pr-6" /> */}
              <button onClick={() => handleClose(true)}>
              <img
                src={closeIcon}
                alt="close"
              />
              </button>
            </div>
          </header>
          {renderStepContent()}
        </div>
      );
    }
  };
  
  const renderStepContent = () => {
    switch (currentStepName) {
      case "confirm_your_details":
        return (
          <div className="flex flex-col min-h-screen items-center justify-center pt-16 w-full">
            <ConfirmYourDetails nextStep={nextStep} />
          </div>
        );
      case "writing_instructions":
        return (
          <div className="min-h-screen pt-24">
            <ReadingInstructions nextStep={nextStep} />
          </div>
        );
      case "Part 1":
        return (
          <div className="min-h-screen py-16">
            <ReadingPart1 nextStep={nextStep} />
          </div>
        );
      case "part_test_completed":
        return (
          <div className="min-h-screen pt-10 flex items-center justify-center">
            <PartTestCompletedCard
              nextStep={nextStep}
              currentPartName={currentPartName}
              nextPartName={nextPartName}
              startTeststore={startTestStoreReading}
              resultStore={resultsStoreReading}
              submitTest={submitTest}
              submitAnswer={submitAnswer}
              handleClose={handleClose}
              module="Reading"
            />
          </div>
        );
      case "Part 2":
        return (
          <div className="min-h-screen py-16">
            <ReadingPart2 nextStep={nextStep} />
          </div>
        );
      case "Part 3":
        return (
          <div className="min-h-screen py-16">
            <ReadingPart3 nextStep={nextStep} />
          </div>
        );
      default:
        return null;
    }
  };
  
  

  return (
    <>
    {renderContent()}
    </>
  );
});

export default FullTestReading;