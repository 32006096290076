import React, { useState } from 'react'
import IELTSPaymentsUtility from './IELTSPaymentsUtility'
import CambriPaymnetsUtilities from './CambriPaymnetsUtilities'

const Payments = () => {
    const [projectName,setProjectName] = useState('')

const projectHandler = (e:any) => {
setProjectName(e.target.value)
}

const selectedProject = () => {
switch  (projectName){
    case 'ielts': return  <IELTSPaymentsUtility />
    case 'cambri': return <CambriPaymnetsUtilities />
}

}

  return (
   <div >
    <select className='border  outline-none rounded-md px-2 py-1' onChange={projectHandler}>
        <option value=''>Choose Project</option>
        <option value='ielts'>IELTS</option>
        <option value='cambri'>Cambri</option>
    </select>
    <div className='flex justify-center mt-10'>
    {projectName ? selectedProject() :<div className='font-semibold'> Please Select a Project</div>}
    </div>
    
   </div>

  )
}

export default Payments