import { useStore } from "../../Hooks/use-store";
import { observer } from "mobx-react-lite";
import Progress from "./Progressbar/progress";
import { useEffect, useState } from "react";
import Timer from "./recordTimer";

const TaskCard = observer((props: any) => {
  const [timeProgress, setTimeProgress] = useState(0);
  const { startTestStore } = useStore();

  const readyHandler = () => {
    props?.nextStep();
    startTestStore.setIsTaskCard(false);
    startTestStore.setCounter(true);
  };

  useEffect(() => {
    if (startTestStore.progressBarWidth <= 0) {
      readyHandler();
    }
  }, [startTestStore.progressBarWidth]);


  return (
    <>
    <div className="flex">
      <div
        style={{
          background: "linear-gradient(216deg, #FFF9FA 1.21%, #FFF 97.42%)",
          border: "1px solid rgba(227, 24, 55, 0.30)",
        }}
        className="flex flex-col rounded-md border border-solid border-[rgba(227, 24, 55, 0.30)] bg-gradient-to-br from-[#FFF9FA] via-transparent to-[#FFF] p-5 pb-10 h-auto mx-auto lg:w-1/2 md:w-1/2 w-[90%]"
      >
        <h1 className="text-2xl font-medium mb-6">Candidate Task Card</h1>

        <div className="text-[#292D35] mb-2 text-24">
          {startTestStore.partTwoCardDetails.topic}
        </div>
        <div>
          {startTestStore.partTwoCardDetails.questions.length > 0 &&
            startTestStore.partTwoCardDetails.questions.map(
              (item: any) => {
                return (
                  
                    <ul key={item}>{item}</ul>
                  
                );
              }
            )}
        </div>
      </div>
      <div className="pr-2">
      <Timer time={timeProgress} setTime={setTimeProgress}/>
      </div> 
    </div>
    
      
      <div className=" my-8">
        <Progress maxTime="60000"/>
      </div>
      <div className="text-[#737E93] text-base font-normal leading-5 flex justify-center">
        Take this 1 minute to prepare
      </div>
      <div className="flex justify-center mt-20">
        <button
          className="mx-auto bg-white rounded-full px-4 py-4 text-[#E31837] text-xl font-semibold border border-[#E31837] lg:w-1/6 md:w-1/6 w-3/4"
          onClick={readyHandler}
        >
          READY
        </button>
      </div>
    </>
  );
});

export default TaskCard;
