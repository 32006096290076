import React from 'react';

interface UserProfileProps {
  currentStepName: string;
  profileImage: string;
  userName: string;
}

const UserProfile: React.FC<UserProfileProps> = ({ currentStepName, profileImage, userName }) => {
  return (
    <>
      {currentStepName !== "confirm_your_details" ? (
        <div className="flex mx-4">
          <img src={profileImage} alt="profile" className="mr-2" />
          <div className="text-[#8F98A9] md:text-xl text-sm flex items-center">
            {userName}
          </div>
        </div>
      ) : (
        <div className="text-[#292D35] md:text-xl text-sm">
          Full Test
        </div>
      )}
    </>
  );
};

export default UserProfile;
