import { useStore } from "../../Hooks/use-store";
import { observer } from "mobx-react-lite";
import { generateQuestionsArray } from "../../utils";
import {displayQuestions}  from '../../utilities/displayQuestions'

const questionsArray = (partQns: any) => {
  let allListOfQuestions =
    partQns.questions &&
    partQns.questions.length > 0 &&
    partQns.questions.map((item: any) => {
      let total = [];
      if (item["list-of-questions"]) {
        let arr: any = [];
        let data = item["list-of-questions"];
        data.forEach((item: any) => {
          arr.push(item["qId"]);
        });
        total.push(arr.flat(Infinity));
      } else {
        total.push(generateQuestionsArray(item["question-range"]));
      }
      return total;
    });
  let result =
    allListOfQuestions &&
    allListOfQuestions.length > 0 &&
    allListOfQuestions.flat(Infinity);
  return result;
};

const combineAnswers = (...rest: any) => {
  return rest.flat(Infinity);
};

const isQuestionAnswered = (partAllAnswers: any, number: any) => {
  return partAllAnswers.some((item: any) =>
    !item["answer"] || item["answer"].length < 2
      ? false
      : item["qId"] === number.toString()
  );
};


const Tab = ({
  label,
  onClick,
  isActive,
  part1Questions,
  part2Questions,
  part3Questions,
  startTestStoreReading,
}: any) => {
  const {
    part1SingleAnswer,
    part1MultipleAnswer,
    part1NoteCompletionAnswer,
    part1SummaryCompletionOneWordAnswers,
    part1DragAndDropAnswers,
    part1TaskIdentifyingItemAnswers,
    part1SenetenceComplete,
    part1DraggedCurrentSentence,
    part1MatchingSentenceEndAnswers,
    part1TableCompletionAnswer,
    part1MatchingFeatureAnswers,
    part1MatchingHeadingAnswer,
    part2SingleAnswer,
    part2MultipleAnswer,
    part2NoteCompletionAnswer,
    part2SummaryCompletionOneWordAnswers,
    part2DragAndDropAnswers,
    part2TaskIdentifyingItemAnswers,
    part2SenetenceComplete,
    part2DraggedCurrentSentence,
    part2MatchingSentenceEndAnswers,
    part2TableCompletionAnswer,
    part2MatchingFeatureAnswers,
    part2MatchingHeadingAnswer,
    part3SingleAnswer,
    part3MultipleAnswer,
    part3NoteCompletionAnswer,
    part3SummaryCompletionOneWordAnswers,
    part3DragAndDropAnswers,
    part3TaskIdentifyingItemAnswers,
    part3SenetenceComplete,
    part3DraggedCurrentSentence,
    part3MatchingSentenceEndAnswers,
    part3TableCompletionAnswer,
    part3MatchingFeatureAnswers,
    part3MatchingHeadingAnswer,
  } = startTestStoreReading;
  let partOneAllAnswers = combineAnswers(
    part1SingleAnswer,
    part1MultipleAnswer,
    part1NoteCompletionAnswer,
    part1SummaryCompletionOneWordAnswers,
    part1DragAndDropAnswers,
    part1TaskIdentifyingItemAnswers,
    part1SenetenceComplete,
    part1DraggedCurrentSentence,
    part1MatchingSentenceEndAnswers,
    part1TableCompletionAnswer,
    part1MatchingFeatureAnswers,
    part1MatchingHeadingAnswer
  );
  let partTwoAllAnswers = combineAnswers(
    part2SingleAnswer,
    part2MultipleAnswer,
    part2NoteCompletionAnswer,
    part2SummaryCompletionOneWordAnswers,
    part2DragAndDropAnswers,
    part2TaskIdentifyingItemAnswers,
    part2SenetenceComplete,
    part2DraggedCurrentSentence,
    part2MatchingSentenceEndAnswers,
    part2TableCompletionAnswer,
    part2MatchingFeatureAnswers,
    part2MatchingHeadingAnswer
  );
  let partThreeAllAnswers = combineAnswers(
    part3SingleAnswer,
    part3MultipleAnswer,
    part3NoteCompletionAnswer,
    part3SummaryCompletionOneWordAnswers,
    part3DragAndDropAnswers,
    part3TaskIdentifyingItemAnswers,
    part3SenetenceComplete,
    part3DraggedCurrentSentence,
    part3MatchingSentenceEndAnswers,
    part3TableCompletionAnswer,
    part3MatchingFeatureAnswers,
    part3MatchingHeadingAnswer
  );
  return (
    <button
      className={`cursor-pointer flex px-4 pt-4 border-t-[5px] rounded-sm font-extrabold ${
        isActive ? "border-red-600 text-[#E31837] " : "border-transparent"
      }`}
      onClick={onClick}
    >
      <div className="pt-1">{label}</div>
      {label === "Part 1" &&
        isActive &&
        displayQuestions(part1Questions, partOneAllAnswers,isQuestionAnswered)}
      {label === "Part 2" &&
        isActive &&
        displayQuestions(part2Questions, partTwoAllAnswers,isQuestionAnswered)}
      {label === "Part 3" &&
        isActive &&
        displayQuestions(part3Questions, partThreeAllAnswers,isQuestionAnswered)}
    </button>
  );
};

const combineUserAnswers = (setPartAllAnswers: any, ...rest: any) => {
  setPartAllAnswers(rest.flat(Infinity));
};

const Tabs = ({ tabs }: any) => {
  const { resultsStoreReading, startTestStoreReading } = useStore();
  const {
    part1Questions,
    part2Questions,
    part3Questions,
    setPart1AllAnswers,
    setPart2AllAnswers,
    setPart3AllAnswers,
    part1SingleAnswer,
    part1MultipleAnswer,
    part1NoteCompletionAnswer,
    part1SummaryCompletionOneWordAnswers,
    part1DragAndDropAnswers,
    part1TaskIdentifyingItemAnswers,
    part1SenetenceComplete,
    part1DraggedCurrentSentence,
    part1MatchingSentenceEndAnswers,
    part1TableCompletionAnswer,
    part1MatchingFeatureAnswers,
    part1MatchingHeadingAnswer,
    part2SingleAnswer,
    part2MultipleAnswer,
    part2NoteCompletionAnswer,
    part2SummaryCompletionOneWordAnswers,
    part2DragAndDropAnswers,
    part2TaskIdentifyingItemAnswers,
    part2SenetenceComplete,
    part2DraggedCurrentSentence,
    part2MatchingSentenceEndAnswers,
    part2TableCompletionAnswer,
    part2MatchingFeatureAnswers,
    part2MatchingHeadingAnswer,
    part3SingleAnswer,
    part3MultipleAnswer,
    part3NoteCompletionAnswer,
    part3SummaryCompletionOneWordAnswers,
    part3DragAndDropAnswers,
    part3TaskIdentifyingItemAnswers,
    part3SenetenceComplete,
    part3DraggedCurrentSentence,
    part3MatchingSentenceEndAnswers,
    part3TableCompletionAnswer,
    part3MatchingFeatureAnswers,
    part3MatchingHeadingAnswer,
  } = startTestStoreReading;
  let partOneQuestions = questionsArray(part1Questions);
  let partTwoQuestions = questionsArray(part2Questions);
  let partThreeQuestions = questionsArray(part3Questions);
  const handleTabClick = (index: any) => {
    resultsStoreReading.setActiveTab(index);
    combineUserAnswers(
      setPart1AllAnswers,
      part1SingleAnswer,
      part1MultipleAnswer,
      part1NoteCompletionAnswer,
      part1SummaryCompletionOneWordAnswers,
      part1DragAndDropAnswers,
      part1TaskIdentifyingItemAnswers,
      part1SenetenceComplete,
      part1DraggedCurrentSentence,
      part1MatchingSentenceEndAnswers,
      part1TableCompletionAnswer,
      part1MatchingFeatureAnswers,
      part1MatchingHeadingAnswer
    );
    combineUserAnswers(
      setPart2AllAnswers,
      part2SingleAnswer,
      part2MultipleAnswer,
      part2NoteCompletionAnswer,
      part2SummaryCompletionOneWordAnswers,
      part2DragAndDropAnswers,
      part2TaskIdentifyingItemAnswers,
      part2SenetenceComplete,
      part2DraggedCurrentSentence,
      part2MatchingSentenceEndAnswers,
      part2TableCompletionAnswer,
      part2MatchingFeatureAnswers,
      part2MatchingHeadingAnswer
    );
    combineUserAnswers(
      setPart3AllAnswers,
      part3SingleAnswer,
      part3MultipleAnswer,
      part3NoteCompletionAnswer,
      part3SummaryCompletionOneWordAnswers,
      part3DragAndDropAnswers,
      part3TaskIdentifyingItemAnswers,
      part3SenetenceComplete,
      part3DraggedCurrentSentence,
      part3MatchingSentenceEndAnswers,
      part3TableCompletionAnswer,
      part3MatchingFeatureAnswers,
      part3MatchingHeadingAnswer
    );
    if( index === 0){
      return resultsStoreReading.setStepManagement("Part 1", "", "")
    }else if(index === 1){
      return resultsStoreReading.setStepManagement("Part 2", "Part 2", "")
    }else{
      return resultsStoreReading.setStepManagement("Part 3", "Part 3", "")
    }
  }

  const keyTab = (index: any) => {
    switch (index) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 0;
    }
  };

  const renderTab = (): JSX.Element | null => {
    const testType: any= startTestStoreReading.testType;

    switch (testType) {
      case "part1":
        return (
          <Tab
            key={0}
            label="Part 1"
            onClick={() => handleTabClick(0)}
            isActive="true"
            part1Questions={partOneQuestions}
            part2Questions={""}
            part3Questions={""}
            startTestStoreReading={startTestStoreReading}
          />
        );
        
      case "part2":
        return (
          <Tab
            key={1}
            label="Part 2"
            onClick={() => handleTabClick(1)}
            isActive="true"
            part1Questions={""}
            part2Questions={partTwoQuestions}
            part3Questions={""}
            startTestStoreReading={startTestStoreReading}
          />
        );
      case "part3":
        return (
          <Tab
            key={2}
            label="Part 3"
            onClick={() => handleTabClick(2)}
            isActive="true"
            part1Questions={""}
            part2Questions={""}
            part3Questions={partThreeQuestions}
            startTestStoreReading={startTestStoreReading}
          />
        );
     case null:
      default:
        return tabs.map((tab: any, index: any) => (
          <Tab
            key={keyTab(index)}
            label={tab}
            onClick={() => handleTabClick(index)}
            isActive={index === resultsStoreReading.activeTab}
            part1Questions={partOneQuestions}
            part2Questions={partTwoQuestions}
            part3Questions={partThreeQuestions}
            startTestStoreReading={startTestStoreReading}
          />
        ));
    }
  };

  return (
    <div className="flex  rounded-t-[8px] pl-4 w-1/2">
       {renderTab()}
    </div>
  );
};

const PartTestLayoutReading = observer(
  ({ children, buttonTitle, partType, nextStep, title, description }: any) => {
    const tabs = ["Part 1", "Part 2", "Part 3"];
    return (
      <div>
        <div className="border h-full border-[#E3E5E9] p-4 m-6 bg-[#FFF] rounded-lg">
          <p className="font-extrabold text-base md:text-xl mb-2">{title}</p>
          <h5>{description}</h5>
        </div>
        {children}
        <div className="fixed bg-white flex justify-between  border-t border-t-[#E3E5E9]  bottom-0 w-full">
          <Tabs tabs={tabs} />

          <div>
            <button
              //disabled={disabled}
              onClick={nextStep}
              className={` ${
                false
                  ? "bg-[#ABB2BE] text-white"
                  : "text-[#E31837] border-[#E31837] "
              } border my-4 mr-4 px-4 py-2 rounded-full `}
            >
              {buttonTitle}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default PartTestLayoutReading;
