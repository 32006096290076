import { observer } from "mobx-react-lite";
import React from "react";

const MultiAnswerQuestions = ({ item, handleCheckBoxChange, multipleAnswers }: any) => {
  return (
    <>
      <div className="text-[#3C424C] text-sm">
        {" "}
        Choose the multiple correct answers
      </div>
      {item["list-of-questions"].length > 0 &&
        item["list-of-questions"].map((question: any, index: any) => {
          return (
            <div key={question.qId} className="p-4 border border-[#E3E5E9] rounded-xl m-2 flex flex-col">
              <div className="text-[#292D35] text-base">{`${question.qId}. ${question.qName}`}</div>
              <div>
                {question.options.map((option: any, index: any) => {
                  const filteredItem = multipleAnswers?.find(
                    (item: any, index: any) => item.qId === question.qId
                  );
                  const isDisabled =
                    filteredItem &&
                    !filteredItem.answer?.includes(option) &&
                    filteredItem.answer.length >= 2;

                  const isChecked = multipleAnswers?.some(
                    (item: any) =>
                      item.qId === question.qId && item.answer.includes(option)
                  );
                  
                  return (
                    <div key={question.qId} className="text-[#737E93] ">
                      <input
                        type="checkbox"
                        className="w-4 h-4 mr-1 "
                        name={question.qId}
                        value={option}
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={(e) =>
                          handleCheckBoxChange(
                            e.target.name,
                            e.target.value,
                            e.target.checked
                          )
                        }
                      />{" "}
                      {option}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default observer(MultiAnswerQuestions);
