import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../Hooks/use-store";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import DetailedResults from "../components/Results/detailedReport/detailedResults";
import Frame from "../icons/Frame.svg";
import AiFeedback from "../components/Results/aiFeedback/aiFeedback";
import BoxTabs from "../components/common/boxTabs";

interface TabPanel {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanel) => {
  const { children, index, value } = props;
  return (
    <div>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const SpeakingResults = () => {
  const [value, setValue] = useState(0);

  const navigate = useNavigate();
  const { resultsStore } = useStore();
  const backHandler = () => {
    resultsStore.isFreeTestCompletedClicked
      ? resultsStore.viewResultsPage(false)
      : resultsStore.viewResultsPage(true);
    navigate("/speaking");
  };

  const handleTabs = (event: React.SyntheticEvent, newValue: number | null) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <div className=" ml-12 mb-6 ">
      <button
        onClick={backHandler}
        className="text-[#3C424C] font-normal text-lg cursor-pointer flex flex-row"
      >
        <img src={Frame} alt="arrow" />{" "}
        <span className="ml-4 font-semibold">Test Results</span>
      </button>
      <BoxTabs
        value={value}
        handleTabs={handleTabs}
        tabLabels={[
          { name: "Result", id: 1 },
          { name: "AI Feedback", id: 2 },
        ]}
      >
        <CustomTabPanel index={0} value={value}>
          {resultsStore.isResultsLoading ? (
            <>
              <div className="flex gap-3  w-full">
                <div className="sm:w-1/3  h-[15rem] rounded my-8 shimmer "></div>
                <div className="sm:w-1/2 h-[15rem] rounded my-8 shimmer "></div>
              </div>
              <div className="flex flex-col sm:flex-row  w-full gap-5">
                <div className="sm:w-1/4  h-[6.5rem] rounded my-8 shimmer "></div>
                <div className="sm:w-1/4 h-[6.5rem] rounded my-8 shimmer mx-1"></div>
                <div className="sm:w-1/4  h-[6.5rem] rounded my-8 shimmer "></div>
              </div>
            </>
          ) : (
            <DetailedResults store={resultsStore} />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <AiFeedback />
        </CustomTabPanel>
      </BoxTabs>
    </div>
  );
};

export default observer(SpeakingResults);
