import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../Hooks/use-store";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import DetailedResultsReading from "../components/Results/detailedReport/detailedResultsReading";
import Frame from "../icons/Frame.svg";
import AiFeedbackQuestionDescription from "../components/common/aiFeedbackQuestionDescription";
import LockedContentOverlay from "../components/common/lockedContentoverlay";
import BoxTabs from "../components/common/boxTabs";
import RenderTable from "../components/Results/aiFeedback/tableQuestionResults";
import {questionIdClassName,getUserAnswerTextClassName, getAnswerRender, getExactCorrectClassName, getTheAnswerStatement} from '../utils'

interface TabPanel {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanel) => {
  const { children, index, value } = props;
  return (
    <div>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography className="font-avenir">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ReadingResults = () => {
  const [value, setValue] = useState(0);
  const [aiFeedback, setAIFeedback] = useState({
    partType: "",
    paragraphTitle: "",
    paragraphContent: "",
    questionId: "",
    questionName: "",
    questionType: "",
    userAnswer: "",
    exactAnswer: "",
    isCorrect: false,
    isSelected: false,
    isAttempted: true,
    table: [],
  });

  const navigate = useNavigate();
  const { resultsStoreReading, userStore, dashboardStore } = useStore();
  const { aiFeedbackData: data } = resultsStoreReading;
  const backHandler = () => {
    resultsStoreReading.isFreeTestCompletedClicked
      ? resultsStoreReading.viewResultsPage(false)
      : resultsStoreReading.viewResultsPage(true);
    navigate("/reading");
  };

  const handleTabs = (event: React.SyntheticEvent, newValue: number | null) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  const handleAIFeedback = (item: any, part: any) => {
    let paraTitle = data[part as keyof typeof data]["passage"]["title"];
    let paraContent = data[part as keyof typeof data]["passage"]["content"];
    if (
      item.questionType === "Multiple Choice (more than one answer)" &&
      Array.isArray(item.exactAnswer)
    ) {
      item.exactAnswer = item.exactAnswer.join(", ");
    }
    setAIFeedback((prevState) => ({
      ...prevState,
      partType: part,
      // paragraph:data["part1"],
      questionId: item.qId,
      paragraphTitle: paraTitle,
      paragraphContent: paraContent,
      questionName: item.qName,
      questionType: item.questionType,
      userAnswer: item.userAnswer,
      exactAnswer: item.exactAnswer,
      isCorrect: item.isCorrect,
      isSelected: true,
      isAttempted: item.isAttempted,
      table: item.table,
    }));
  };

  const renderQuestionsName = (
    questionName: any,
    userAnswer: any,
    isCorrect: any,
    dataQuestions: any
  ): React.ReactNode => {
    let isqIdInvolved = (segment: any) =>
      segment.startsWith("{qId:") && segment.endsWith("}");
    if (questionName.includes("{")) {
      let splittedArray = questionName.split(/(\{qId:\d+\})/g);
      return splittedArray?.map((seg: any) => {
        if (isqIdInvolved(seg)) {
          const qId = seg.match(/\d+/)[0];
          const item = dataQuestions.find((ite: any) => ite.qId === qId);
          return item ? (
            <span
              className={`${
                item.isCorrect
                  ? "text-[#59A51A] font-bold"
                  : "text-[#DB3555] font-bold"
              }`}
            >
              {item.userAnswer ? (
                <span>{" " + item.userAnswer + " "}</span>
              ) : (
                "________________"
              )}
            </span>
          ) : (
            item
          );
        } else {
          return seg;
        }
      });
    } else {
      return <span className="font-avenir">{questionName}</span>;
    }
  };

  const renderParagraph = (paragraph: any) => {
    const regex = /{qId: (\d+)}./g;
    const hasQId = regex.test(paragraph);
    if (hasQId) {
      return paragraph.split(regex).map((part: any, index: any) => {
        if (index % 2 === 0) {
          return part;
        } else {
          const qId = part;

          return (
            <div
              key={qId}
              className={
                "rounded-lg p-1 my-1 text-center text-[#62656a]  border border-[#b3b8c2]"
              }
            >
              {<p>Question:{qId}</p>}
            </div>
          );
        }
      });
    } else {
      return <div className="whitespace-pre-line">{paragraph}</div>;
    }
  };

  const renderMatchingHeadingQname = (
    questionName: any,
    userAnswer: any,
    isCorrect: any
  ) => {
    const parts = questionName.split(/\{qId: \d+\}./);
    return (
      <div>
        {
          <div
            className={`${
              isCorrect
                ? "text-[#59A51A] border border-[#59A51A]"
                : "text-[#DB3555] border border-[#DB3555]"
            }  rounded-md px-3 py-2 my-2 w-3/4 font-avenir`}
          >
            {userAnswer}
          </div>
        }
        {parts[1]}
      </div>
    );
  };

  const startHandler = () => {
    dashboardStore.setProfilePageTabIndex(1);
    navigate("/profile");
  };
 

  return (
    <div className="w-[95%] mx-auto ">
      <button
        onClick={backHandler}
        className="text-[#3C424C] font-normal text-lg cursor-pointer flex flex-row"
      >
        <img src={Frame} alt="arrow" />{" "}
        <span className="ml-4 font-semibold font-avenir">
          Test Results Reading
        </span>
      </button>
      <BoxTabs
        value={value}
        handleTabs={handleTabs}
        tabLabels={[
          { name: "Result", id: 1 },
          { name: "AI Feedback", id: 2 },
        ]}
      >
        <CustomTabPanel index={0} value={value}>
          {resultsStoreReading.isResultsLoading ? (
            <div className="bg-[#E3E5E9]-500 border-2 rounded-[8px] w-[90%]   h-[20rem] my-8 shimmer"></div>
          ) : (
            <DetailedResultsReading />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="relative">
            <div className="flex gap-4">
              <div className="border border-gray-300 rounded-md bg-white w-1/3 min-h-[70vh]">
                <h1 className="bg-[#F8F9FA] p-4 font-avenir border rounded-md rounded-b-none border-b-gray-300 font-medium text-[#292D35] text-lg">
                  Section-wise Questions
                </h1>
                <div className="flex gap-4 px-4 py-2 items-center border-b border-b-gray-300">
                  <div className="flex items-center gap-1">
                    {/* <input type="checkbox" /> */}
                    <div className="w-4 h-4 border-2 border-[#59A51A] rounded"></div>
                    <p className="text-[1rem] my-auto font-avenir">Correct</p>
                  </div>
                  <div className="flex items-center gap-1">
                    {/* <input type="checkbox" /> */}
                    <div className="w-4 h-4 border-2 border-[#DB3555] rounded"></div>
                    <p className="text-[1rem] my-auto font-avenir">Incorrect</p>
                  </div>
                  <div className="flex items-center gap-1">
                    {/* <input type="checkbox" /> */}
                    <div className="w-4 h-4 border-2 border-[#737E93] rounded"></div>
                    <p className="text-[1rem] my-auto font-avenir">
                      Not attempted
                    </p>
                  </div>
                </div>
                {data["part1"] && (
                  <div className="mt-3 px-4">
                    <h1 className="mb-2 font-medium text-[#292D35] text-base font-avenir">
                      Part 1
                    </h1>
                    <div className="flex flex-wrap gap-4 mb-4 w-full ">
                      {data?.part1?.questions.length > 0 &&
                        data?.part1?.questions.map((item: any) => {
                          return (
                            <button
                              key={item?.qId}
                              className={`${questionIdClassName(item,aiFeedback.questionId)}  px-3 py-1 font-avenir cursor-pointer rounded-md `}
                              onClick={() => handleAIFeedback(item, "part1")}
                            >
                              {item.qId}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                )}

                {data["part2"] && (
                  <div className="mt-3 px-4">
                    <h1 className="mb-2 font-medium text-[#292D35] text-base font-avenir">
                      Part 2
                    </h1>
                    <div className="flex flex-wrap gap-4 mb-4">
                      {data?.part2?.questions.length > 0 &&
                        data?.part2?.questions.map((item: any) => {
                          return (
                            <button
                              key={item?.qId}
                              className={`${questionIdClassName(item,aiFeedback.questionId)}  px-3 py-1 font-avenir cursor-pointer rounded-md `}
                              onClick={() => handleAIFeedback(item, "part2")}
                            >
                              {item.qId}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                )}

                {data["part3"] && (
                  <div className="mt-3 px-4">
                    <h1 className="mb-2 font-medium text-[#292D35] text-base font-avenir">
                      Part 3
                    </h1>
                    <div className="flex flex-wrap gap-4 mb-4 font-avenir">
                      {data?.part3?.questions.length > 0 &&
                        data?.part3?.questions.map((item: any) => {
                          return (
                            <button
                              key={item?.qId}
                              className={`${questionIdClassName(item,aiFeedback.questionId)}  px-3 py-1 font-avenir cursor-pointer rounded-md `}
                              onClick={() => handleAIFeedback(item, "part3")}
                            >
                              {item.qId}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>

              <div className="border border-gray-300 rounded-md bg-white w-8/12">
                <h1 className="bg-[#F8F9FA] p-4 font-avenir border rounded-md rounded-b-none border-b-gray-300 font-medium text-[#292D35] text-lg">
                  Analysis
                </h1>
                {!aiFeedback.questionId ? (
                  <div className="flex justify-center items-center h-full font-avenir ">
                    {" "}
                    Please Select the Question to get the feedback
                  </div>
                ) : (
                  <div className="flex lg:flex-row justify-between w-full p-4 text-xs">
                    <div className="pr-5 w-full  mb-4 lg:mb-0 lg:mr-4">
                      <h1 className="text-sm text-[#737E93] uppercase mb-2 font-semibold font-avenir">
                        Academic Reading {aiFeedback.partType}
                      </h1>
                      <AiFeedbackQuestionDescription
                        questionType={aiFeedback.questionType}
                      />
                      {aiFeedback.questionType !== "Table Completion" ? (
                        <div>
                          <p className="text-[#292D35] text-sm mb-2">
                            {aiFeedback.questionType === "Matching Headings"
                              ? renderMatchingHeadingQname(
                                  aiFeedback.questionName,
                                  aiFeedback.userAnswer,
                                  aiFeedback.isCorrect
                                )
                              : renderQuestionsName(
                                  aiFeedback.questionName,
                                  aiFeedback.userAnswer,
                                  aiFeedback.isCorrect,
                                  data[`${aiFeedback.partType}`].questions
                                )}
                          </p>
                          <p className="text-[#737E93] uppercase my-2 mt-10 font-semibold text-sm font-avenir">
                            Your Answer
                          </p>
                          <p
                            className={`${getUserAnswerTextClassName(aiFeedback)} text-sm font-avenir`}
                          >
                            {getAnswerRender(aiFeedback,"userAnswer")}
                           
                          </p>
                          <p
                            className={`text-[#737E93] uppercase mb-2 mt-5 font-semibold text-sm font-avenir`}
                          >
                            Correct Answer
                          </p>
                          <p className={"text-[#59A51A] text-sm font-avenir"}>
                          {getAnswerRender(aiFeedback,"exactAnswer")}
                          </p>
                          <div
                            className={` mt-10 ${
                              getExactCorrectClassName(aiFeedback)
                            }  rounded-md px-3 py-2 w-3/4 font-avenir`}
                          >
                            {getTheAnswerStatement(aiFeedback)}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {aiFeedback.table && <RenderTable tableA={aiFeedback} data={data} />}
                          <p className="text-[#737E93] uppercase my-2 mt-10 font-semibold text-sm font-avenir">
                            Your Answer
                          </p>
                          <p
                            className={`${getUserAnswerTextClassName(aiFeedback)
                            } text-sm font-avenir"`}
                          >
                            {aiFeedback.userAnswer !== ""
                              ? aiFeedback.userAnswer
                              : "Not Attempted"}
                          </p>
                          <p
                            className={`text-[#737E93] uppercase mb-2 mt-5 font-semibold text-sm font-avenir`}
                          >
                            Correct Answer
                          </p>
                          <p className={"text-[#59A51A] text-sm font-avenir"}>
                            {aiFeedback.exactAnswer}
                          </p>
                          <div
                            className={` mt-10 ${getExactCorrectClassName(aiFeedback)
                            }  rounded-md px-3 py-2 w-3/4 font-avenir`}
                          >
                            {getTheAnswerStatement(aiFeedback)}
                            
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <p className="text-sm text-[#4E5664] font-extrabold mb-4 font-avenir">
                        {aiFeedback.paragraphTitle}
                      </p>
                      <p className="text-sm text-justify font-avenir leading-7">
                        {renderParagraph(aiFeedback.paragraphContent)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!userStore.isProUser && <LockedContentOverlay startHandler={startHandler}/>}
          </div>
        </CustomTabPanel>
      </BoxTabs>
    </div>
  );
};

export default observer(ReadingResults);
