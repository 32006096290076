// components/ReadingTableCompletion.tsx

import { observer } from 'mobx-react-lite';
import React from 'react';
import CommonTable from '../../common/commonTableCompletion';

const ReadingTableCompletion = ({
  item,
  inputHandler,
  tableCompletionAnswer,
}: any) => {
  const { questionTable } = item;
  const { tbody} = questionTable;
  let headings = Object.keys(tbody[0]);

  return (
    <>
      <div className="text-[#3C424C] text-sm mb-5 ">
        Complete the table. Write <span className="font-semibold">ONE WORD ONLY </span>{''}
        from the text for each answer.
      </div>
      <CommonTable
        headings={headings}
        tbody={tbody}
        tableCompletionAnswer={tableCompletionAnswer}
        inputHandler={inputHandler}
      />
    </>
  );
};

export default observer(ReadingTableCompletion);
