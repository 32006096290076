// components/ListeningTableCompletion.tsx

import { observer } from 'mobx-react-lite';
import React from 'react';
import CommonTable from '../../common/commonTableCompletion';

const ListeningTableCompletion = ({
  item,
  inputHandler,
  tableCompletionAnswer,
}: any) => {
  const { listOfQuestions } = item;
  const { tbody } = listOfQuestions[0].items;
  let headings = Object.keys(tbody[0]);

  return (
    <>
      <div className="text-[#3C424C] text-sm mb-5">
        Complete the table. Write <span className="font-semibold">ONE</span>{" "}
        <span>WORD</span> <span>ONLY</span> from the text for each answer.
      </div>
      <CommonTable
        headings={headings}
        tbody={tbody}
        tableCompletionAnswer={tableCompletionAnswer}
        inputHandler={inputHandler}
      />
    </>
  );
};

export default observer(ListeningTableCompletion);
