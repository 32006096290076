import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Hooks/use-store";
import microphone from "../../icons/microphone.svg";
// @ts-ignore
import MicRecorder from "mic-recorder-to-mp3";
import "../../css/pulsateImage.css";
import "../../css/answerRecording.css";
import Timer from "../common/recordTimer";
import Progress from "../common/Progressbar/progress";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ProgressiveImage from "../common/ProgressiveImage";
import tinyImage from "../../icons/examnrBlur.jpg"

const PulsateImage = observer((props: any) => {
  const [time, setTime] = useState(0);
  const { startTestStore } = useStore();
  const [question_id, setQuestion_id] = useState();
  const location = useLocation();
  const [Mp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 })
  );
  const [examinerImage, setExaminerImage] = useState("");
  const [progressTimer,setProgressTimer] = useState(0)

  const partOneQuestionsSubmit = async (question_id: any, blobURL: any) => {
    let testId: any = startTestStore.testId;
    const formData = new FormData();
    formData.append("test_id", testId);
    if (props.testType !== "Part 2") {
      formData.append("question_id", question_id);
    } else {
      formData.append("card_id", question_id);
    }
    await fetch(blobURL)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "audio.mp3", { type: "audio/mp3" });
        formData.append("audio_file", file);
      });

    await startTestStore.submitAnswer(formData);
  };

  const start = () => {
    if (startTestStore.isBlocked) {
        toast.error("Microphone Access Denied: Test Aborted");
        props.handleClose(true)
    } else {
      Mp3Recorder.start()
        .then(() => {
          startTestStore.setIsRecording(true);
        })
        .catch((e: any) => console.error(e));
    }
  };
  const handleMp3Recording = (questionId:any) => {
    if (Mp3Recorder) {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]: [Uint8Array, Blob]) => {
          const blobURL = URL.createObjectURL(blob);
          if (props.testType !== "Part 2") {
            partOneQuestionsSubmit(questionId, blobURL);
          } else {
            partOneQuestionsSubmit(startTestStore.partTwoCardId, blobURL);
          }
  
          startTestStore.blobURL = blobURL;
          startTestStore.setIsRecording(false);
          startTestStore.setCounter(true);
        })
        .catch((e: any) => console.log(e));
    }
  };
  
  const stop = () => {
    if (props.testType == "Part 1" || props.testType == "Part 3") {
      if (time >= 240) {
        handleMp3Recording(question_id);
        props?.nextStep();
        startTestStore.setIsRecording(false);
        return;
      }
    }

    if (startTestStore.counter <= startTestStore.getTestV2Result.length - 1) {
      handleMp3Recording(question_id);
    } else {
      props?.nextStep();
      startTestStore.setIsRecording(false);
    }
  };
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        startTestStore.setIsBlocked(false);
      })
      .catch((error) => {
        startTestStore.setIsBlocked(true);
      });
  }, []);

  useEffect(() => {
    let currentCount = startTestStore.counter;
    let audio: any;

    if (currentCount < startTestStore.getTestV2Result.length) {
      let currentAudio =
        startTestStore.getTestV2Result[currentCount]?.data?.audio;
      if (currentAudio) {
        setQuestion_id(startTestStore.getTestV2Result[currentCount].data.id);
        audio = new Audio(currentAudio);
        if (props.testType == "Part 2" && currentCount == 0) {
          startTestStore.setIsAnimated(true);
          audio.addEventListener("ended", () => {
            startTestStore.setIsAnimated(false);
            startTestStore.setIsTaskCard(true);
          });
        } else {
          startTestStore.setIsAnimated(true);
          audio.addEventListener("ended", () => {
            startTestStore.setIsAnimated(false);
            startTestStore.setIsRecording(true);
            start();
          });
        }
        audio.play().then(() => {
          // Audio playback started successfully
          console.log("Audio playback started");
      }).catch((error:any) => {
          // Handle error if audio playback fails
          startTestStore.setCounter(true);
          console.error("Failed to start audio playback:", error)
      });
      } else {
        startTestStore.setCounter(true);
      }
      let examImage = startTestStore.getTestV2Result[0].examiner_image;
      setExaminerImage(examImage);
    } else {
      props?.nextStep();
    }
    return () => {
      if (audio) {
        audio.pause();
        audio.removeEventListener("ended", () => {});
      }
    };
  }, [startTestStore.counter, startTestStore.getTestV2Result]);

  useEffect(() => {
    const intervalProgress = setInterval(() => {
      setProgressTimer((prevTimeProgress: any) => {
        if (startTestStore.isRecording) {
          return prevTimeProgress + 1;
        } else {
          clearInterval(intervalProgress);
          prevTimeProgress = 0
          return prevTimeProgress;
        }
      });
    }, 1000);
  }, [startTestStore.isRecording]);

  useEffect(() => {
    if(progressTimer >= 115){
      stop();
    }

  },[progressTimer])

  const renderTimer = () => {
    if (
      location.pathname == "/part2" ||
      (location.pathname == "/fulltest" && startTestStore.partType === "Part 2")
    ) {
      if (startTestStore.isRecording) {
        return <Timer time={time} setTime={setTime} />;
      }
    } else {
      return <Timer time={time} setTime={setTime} />;
    }
  };
  return (
    <>
      <div className="flex  justify-end px-4">{renderTimer()}</div>
      <div
        className={`${startTestStore.isAnimated ? "pulse" : "pulse-off"}`}
        style={{ border: "2px solid red", marginTop: "60px" }}
      >
        <ProgressiveImage src={examinerImage} placeHolderImage={tinyImage}  height='800' width='800' isRounded={true} alt='teacher'/>
        {/* <img
          src={examinerImage}
          alt="teacher-image"
          className="rounded-full z-10"
        /> */}
      </div>
      <div
        className={`${
          startTestStore.isRecording ? "pulse-recorder" : "pulse-recorder-off"
        } mt-16 border border-solid border-[#E3E5E9] `}
      >
        <img src={microphone} alt="mic" className="rounded-full z-10" />
      </div>

      {startTestStore.isRecording && (
        <div className="w-full ">
          <div className="my-16">
            {" "}
            <Progress  maxTime="120000"/>
          </div>

          <button
            className={`block mx-auto ${progressTimer<=5 ? "bg-gray-400": "bg-red-500"} rounded-full px-4 py-2 text-white`} 
            onClick={stop}
            disabled={progressTimer<=5}
          >
            Next
          </button>
        </div>
      )}

      {/* <audio src={startTestStore.blobURL} controls /> */}
    </>
  );
});
export default PulsateImage;
