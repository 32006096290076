// Tab.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';

interface TabProps {
  label: string;
  onClick: () => void;
  isActive: boolean;
}

const Tab: React.FC<TabProps> = ({ label, onClick, isActive }) => {
  return (
    <button
      className={`cursor-pointer px-4 py-2 border-b-[5px] rounded-tl-10 rounded-tr-10 rounded-b-0 font-extrabold ${isActive ? 'border-red-600 text-[#E31837]' : 'border-transparent'}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default observer(Tab);
