import React from 'react'
import { useStore } from '../../../Hooks/use-store'
import ListeningPart from './ReusableListeningPart';
import { observer } from 'mobx-react-lite';

const ListeningPart2 = observer(({ nextStep } : any) => {
  const { startTestStoreListening } = useStore();
  const { part2Questions } = startTestStoreListening;
  const part2StateData = {
    singleAnswer : startTestStoreListening.part2SingleAnswer,
    setSingleAnswer : startTestStoreListening.setPart2SingleAnswer,
    multipleAnswer : startTestStoreListening.part2MultipleAnswer,
    setMultipleAnswer : startTestStoreListening.setPart2MultipleAnswer,
    taskIdentifyingItemAnswers : startTestStoreListening.part2TaskIdentifyingItemAnswers,
    setTaskIdentifyingItemAnswers : startTestStoreListening.setPart2TaskIdentifyingItemAnswers,
    noteCompletionAnswer : startTestStoreListening.part2NoteCompletionAnswer,
    setNoteCompletionAnswer : startTestStoreListening.setPart2NoteCompletionAnswer,
    planDiagramAnswer : startTestStoreListening.part2PlanDiagramAnswer,
    setPlanDiagramAnswer : startTestStoreListening.setPart2PlanDiagramAnswer,
    shortAnswer : startTestStoreListening.part2ShortAnswer,
    setShortAnswer : startTestStoreListening.setPart2ShortAnswer,
    senetenceComplete : startTestStoreListening.part2SenetenceComplete,
    setSenetenceComplete : startTestStoreListening.setPart2SenetenceComplete,
    summaryCompletionOneWordAnswers : startTestStoreListening.part2SummaryCompletionOneWordAnswers,
    setSummaryCompletionOneWordAnswers : startTestStoreListening.setPart2SummaryCompletionOneWordAnswers,
    dragAndDropAnswers : startTestStoreListening.part2DragAndDropAnswers,
    setDragAndDropAnswers : startTestStoreListening.setPart2DragAndDropAnswers,
    draggedCurrentSentence : startTestStoreListening.part2DraggedCurrentSentence,
    setDraggedCurrentSentence : startTestStoreListening.setPart2DraggedCurrentSentence,
    tableCompletionAnswer : startTestStoreListening.part2TableCompletionAnswer,
    setTableCompletionAnswer : startTestStoreListening.setPart2TableCompletionAnswer
  }

  return (
      <ListeningPart store={startTestStoreListening} partType={'part2'} stateData={part2StateData} paraQuestions={part2Questions} nextStep={nextStep}/>
  )
})

export default ListeningPart2