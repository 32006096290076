import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import BottomRightIcon from "../../icons/undraw_different_love_a-3-rg 1-right (1).png";
import BottomLeftIcon from "../../icons/undraw_different_love_a-3-rg 1-left.png";
import GoogleImage from "../../icons/google image.svg";
import { useStore } from "../../Hooks/use-store";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import "../../../src/styles.css";
import { observer } from "mobx-react-lite";
import { OAuthUrl } from "../../api/api-config";

function Login() {
  let navOtp = useNavigate();
  const { userStore, dashboardStore } = useStore();
  const { studentDetails } = userStore;
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const onClickHandler = async () => {
    if (studentDetails.mobileNumber && isValid) {
      setLoading(true);
      if (studentDetails.mobileNumber) {
        let student = JSON.parse(JSON.stringify(studentDetails));
        student.emailId = "";
        userStore.updateStudentDetails(student);
        userStore.updateMobileNumber(studentDetails.mobileNumber);
        try {
          const res = await userStore.request_otp(studentDetails.mobileNumber);
          if (res.status === 200) {
            navOtp("/otp");
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
        }
      }
    }
  };

  const gmailHandler = () => {
    let redirectURl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=612878259205-gojd9o52i6om5tnpu2aohbfgln4ikbfb.apps.googleusercontent.com&redirect_uri=${OAuthUrl}&scope=openid%20profile%20email&access_type=offline`;
    window.location.href = redirectURl;
  };

  const handleOnChange = (value: any, country: any) => {
    if (isValidPhoneNumber("+" + value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    userStore.updateMobileNumber(`+${value}`);
  };

  useEffect(() => {
    // This effect runs only once when the component mounts
    // Disable browser back button
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };

    // Cleanup function
    return () => {
      window.onpopstate = null;
    };
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    userStore.updateMobileNumber("");
    userStore.clearIsLoggedIn();
  }, []);

  return (
    <>
      <div className="bg-[#FFC9C9] p-4 md:p-0 w-full min-h-screen flex justify-center items-center relative ">
        <div className="md:pt-20 md:pb-28 md:px-[120px] p-4 flex flex-col justify-center items-center border rounded-2xl bg-[#FFFFFF] md:w-[50%] w-full max-w-screen-md z-[999]">
          <h1 className="text-[#E31837] text-[40px] font-manrope font-bold tracking-wider text-center mb-11">
            IELTS
          </h1>
          <button
            className="text-[#3C424C] text-2xl font-extrabold text-center mb-10"
            onClick={() => dashboardStore.showProfilePage(false)}
          >
            Log In
          </button>
          <div className="flex justify-center items-center w-full md:w-[80%]">
            <PhoneInput
              containerClass="w-full"
              inputClass="h-12 border border-[1px] rounded-3xl border border-red-600 mb-4 px-2"
              country={"in"}
              placeholder=""
              value={studentDetails.mobileNumber}
              onChange={handleOnChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  onClickHandler();
                }
              }}
            />
          </div>
          <button
            className={`text-[#FFFFFF] ${
              loading || !studentDetails.mobileNumber || !isValid
                ? "bg-gray-300 text-white"
                : "bg-[#E31837] text-white"
            }  w-full md:w-[80%] h-12 border-[1px] rounded-3xl mt-10 mb-20 px-120`}
            onClick={onClickHandler}
            disabled={loading || !studentDetails.mobileNumber || !isValid}
          >
            {loading ? <div className="buttonLoader mx-auto"></div> : "GET OTP"}
          </button>
          <p className="text-[#737E93] text-sm font-bold text-center mb-6">
            Or continue with
          </p>
          <div className="flex justify-center w-full">
            {/* <span className="mx-2">
            <img src={FacebookImage} alt="Facebook" />
          </span>
          <span className="mx-2">
            <img src={AppleImage} alt="Apple" />
          </span> */}
            <span className="mx-2">
              <button className="cursor-pointer" onClick={gmailHandler}>
                <img src={GoogleImage} alt="Google" />
              </button>
            </span>
          </div>
        </div>
      </div>
      <img
        src={BottomRightIcon}
        alt="right"
        className="absolute bottom-0 right-0"
      />
      <img
        src={BottomLeftIcon}
        alt="left"
        className="absolute bottom-0 left-0"
      />
    </>
  );
}

export default observer(Login);
