import React from 'react';

const extractQuestionId = (string: any) => {
  let startIndex = string.indexOf("{");
  let lastIndex = string.indexOf("}");

  if (startIndex !== -1 && lastIndex !== -1) {
    let extractedString = string
      .slice(startIndex + 1, lastIndex)
      .replace(/\s/g, "");

    let index = extractedString.indexOf(":");

    if (index !== -1) {
      let result = extractedString.substring(index + 1);
      return result;
    }
  }
};

const renderUserAnswer = (cell: any, matchQuestion: any): React.ReactNode => {
  let startIndex = cell.indexOf("{");
  let endIndex = cell.indexOf("}");
  let startString = cell.substring(0, startIndex).trim();
  let endString = cell.substring(endIndex + 1).trim();

  return (
    <p>
      {matchQuestion ? (
        <span className="font-avenir">
          {startString}{" "}
          <span
            className={`${
              matchQuestion.isCorrect ? "text-[#59A51A]" : "text-[#DB3555]"
            } font-semibold font-avenir`}
          >
            {matchQuestion.userAnswer ? (
              <>
                <span className=" font-bold ">{matchQuestion.qId}.</span>{" "}
                {matchQuestion.userAnswer}
              </>
            ) : (
              <span className="text-red-600">
                {''}<span className=" font-bold ">{matchQuestion.qId}.</span>
                {''}_______
              </span>
            )}{" "}
          </span>
          {endString}
        </span>
      ) : (
        cell
      )}
    </p>
  );
};

const renderCell = (cell: any, questions: any): React.ReactNode => {
  let matchQuestion;
  if (cell.includes("{qId:")) {
    let extractedQId = extractQuestionId(cell);

    matchQuestion =
      questions.length > 0 &&
      questions.find((item: any) => item.qId === extractedQId);
    console.log(
      extractedQId,
      questions,
      matchQuestion,
      "CELL CELL"
    );
  }
  return (
    <p className="font-avenir">{renderUserAnswer(cell, matchQuestion)}</p>
  );
};

const RenderTable: React.FC<{ tableA: any, data: any }> = ({ tableA, data }) => {
  let tableArray = tableA.table;

  return (
    <div>
      <table className="border border-[#E3E5E9] rounded-md ">
        <thead className="rounded">
          <tr className="bg-[#FFEAEA] ">
            {Object.keys(tableArray[0]).map((item: any) => (
              <th key={item} className="py-4 px-4 font-avenir">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableArray.map((row: any, rowIndex: number) => (
            <tr key={row[rowIndex]}>
              {Object.values(row).map((cell: any, cellIndex: number) => (
                <td key={cell[cellIndex]} className="border px-2 py-4 font-avenir">
                  {renderCell(cell, data[tableA.partType].questions)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RenderTable;
