import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircularProgress from "@mui/material/CircularProgress";
import { setLinearProgressColors } from "../../../utils";
import useGetFraction from "./useGetFraction";
import { ILinearProgressBar } from "./detailedResults";

const LinerProgressBar = (props: ILinearProgressBar) => {
  const { item } = props;
  const { average, percentage, name, outerbgcolor, innerbgcolor } =
    setLinearProgressColors(item);
  return (
    <>
      <div className="flex flex-row justify-between text-[#4E5664] font-semibold">
        <p className="text-[14px]">{name}</p>
        <p>{average}/9</p>
      </div>

      <div
        className="w-full h-[8px]  rounded-2xl"
        style={{
          backgroundColor: outerbgcolor,
        }}
      >
        <div
          className=" h-full rounded-2xl"
          style={{
            backgroundColor: innerbgcolor,
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </>
  );
};

const ReportCardDetails = (props: any) => {
  return (
    <div className="flex flex-col w-[19rem] max-h-[400px]  border border-[#E3E5E9] rounded-[8px] mt-[5px] mr-2">
      <div className="px-[16px] pt-[16px]">
        <LinerProgressBar item={props.reportData} />
      </div>
      <ul
        className="pt-[22px] px-[16px] ml-4 mt-1 overflow-y-auto scrollbar-ielts"
        style={{ listStyleType: "disc" }}
      >
        {props.reportData.description.map((item: any, index: any) => (
          <li key={item} className="my-2 text-[#737E93]">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ReportFullDetails = ({ partDetails }: any) => {
  const { fraction, percentage, pathColor, trailColor, textColor } =
    useGetFraction(partDetails);

  return (
    <>
      {!partDetails ? (
        <div className="m-auto my-5">
          <CircularProgress size={80} />
        </div>
      ) : (
        <div>
          <div className="flex flex-row flex-wrap">
            <div className="border border-[#E3E5E9] rounded-b-[8px] w-full md:h-[22rem] h-full flex flex-col">
              <div className="flex flex-col md:flex-row justify-between ">
                <div className="flex flex-col md:mx-[32px] mx-1 my-[16px] w-[75%] justify-between h-full">
                  {partDetails.detailed_report &&
                    partDetails.detailed_report.length > 0 &&
                    partDetails.detailed_report.map((item: any, index: any) => {
                      return <LinerProgressBar item={item} key={item} />;
                    })}
                </div>
                <div className="md:w-[22.88rem] md:h-[18.75rem] w-[10rem] h-[10rem] rounded-[8px] flex justify-center items-center mb-2 ml-2 ">
                  <div className="w-[180px] h-[180px] ">
                    <CircularProgressbar
                      value={percentage}
                      text={`${fraction}/9`}
                      styles={buildStyles({
                        rotation: 1,
                        strokeLinecap: "round",
                        textSize: "20px",
                        pathTransitionDuration: 0.5,
                        pathColor: pathColor,
                        textColor: textColor,
                        trailColor: trailColor,
                      })}
                    />
                    <div className="md:text-[20px] text-[15px] font-Avenir font-medium text-[#3C424C] flex justify-center md:pt-[20px]">
                      Avg Band Score
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-[30px]">
            <div className="font-medium text-2xl font-Avenir text-[#292D35] ">
              Detailed Report
            </div>
            <div className="flex flex-row  overflow-x-scroll lg:overflow-x-hidden md:overflow-x-hidden">
              {partDetails.detailed_report &&
                partDetails.detailed_report.length > 0 &&
                partDetails.detailed_report.map((item: any) => {
                  return <ReportCardDetails reportData={item} key={item}/>;
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportFullDetails;
