import { useEffect } from "react";
import InstructionCard from "../../instructionsCard/instructionCard";
import { useStore } from "../../../Hooks/use-store";
import { observer } from "mobx-react-lite";

const Part1Test = observer((props:any) => {
  const { startTestStore,userStore } = useStore();

  let PartOneINstructions = [
    "1.In this part, examiner will ask you general questions on familiar topics, e.g. home, family, work, studies & interests.",
    "2.This section should help you relax and talk naturally.",
  ];

  useEffect(()=>{
    let isProUser = userStore.isProUser;

    if(isProUser  && startTestStore.testType!=='full'){
      startTestStore.setCurrentStep("step3")
     }
    startTestStore.setCurrentStep("step1")
  },[])

  return (
    <div className="w-full mx-auto">
        <InstructionCard testType="Part 1" instructions={PartOneINstructions} />
    </div>
  );
});

export default Part1Test;
