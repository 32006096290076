import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../Hooks/use-store";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import BottomRightIcon from "../../icons/undraw_different_love_a-3-rg 1-right (1).png";
import BottomLeftIcon from "../../icons/undraw_different_love_a-3-rg 1-left.png";
import Timer from "./timer";
import { jwtDecode, JwtPayload } from "jwt-decode";

interface CustomJwtPayload extends JwtPayload {
  operational: boolean;
}

function OTP() {
  let navFromSignUp = useNavigate();
  const { userStore, dashboardStore } = useStore();
  const { studentDetails } = userStore;
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isVerificationInProgress, setIsVerificationInProgress] =
    useState(false);
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(4).fill(null));
  const [time, setTime] = useState(120);
  const [resendOTP, setResendOTP] = useState(true);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const otpInputHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const targetValue = event.target.value;

    if (targetValue.length === 1 && index <= 3) {
      const newOtp = [...otp];
      newOtp[index] = targetValue;
      setOtp(newOtp);

      if (index < 3) {
        const nextInput = event.target.nextSibling as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if (targetValue.length === 0 && index >= 0) {
      const newOtp = [...otp];
      newOtp[index] = targetValue;
      setOtp(newOtp);

      if (index > 0) {
        const prevInput = event.target.previousSibling as HTMLInputElement;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };
  const onClickHandler = async () => {
    // Reset the timer to 10 seconds
    setTime(120);

    // Enable Resend button
    setResendOTP(true);
    if (studentDetails.mobileNumber) {
      userStore.updateMobileNumber(studentDetails.mobileNumber);
      try {
        await userStore.request_otp(studentDetails.mobileNumber);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const verifyOtpHandler = async () => {
    let mobileNumber = userStore.studentDetails.mobileNumber;
    dashboardStore.showProfilePage(false);
    dashboardStore.updateCurrentNavBar("Speaking");
    if (mobileNumber) {
      setIsVerificationInProgress(true);
      const enteredOtp = otp.join("");
      let res = await userStore.verify_otp(mobileNumber, enteredOtp);
      if (res.status === 200) {
        let decodedToken = jwtDecode<CustomJwtPayload>(res.data.token);
        userStore.updateOperationalUser(decodedToken?.operational);
        if (decodedToken?.operational) {
          navFromSignUp("/operationalutilities");
        } else {
          res.data.already_registered
            ? navFromSignUp(`/${userStore.userDashboards[0].toLowerCase()}`)
            : navFromSignUp("/details");
          userStore.updateAlreadyRegistered(res.data.already_registered);
        }
        setIsVerificationInProgress(false);
      } else {
        setIsVerificationInProgress(false);
        toast.error(res.data.detail);
      }
    }
  };

  const isSignUpButtonDisabled = otp.some((value) => value.length !== 1);
  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);
  useEffect(() => {
    if (time === 0) {
      setResendOTP(false);
    }
  }, [time]);

  return (
    <>
      <div className="bg-[#FFC9C9] w-full min-h-screen flex justify-center items-center relative">
        <div className="flex flex-col justify-center items-center border rounded-2xl bg-[#FFFFFF] lg:w-[50%] md:w-[50%] w-3/4 max-w-screen-md p-4 z-[999]">
          <h1 className="text-[#E31837] text-4xl font-manrope font-semibold tracking-wider text-center mb-4">
            IELTS
          </h1>
          <p className="text-[#3C424C] text-lg font-extrabold text-center mb-4">
            Enter 4 digit OTP sent to your number
          </p>
          <div className="flex items-center justify-center">
            {otp.map((value, index) => {
              let keyId = index + 1;
              return (
                <input
                  key={keyId}
                  type="tel"
                  className="lg:w-8 md:w-8 w-5 lg:h-8  md:h-8 h-5 border m-2 rounded-2xl bg-transparent outline-none text-center font-semibold text-sm lg:text-xl md:text-xl border-opacity-50 border-[#E31837] mb-6"
                  onChange={(evt) => otpInputHandler(evt, index)}
                  value={value}
                  ref={(inputRef) => (inputRefs.current[index] = inputRef)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      verifyOtpHandler();
                    }
                  }}
                />
              );
            })}
          </div>

          <button
            className={`text-[#FFFFFF] w-[80%] h-12 border rounded-3xl mb-4 px-120 ${
              isSignUpButtonDisabled || isVerificationInProgress
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-[#E31837]"
            }`}
            onClick={verifyOtpHandler}
            disabled={isSignUpButtonDisabled || isVerificationInProgress} // Disable the button if OTP is incomplete
          >
            {isVerificationInProgress ? (
              <div className="buttonLoader mx-auto"></div>
            ) : (
              "Sign up"
            )}
          </button>
          <p className="text-[#737E93] flex">
            Didn't receive the OTP?{" "}
            {resendOTP ? (
              <span className="flex">
                Retry in{" "}
                <Timer
                  time={time}
                  setTime={setTime}
                  intervalRef={timerIntervalRef}
                />
                <span></span>
              </span>
            ) : (
              <button
                className="text-[#E31837] cursor-pointer"
                onClick={onClickHandler}
              >
                Resend
              </button>
            )}
          </p>
        </div>
      </div>
      <img
        src={BottomRightIcon}
        alt="right"
        className="absolute bottom-0 right-0"
      />
      <img
        src={BottomLeftIcon}
        alt="left"
        className="absolute bottom-0 left-0"
      />
    </>
  );
}

export default OTP;
