const useDragAndDropSentence = () => {
  const handleDragSentenceStartFromAnswer = (e: any, answer: any) => {
    e.dataTransfer?.setData("SentenceEnding", answer.toString());
    e.dataTransfer?.setData("displayedItem", "");
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return {
    handleDragSentenceStartFromAnswer,
    handleDragOver,
  };
};

export default useDragAndDropSentence;
