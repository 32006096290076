import WritingInstructionsCard from "../common/WritingInstructionsCard";
import { observer } from "mobx-react-lite";

const ListeningInstructions = observer((props: any) => {
  const { nextStep } = props;
  return (
    <div className="w-full flex justify-center flex-col">
      <p className="w-11/12 mx-10 text-base md:text-xl font-extrabold mb-6">
        IELTS Academic Listening - 30 minutes
      </p>
      <WritingInstructionsCard
        border="rgba(227, 24, 55, 0.30)"
        bg="linear-gradient(216deg, #FFF9FA 1.21%, #FFF 97.42%)"
        width="w-full"
        height="h-full"
      >
        <div>
          <h3 className="text-base md:text-xl font-extrabold mb-2">
            INSTRUCTIONS TO CANDIDATES
          </h3>
          <h4>1. Answer all the questions.</h4>{" "}
          <h4>2. You can change your answers at any time during test.</h4>
        </div>

        <div className="mt-6">
          <h3 className="text-base md:text-xl font-extrabold mb-2">
            INFORMATION FOR CANDIDATES
          </h3>
          <h4>1. There are 40 questions in this test.</h4>
          <h4>2. Each question carries 1 mark.</h4>
          <h4>3.There are four parts to the test.</h4>
          <h4>4.You will hear each part once.</h4>
          <h4>
            5.For each part of the test there will be time for you to look
            through the questions and time for you to check your answers.
          </h4>
          <h4>
            6.Progress to the next tab is allowed only upon completing the
            current tab, with no option to return to previous tabs.
          </h4>
        </div>

        <button
          onClick={nextStep}
          className="bg-[#E31837] text-white py-2 mt-6 px-6 mx-auto rounded-full"
        >
          Start Test
        </button>
      </WritingInstructionsCard>
    </div>
  );
});

export default ListeningInstructions;