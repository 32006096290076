import React from 'react'
import ReadingPartTest from './ReusablePart'
import { useStore } from "../../../Hooks/use-store";
import { observer } from "mobx-react-lite";

const ReadingPart1 = observer(({ nextStep }: any) => {
    const { startTestStoreReading } = useStore();
    const { part1Questions } = startTestStoreReading;
    const part1StateData = {
      singleAnswer : startTestStoreReading.part1SingleAnswer,
      setSingleAnswer : startTestStoreReading.setPart1SingleAnswer,
      multipleAnswer : startTestStoreReading.part1MultipleAnswer,
      setMultipleAnswer : startTestStoreReading.setPart1MultipleAnswer,
      taskIdentifyingItemAnswers : startTestStoreReading.part1TaskIdentifyingItemAnswers,
      setTaskIdentifyingItemAnswers : startTestStoreReading.setPart1TaskIdentifyingItemAnswers,
      noteCompletionAnswer : startTestStoreReading.part1NoteCompletionAnswer,
      setNoteCompletionAnswer : startTestStoreReading.setPart1NoteCompletionAnswer,
      senetenceComplete : startTestStoreReading.part1SenetenceComplete,
      setSenetenceComplete : startTestStoreReading.setPart1SenetenceComplete,
      summaryCompletionOneWordAnswers : startTestStoreReading.part1SummaryCompletionOneWordAnswers,
      setSummaryCompletionOneWordAnswers : startTestStoreReading.setPart1SummaryCompletionOneWordAnswers,
      dragAnswers : startTestStoreReading.part1DragAnswers,
      setDragAnswers : startTestStoreReading.setPart1DragAnswers,
      dragAndDropAnswers : startTestStoreReading.part1DragAndDropAnswers,
      setDragAndDropAnswers : startTestStoreReading.setPart1DragAndDropAnswers,
      dragSentenceAnswers : startTestStoreReading.part1DragSentenceAnswers,
      setDragSentenceAnswers : startTestStoreReading.setPart1DragSentenceAnswers,
      draggedCurrentSentence : startTestStoreReading.part1DraggedCurrentSentence,
      setDraggedCurrentSentence : startTestStoreReading.setPart1DraggedCurrentSentence,
      tableCompletionAnswer : startTestStoreReading.part1TableCompletionAnswer,
      setTableCompletionAnswer : startTestStoreReading.setPart1TableCompletionAnswer,
      matchingHeadingOptions : startTestStoreReading.part1MatchingHeadingOptions,
      setMatchingHeadingOptions : startTestStoreReading.setPart1MatchingHeadingOptions,
      matchingHeadingAnswer : startTestStoreReading.part1MatchingHeadingAnswer,
      setMatchingHeadingAnswer : startTestStoreReading.setPart1MatchingHeadingAnswer,
      matchingFeatureAnswers : startTestStoreReading.part1MatchingFeatureAnswers,
      setMatchingFeatures : startTestStoreReading.setPart1MatchingFeatures,
    }

  return (
    <ReadingPartTest store={startTestStoreReading} stateData={part1StateData} paraQuestions={part1Questions} nextStep={nextStep} partType={'part1'}/>
  )
})

export default ReadingPart1