// Tabs.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import Tab from './Tab';
import { useTabs } from '../../../customHooks/useTabs';

interface TabsProps {
  tabs: {
    label: string;
    content: JSX.Element;
  }[];
  partType: string;
}

const Tabs: React.FC<TabsProps> = ({ tabs, partType }) => {
  const { activeTab, handleTabClick, getTabContent } = useTabs(tabs, partType);

  const renderTabs = () => {
    if (['part1', 'part2', 'part3', 'part4'].includes(partType)) {
      const partIndex = Number(partType.replace('part', '')) - 1;
      return (
        <Tab
          key={partIndex}
          label={tabs[partIndex].label}
          onClick={() => handleTabClick(partIndex)}
          isActive={true}
        />
      );
    }

    return tabs.map((tab, index) => (
      <Tab
        key={tab.label}
        label={tab.label}
        onClick={() => handleTabClick(index)}
        isActive={index === activeTab}
      />
    ));
  };

  return (
    <div>
      <div className="flex border border-[#E3E5E9] rounded-t-[8px] pt-4 pl-4 bg-[#E3E5E9]">
        {renderTabs()}
      </div>
      <div>{getTabContent(partType, tabs)}</div>
    </div>
  );
};

export default observer(Tabs);
