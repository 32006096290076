import { useState, useEffect } from "react";
import {
  calculatePercentage,
  getColorsByPercentage,
  getFraction,
} from "../../../utils";

const useGetFraction = (partDetails: any) => {
  const [fraction, setFraction] = useState("");

  const percentage = calculatePercentage(partDetails?.overall_score);
  const { pathColor, trailColor, textColor } =
    getColorsByPercentage(percentage);

  useEffect(() => {
    setFraction(getFraction(partDetails));
  }, [partDetails]);

  return { fraction, percentage, pathColor, trailColor, textColor };
};

export default useGetFraction;
