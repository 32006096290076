import React from "react";

interface ErrorPageProps {
  errorMessage: string;
  onBackToHome: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorMessage,
  onBackToHome,
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white text-center p-4 -mt-8">
      <h1 className="text-red-400 text-4xl sm:text-6xl font-medium mb-4 font-serif">Oops!</h1>
      <p className="text-gray-600 mb-8 font-semibold text-xl sm:text-2xl">{errorMessage}</p>
      <button
        onClick={onBackToHome}
        className="bg-gray-600 hover:bg-gray-700 text-white font-semibold px-4 py-2 sm:px-6 sm:py-2 rounded-lg transition-colors"
      >
        Back to Home
      </button>
      <div className="mt-4">
        <p className="text-gray-600 font-medium text-sm sm:text-lg">
          If you have any queries, please contact us at<a href="mailto:support@cambri.ai" className="ml-1 underline text-gray-600 font-semibold">support@cambri.ai</a></p>
      </div>
    </div>
  );
};

export default ErrorPage;
