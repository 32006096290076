// DetailedResultsWriting.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../Hooks/use-store';
import ReportFullDetails from './reportFullDetails';
import Tabs from '../ieltsResultsTabs/Tabs';

const DetailedResultsWriting: React.FC = () => {
  const { resultsStoreWriting } = useStore();
  const { individualTestScorePart1, individualTestScorePart2, partType } = resultsStoreWriting;

  const tabs = [
    { label: 'Part 1', content: <div><ReportFullDetails partDetails={individualTestScorePart1} /></div> },
    { label: 'Part 2', content: <div><ReportFullDetails partDetails={individualTestScorePart2} /></div> },
  ];

  return (
    <div>
      <header className="bg-[#E3E5E9]-500 border-b-2">
        <Tabs tabs={tabs} partType={partType} />
      </header>
    </div>
  );
};

export default observer(DetailedResultsWriting);
