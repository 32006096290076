import { observer } from "mobx-react-lite";
import React from "react";

const SingleChoiceQuestions = ({ item, handleRadioChange, singleAnswers }: any) => {
  return (
    <div>
      <div className="text-[#3C424C] text-sm"> Choose the correct answer</div>
      {item["list-of-questions"].length > 0 &&
        item["list-of-questions"].map((question: any, index: any) => {
          return (
            <>
              {" "}
              <div key={question.qId} className="p-4 border border-[#E3E5E9] rounded-xl m-2 flex flex-col">
                <div className="text-[#292D35] text-base">{`${question.qId}. ${question.qName}`}</div>
                <div>
                  {question.options.length > 0 &&
                    question.options.map((option: any, index: any) => {
                      const isChecked =
                      singleAnswers?.some(
                        (item: any) => item?.qId === question.qId && item?.answer === option
                      );
                      
                      return (
                        <div className="text-[#737E93]" key={option}>
                          <input
                            type="radio"
                            className="w-3 h-3 mr-1 "
                            name={question?.qId}
                            value={option}
                            checked={isChecked}
                            onChange={(e) =>
                              handleRadioChange(e.target.name, e.target.value)
                            }
                          />{" "}
                          {option}
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default observer(SingleChoiceQuestions);
