import  { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";


const WritingTimer = ({ store, resultStore }: any) => {

  const [seconds, setSeconds] = useState(store.timer);

  let minutes = Math.floor(seconds / 60);
  let second = seconds % 60;

  useEffect(() => {
    setInterval(() => {
      setSeconds((second: any) => second - 1);
    }, 1000);
  }, []);

  useEffect(() => {
    return () => {
        store.setTimer(seconds)
    }
  })

  if (seconds <= 0) {
    if (store.storeName === "reading") {
      resultStore.setStepManagement("part_test_completed", "Part 3", "");
    }
    if (store.storeName === "writing") {
      resultStore.setStepManagement("part_test_completed", "Part 2", "");
    }
    if (store.storeName === "listening") {
      resultStore.setStepManagement("part_test_completed", "Part 4", "");
    }
  }


  
  return (
    <div className="text-[#8F98A9] md:text-xl text-sm">
      {" "}
      {minutes.toString().padStart(2, "0")} :{" "}
      {second.toString().padStart(2, "0")}
    </div>
  );
};

export default observer(WritingTimer);
