import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import PartTestLayoutListening from "../PartTestLayoutListening";
import RenderingQuestionsListening from "../renderingQuestionsListening";
import useDragAndDropSentence from "../../../customHooks/useDragAndDropSentence";


const ListeningPart = observer(
  ({ store, stateData,  paraQuestions, partType, nextStep }: any) => {
    const { questions } = paraQuestions;
    const [dragAnswers, setDragAnswers] = useState([]);
    const [dragSentenceAnswers, setDragSentenceAnswers] = useState([]);
    const { handleDragSentenceStartFromAnswer, handleDragOver } = useDragAndDropSentence();

    const handleRadioChange = (qId: any, optionValue: any) => {
      const existingAnswerIndex = stateData.singleAnswer.findIndex(
        (answer: any) => answer.qId === qId
      );
      if (
        existingAnswerIndex !== -1 &&
        stateData.singleAnswer[existingAnswerIndex].answer === optionValue
      ) {
        const updatedAnswers = [...stateData.singleAnswer];
        updatedAnswers[existingAnswerIndex].answer = null; // Set to null or an empty string as per your requirement
        stateData.setSingleAnswer(updatedAnswers);
        return; // Exit the function early since we've handled the deselection
      }
      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...stateData.singleAnswer];
        updatedAnswers[existingAnswerIndex].answer = optionValue;
        stateData.setSingleAnswer(updatedAnswers);
      } else {
        stateData.setSingleAnswer([
          ...stateData.singleAnswer,
          { qId, answer: optionValue },
        ]);
      }
    };

    const handleRadioChangeTaskIdentifying = (qId: any, optionValue: any) => {
      const existingAnswerIndex =
        stateData.taskIdentifyingItemAnswers.findIndex(
          (answer: any) => answer.qId === qId
        );
      if (
        existingAnswerIndex !== -1 &&
        stateData.taskIdentifyingItemAnswers[existingAnswerIndex]
          .optionValue === optionValue
      ) {
        const updatedAnswers = [...stateData.taskIdentifyingItemAnswers];
        updatedAnswers[existingAnswerIndex].optionValue = null; // Set to null or an empty string as per your requirement
        stateData.setTaskIdentifyingItemAnswers(updatedAnswers);
        return; // Exit the function early since we've handled the deselection
      }
      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...stateData.taskIdentifyingItemAnswers];
        updatedAnswers[existingAnswerIndex].optionValue = optionValue;
        stateData.setTaskIdentifyingItemAnswers(updatedAnswers);
      } else {
        stateData.setTaskIdentifyingItemAnswers([
          ...stateData.taskIdentifyingItemAnswers,
          { qId, optionValue },
        ]);
      }
    };

    const handleCheckBoxChange = (
      qId: any,
      optionValue: any,
      isChecked: any
    ) => {
      const existingAnswerIndex = stateData.multipleAnswer.findIndex(
        (answer: any) => answer.qId === qId
      );
      if (existingAnswerIndex !== -1) {
        const updatedMultipleAnswers = [...stateData.multipleAnswer];
        const updatedOptionValue = Array.isArray(optionValue)
          ? optionValue
          : [optionValue];
        if (isChecked) {
          updatedMultipleAnswers[existingAnswerIndex].answer = [
            ...updatedMultipleAnswers[existingAnswerIndex].answer,
            ...updatedOptionValue,
          ];
        } else {
          updatedMultipleAnswers[existingAnswerIndex].answer =
            updatedMultipleAnswers[existingAnswerIndex].answer.filter(
              (value: any) => !updatedOptionValue.includes(value)
            );
        }
        stateData.setMultipleAnswer(updatedMultipleAnswers);
      } else {
        stateData.setMultipleAnswer([
          ...stateData.multipleAnswer,
          { qId, answer: [optionValue] },
        ]);
      }
    };


    const handlePlanAnswerChange = (evt: any, key: any) => {
      let updatedAnswers = [...(stateData.planDiagramAnswer || [])];

      let existingItemIndex = updatedAnswers.findIndex(
        (item) => item.qId === key
      );

      let answerItem = {
        qId: key,
        answer: evt.target.id,
      };

      if (existingItemIndex !== -1) {
        updatedAnswers[existingItemIndex] = answerItem;
      } else {
        updatedAnswers.push(answerItem);
      }
      stateData.setPlanDiagramAnswer(updatedAnswers);
    };

    const handleChangeShortAnswer = (qId: any, shortAnswer: any) => {
      const existingAnswerIndex = stateData.shortAnswer.findIndex(
        (answer: any) => answer.qId === qId
      );
      if (existingAnswerIndex !== -1) {
        const updateShortAnswerNew = [...stateData.shortAnswer];
        updateShortAnswerNew[existingAnswerIndex].answer = shortAnswer;
        stateData.setShortAnswer(updateShortAnswerNew);
      } else {
        stateData.setShortAnswer([
          ...stateData.shortAnswer,
          { qId,answer: shortAnswer },
        ]);
      }
    };
    const shortAnswerInputValues = (qId: any) => {
      if (stateData.shortAnswer && stateData.shortAnswer.length > 0) {
        let object = stateData.shortAnswer.find(
          (item: any) => item.qId === qId
        );
        if (object?.answer) {
          return object.answer;
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    const handleChangeOneWordAnswerSentenceCompletion = (
      qId: any,
      oneWordAnswer: any
    ) => {
      const existingAnswerIndex = stateData.senetenceComplete.findIndex(
        (answer: any) => answer.qId === qId
      );

      if (existingAnswerIndex !== -1) {
        const updateOneWordAnswerNew = [...stateData.senetenceComplete];
        updateOneWordAnswerNew[existingAnswerIndex].answer = oneWordAnswer;
        stateData.setSenetenceComplete(updateOneWordAnswerNew);
      } else {
        stateData.setSenetenceComplete([
          ...stateData.senetenceComplete,
          { qId, answer: oneWordAnswer },
        ]);
      }
    };
    const onWordAnswerInputValuesSentenceCompletion = (qId: any) => {
      if (
        stateData.senetenceComplete &&
        stateData.senetenceComplete.length > 0
      ) {
        let object = stateData.senetenceComplete.find(
          (item: any) => item.qId === qId
        );
        return object?.answer;
      }
    };
    const twoWordAnswerSummaryCompletionInputValues = (qId: any) => {
      if (
        stateData.summaryCompletionOneWordAnswers &&
        stateData.summaryCompletionOneWordAnswers.length > 0
      ) {
        let object = stateData.summaryCompletionOneWordAnswers.find(
          (item: any) => item.qId === qId.toString()
        );
        return object?.twoWoedAnswer;
      }
    };
    const handleDragStart = (e: any, displayedItem: any) => {
      const displayedItemString = JSON.stringify(displayedItem);
      e.dataTransfer.setData("displayedItem", displayedItemString);
      e.dataTransfer.setData("answer", "");
    };

    const handleSentenceDragStart = (e: any, displayedItem: any) => {
      const displayedItemStrings = JSON.stringify(displayedItem);
      e.dataTransfer.setData("displayedItem", displayedItemStrings);
      e.dataTransfer.setData("answer", "");
    };
  
    const handleDropFromDisplayedItem = (e: any) => {
      const draggedItemString = e.dataTransfer.getData("displayedItem");
      let draggedIndex: any;
      try {
        let draggedItem = JSON.parse(draggedItemString); // Try to parse the string
        draggedIndex = +draggedItem.newIndex;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
      const updateAnswers: any = [...dragAnswers];
      const updateQuestions = [...stateData.dragAndDropAnswers];
      const findItemDragged: any = updateQuestions.find(
        (item) => +item.newIndex === draggedIndex
      );
      const filteredQuestions = updateQuestions.filter(
        (item) => +item.newIndex !== draggedIndex
      );
      updateAnswers.push(findItemDragged.draggedItem);

      setDragAnswers(updateAnswers);
      stateData.setDragAndDropAnswers(filteredQuestions);
    };

    const handleDropSentenceFromDisplayedItem = (e: any) => {
      const draggedItemString = e.dataTransfer.getData("displayedItem");
      let draggedIndex: any;
      try {
        let draggedItem = JSON.parse(draggedItemString); // Try to parse the string
        draggedIndex = +draggedItem.newIndex;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
      const updateAnswers: any = [...dragSentenceAnswers];
      const updateQuestions = [...stateData.draggedCurrentSentence];
      const findItemDragged: any = updateQuestions.find(
        (item) => +item.newIndex === draggedIndex
      );
      const filteredQuestions = updateQuestions.filter(
        (item) => +item.newIndex !== draggedIndex
      );
      updateAnswers.push(findItemDragged?.draggedItem);

      setDragSentenceAnswers(updateAnswers);
      stateData.setDraggedCurrentSentence(filteredQuestions);
    };

    const handleDrop = (e: any, newIndex: any) => {
      const draggedItemString = e.dataTransfer.getData("displayedItem"); // Get the string representation
      let draggedItemObj;
      try {
        if (draggedItemString) {
          draggedItemObj = JSON.parse(draggedItemString); // Try to parse the string
        }
        // Rest of your code...
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
      const draggedAnswer = e.dataTransfer.getData("answer");

      const existingAnswerIndex = stateData.dragAndDropAnswers.findIndex(
        (answer: any) => answer.newIndex === newIndex
      );
      if (existingAnswerIndex !== -1) {
        //In this blank answer already filled
        if (draggedAnswer) {
          let updateAnswersArray: any = [...dragAnswers];
          updateAnswersArray.push(
            stateData.dragAndDropAnswers[existingAnswerIndex].draggedItem
          );
          let updateQuestyionsArray = [...stateData.dragAndDropAnswers];
          updateQuestyionsArray[existingAnswerIndex].draggedItem =
            draggedAnswer;

          let finalUpdatedAnswers = updateAnswersArray.filter(
            (item: any) => item != draggedAnswer
          );
          setDragAnswers(finalUpdatedAnswers);
          stateData.setDragAndDropAnswers(updateQuestyionsArray);
        } else {
          let draggedItem = draggedItemObj.draggedItem;
          let draggedIndex = draggedItemObj.newIndex;
          let newQuestionsArray: any = [...stateData.dragAndDropAnswers];
          let updateAnswersArray: any = [...dragAnswers];
          updateAnswersArray.push(
            newQuestionsArray[existingAnswerIndex].draggedItem
          );
          newQuestionsArray[existingAnswerIndex].draggedItem = draggedItem;

          let fArray = newQuestionsArray.filter(
            (item: any) => item.newIndex !== draggedIndex
          );
          setDragAnswers(updateAnswersArray);
          stateData.setDragAndDropAnswers(fArray);
        }
      } else if (draggedAnswer) {
          let updateAnswersArray = [...dragSentenceAnswers].filter(
            (item: any) => item !== draggedAnswer
          );
          let draggedItem = draggedAnswer;
          stateData.setDragAndDropAnswers([
            ...stateData.dragAndDropAnswers,
            { newIndex, draggedItem },
          ]);
          setDragAnswers(updateAnswersArray);
        } else {
          let draggedItem = draggedItemObj.draggedItem;
          let draggedIndex = draggedItemObj.newIndex;

          let newQuestionsArray = [...stateData.dragAndDropAnswers].filter(
            (item: any) => item.newIndex !== draggedIndex
          );
          stateData.setDragAndDropAnswers([
            ...newQuestionsArray,
            { newIndex, draggedItem },
          ]);
        }
    };
    const handleSentenceDrop = (e: any, newIndex: any) => {
      const draggedItemString = e.dataTransfer.getData("displayedItem"); // Get the string representation
      let draggedItemObj;
      try {
        if (draggedItemString) {
          draggedItemObj = JSON.parse(draggedItemString); // Try to parse the string
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
      const draggedAnswer = e.dataTransfer.getData("answer");

      const existingAnswerIndex = stateData.draggedCurrentSentence.findIndex(
        (answer: any) => answer.newIndex === newIndex
      );
      if (existingAnswerIndex !== -1) {
        //In this blank answer already filled
        if (draggedAnswer) {
          let updateAnswersArray: any = [...dragSentenceAnswers];
          updateAnswersArray.push(
            stateData.draggedCurrentSentence[existingAnswerIndex].draggedItem
          );
          let updateQuestyionsArray = [...stateData.draggedCurrentSentence];
          updateQuestyionsArray[existingAnswerIndex].draggedItem =
            draggedAnswer;

          let finalUpdatedAnswers = updateAnswersArray.filter(
            (item: any) => item != draggedAnswer
          );
          setDragSentenceAnswers(finalUpdatedAnswers);
          stateData.setDraggedCurrentSentence(updateQuestyionsArray);
        } else {
          let draggedItem = draggedItemObj.draggedItem;
          let draggedIndex = draggedItemObj.newIndex;
          let newQuestionsArray = [...stateData.draggedCurrentSentence];
          let updateAnswersArray: any = [...dragSentenceAnswers];
          updateAnswersArray.push(
            newQuestionsArray[existingAnswerIndex].draggedItem
          );
          newQuestionsArray[existingAnswerIndex].draggedItem = draggedItem;

          let fArray = newQuestionsArray.filter(
            (item: any) => item.newIndex !== draggedIndex
          );
          setDragSentenceAnswers(updateAnswersArray);
          stateData.setDraggedCurrentSentence(fArray);
        }
      } 
      else if (draggedAnswer) {
          let updateAnswersArray = [...dragSentenceAnswers].filter(
            (item: any) => item !== draggedAnswer
          );
          let draggedItem = draggedAnswer;
          stateData.setDraggedCurrentSentence([
            ...stateData.draggedCurrentSentence,
            { newIndex, draggedItem },
          ]);
          setDragSentenceAnswers(updateAnswersArray);
        } else {
          let draggedItem = draggedItemObj.draggedItem;
          let draggedIndex = draggedItemObj.newIndex;

          let newQuestionsArray = [...stateData.draggedCurrentSentence].filter(
            (item: any) => item.newIndex !== draggedIndex
          );
          stateData.setDraggedCurrentSentence([
            ...newQuestionsArray,
            { newIndex, draggedItem },
          ]);
        }
    };

    const handleChangeSummaryCompletionOneWordAnswers = (
      qId: any,
      twoWoedAnswer: any
    ) => {
      const existingAnswerIndex =
        stateData.summaryCompletionOneWordAnswers.findIndex(
          (answer: any) => answer.qId === qId
        );
      if (existingAnswerIndex !== -1) {
        const updateOneWordAnswerNew = [
          ...stateData.summaryCompletionOneWordAnswers,
        ];
        updateOneWordAnswerNew[existingAnswerIndex].twoWoedAnswer =
          twoWoedAnswer;
        stateData.setSummaryCompletionOneWordAnswers(updateOneWordAnswerNew);
      } else {
        stateData.setSummaryCompletionOneWordAnswers([
          ...stateData.summaryCompletionOneWordAnswers,
          { qId, twoWoedAnswer },
        ]);
      }
    };

    // Function to replace words with input fields

    // Function to replace {} with input fields
    const replaceWithInputFields = (
      text: string,
      indexOfSentence: any,
      qId: any
    ) => {
      // return elements;
      const parts = text.split("{}"); // Split the text by {} to get parts around the {}
      let elements: JSX.Element[] = [];

      parts.forEach((part, index) => {
        console.log(parts,"PARTS")
        elements.push(<span key={`text-${qId}`}>{part}</span>); // Add the text part
        if (index < parts.length - 1) {
          // Check if not the last part
          elements.push(
            <input
              key={`input-${qId}`}
              type="text"
              value={onWordAnswerInputValuesSentenceCompletion(qId)}
              placeholder={`Fill in the Blank ${qId}`}
              className="border border-[#E3E5E9] rounded-lg p-1 my-1"
              onChange={(e) =>
                handleChangeOneWordAnswerSentenceCompletion(qId, e.target.value)
              }
            />
          );
        }
      });

      return elements;
    };

    const replaceSentenceCompletionWithInputFields = (
      text: string,
      indexOfSentence: any,
      qId: any
    ) => {
      const separateParts = text.split("{}"); // Split the text by {} to get parts around the {}
      let jsxElements: JSX.Element[] = [];

      separateParts.forEach((part, index) => {
        jsxElements.push(<span key={`text-${qId}`}>{part}</span>); // Add the text part
        if (index < separateParts.length - 1) {
          // Check if not the last part
          jsxElements.push(
            <input
              key={`input-${qId}`}
              type="text"
              value={onWordAnswerInputValuesSentenceCompletion(qId)}
              placeholder={`Fill in the Sentence ${qId}`}
              className="border border-[#E3E5E9] rounded-lg p-1 my-1"
              onChange={(e) =>
                handleChangeOneWordAnswerSentenceCompletion(qId, e.target.value)
              }
            />
          );
        }
      });

      return jsxElements;
    };

    const tableInputHandler = (e: any) => {
      let questionId = e.target.id;
      let userAnswer = e.target.value;
      const existingIndex = stateData.tableCompletionAnswer.findIndex(
        (answer: any) => answer.qId == questionId
      );
      if (existingIndex !== -1) {
        const updateOneWordAnswer = [...stateData.tableCompletionAnswer];
        updateOneWordAnswer[existingIndex].answer = userAnswer;
        stateData.setTableCompletionAnswer(updateOneWordAnswer);
      } else {
        stateData.setTableCompletionAnswer([
          ...stateData.tableCompletionAnswer,
          { qId : questionId, answer:userAnswer },
        ]);
      }
    };
    
    const titleExtractor = (part : string) => {
      if (part === 'part1') {
        return "Part-1 (Section-1)";
      } else if (part === 'part2') {
        return "Part-2 (Section-2)";
      } else if (part === 'part3') {
        return "Part-3 (Section-3)";
      } else if (part === 'part4') {
        return "Part-4 (Section-4)";
      }
    }
    const partTypeExtractor = (part : string) => {
      if (part === 'part1') {
        return "Part1";
      } else if (part === 'part2') {
        return "Part2";
      } else if (part === 'part3') {
        return "Part3";
      } else if (part === 'part4') {
        return "Part4"
      }
    }

    useEffect(() => {
      store.setPartTypeListening(partType);
    }, []);

    
    return (
      <div>
        <PartTestLayoutListening
        buttonTitle={store.testType !== "full" ? "Submit" : "Next"}
        partType={partTypeExtractor(partType)}
        nextStep={nextStep}
        title={`Academic Listening ${titleExtractor(partType)}`}
        description={""}
        audio={paraQuestions?.audioFile?.resourceName} >
               <div className="flex border h-full border-[#E3E5E9] p-4 mx-6 mt-6 mb-28 bg-[#FFF] rounded-lg">
              <div className="w-full flex flex-col pl-5 ">
                <div className="flex flex-col">
                {questions &&
                  questions.length > 0 &&
                  questions.map((item: any, index: any) =>  (
                      <React.Fragment key={item["questionRefrenceId"] || index + 1}>
                        <div
                          className="text-[#3C424C] text-lg font-semibold"
                          key={item["questionRefrenceId"]}
                        >
                          {/* Questions {item["questionType"]} */}
                          Questions {item["questionRange"]}
                        </div>
                        <RenderingQuestionsListening
                          item={item}
                          singleAnswers={stateData.singleAnswer}
                          multipleAnswers={stateData.multipleAnswer}
                          tableCompletionAnswer={
                            stateData.tableCompletionAnswer
                          }
                          taskIdentifyingItemAnswers={
                            stateData.taskIdentifyingItemAnswers
                          }
                          PlanDiagramAnswer={stateData.planDiagramAnswer}
                          handlePlanAnswerChange={handlePlanAnswerChange}
                          handleRadioChange={handleRadioChange}
                          handleCheckBoxChange={handleCheckBoxChange}
                          replaceWithInputFields={replaceWithInputFields}
                          twoWordAnswerSummaryCompletionInputValues={
                            twoWordAnswerSummaryCompletionInputValues
                          }
                          handleChangeSummaryCompletionOneWordAnswers={
                            handleChangeSummaryCompletionOneWordAnswers
                          }
                          handleDragOver={handleDragOver}
                          handleDragStart={handleDragStart}
                          handleDrop={handleDrop}
                          handleDragStartFromAnswer={
                            () => ({})
                          }
                          handleDropFromDisplayedItem={
                            handleDropFromDisplayedItem
                          }
                          darggedCurrentItem={stateData.dragAndDropAnswers}
                          darggedCurrentSentence={
                            stateData.draggedCurrentSentence
                          }
                          setDarggedCurrentSentence={
                            stateData.setDraggedCurrentSentence
                          }
                          dragAnswers={dragAnswers}
                          setDragAnswers={setDragAnswers}
                          handleRadioChangeTaskIdentifying={
                            handleRadioChangeTaskIdentifying
                          }
                          replaceWithInputFieldsSentenceCompletion={
                            replaceSentenceCompletionWithInputFields
                          }
                          setDragSentenceAnswers={setDragSentenceAnswers}
                          handleSentenceDragStart={handleSentenceDragStart}
                          handleDragSentenceStartFromAnswer={
                            handleDragSentenceStartFromAnswer
                          }
                          handleDropSentenceFromDisplayedItem={
                            handleDropSentenceFromDisplayedItem
                          }
                          handleSentenceDrop={handleSentenceDrop}
                          dragSentenceAnswers={dragSentenceAnswers}
                          inputHandler={tableInputHandler}
                          handleChangeShortAnswer={handleChangeShortAnswer}
                          shortAnswerInputValues={shortAnswerInputValues}
                        />
                      </React.Fragment>
                    )
                  )}
                </div>
              </div>
            </div>
        </PartTestLayoutListening>
      </div>
    );
  }
);

export default ListeningPart;
