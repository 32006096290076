import React, { useEffect, useState } from 'react'
import WritingInstructionsCard from '../common/WritingInstructionsCard'
import { observer } from 'mobx-react-lite'

const TestSountIntroCard = observer((props:any)  => {
  const [isPlay,setIsPlay] = useState(false)
  const urlAudio= `https://ielts-app.s3.amazonaws.com/WhatsApp+Audio+2024-04-18+at+13.00.26_0efabc77.mp3`
    const {nextStep} =props;
    const soundHandler = () => {
      setIsPlay(true)
    }
    useEffect(() => {
      return () => {
        setIsPlay(false)
      }
    },[])
  return (
    <div className="flex justify-center items-center flex-col w-full">
      <h4 className="w-[80%] md:w-[25%] text-base md:text-2xl font-semibold md:font-normal mb-1">
        Test Sound
      </h4>
      <WritingInstructionsCard
        width="w-1/3"
        border={"#E3E5E9"}
        bg={"var(--neutral-white, #FFF)"}
      >
        <>
            <h4 className="mt-4">
              Put on your headphones and click on the Play Sound button to play
              a sample sound.
            </h4>
            <button
              className="bg-[#E31837] text-white py-2 mt-6 md:w-1/4 w-[80%] mx-auto rounded-full"
              onClick={soundHandler}
            >
              Play Sound
            </button>
            {isPlay && (
              <audio autoPlay src={urlAudio} onEnded={() => setIsPlay(false)}>
                <track
                  kind="captions"
                  src=""
                  srcLang="en"
                  label="No captions available"
                />
              </audio>
            )}

            <h4 className="mt-4">
              If you cannot hear the sound clearly, please inform your
              invigilator.
            </h4>
            <button
              onClick={nextStep}
              className="bg-[#E31837] text-white py-2 mt-6 md:w-1/4 w-[80%] mx-auto rounded-full"
            >
              Continue
            </button>
          </>
      </WritingInstructionsCard>
    </div>
  );
})

export default TestSountIntroCard