import { observer } from "mobx-react-lite";

const WritingInstructionsCard = observer(({ children,border,bg,width,height }: any) => {
    return (
      <div
        style={{
          background: bg,
          border: `1px solid ${border}`,
        }}
        className={`flex flex-col md:${width} ${height} p-6 rounded-2xl border border-solid border-[rgba(227, 24, 55, 0.30)] bg-gradient-to-br from-[#FFF9FA] via-transparent to-[#FFF] pb-10 h-auto mx-10 `}
      >
        {children}
      </div>
    );
  });
  
  export default WritingInstructionsCard;
  