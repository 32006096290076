import { WritingTestResultsApi } from "./../api/writing-test-results-api";
import { makePersistable } from "mobx-persist-store";
import { makeObservable, observable, action } from "mobx";
import { BaseTestResultsStore } from "./baseClass";

export class TestWritingResultsStore extends BaseTestResultsStore {
  graphData = {
    dates: [] as string[],
    scores: [] as number[],
  };
  getQuestionAnalysisPartOne: any = {};
  getQuestionAnalysisPartTwo: any = {};
  isGrapghDataLoading: boolean = false;
  writingPart1Answer = {
    text: "",
    img: "",
  };
  writingPart2Answer = {
    text: "",
    img: "",
  };
  partType: any = '';
  activeTabAiFeedback: number = 0;
  showless: boolean = false;
  accordian: any = {
    whatWentWrong: false,
    improvements: false,
    idealAnswer: false
  };

  constructor(private writingTestResultsApi: WritingTestResultsApi) {
    super("writingResults");
    makeObservable(this, {
      graphData: observable,
      getQuestionAnalysisPartOne: observable,
      getQuestionAnalysisPartTwo: observable,
      isGrapghDataLoading: observable,
      writingPart1Answer: observable,
      writingPart2Answer: observable,
      partType: observable,
      activeTabAiFeedback: observable,
      showless: observable,
      accordian: observable,
      setLoading: action,
      showContent: action,
      controlAccordian: action,
      setWritingPart1Answers: action,
      setWritingPart2Answers: action,
      testResultsUpdate: action,
      setActiveTabAiFeedback: action,
      setTestCompleted: action,
      freeTestCompletedClicked: action,
      getGraphData: action,
      getTestScoreWriting: action,
      getQuestionAnalysis: action,
      isFreeTestCompleted: action,
    });
    makePersistable(this, {
      name: "TestWritingResultsStore",
      properties: [
        "individualTestScorePart1",
        "showResults",
        "individualTestScorePart2",
        "completedTestType",
        "completedTestId",
        "averageScore",
        "getQuestionAnalysisPartOne",
        "getQuestionAnalysisPartTwo",
      ],
      storage: window.localStorage,
    });
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  async showContent(bool: boolean) {
    this.showless = bool;
  }

  async controlAccordian(section: any, bool: boolean) {
    if (section === 'all') {
      this.accordian.whatWentWrong = bool;
      this.accordian.improvements = bool;
      this.accordian.idealAnswer = bool;
    } else {
      this.accordian[section] = bool;
    }
  }

  async setWritingPart1Answers(value: any, img: any) {
    this.writingPart1Answer.text = value;
    this.writingPart1Answer.img = img;
  }

  async setWritingPart2Answers(value: any, img: any) {
    this.writingPart2Answer.text = value;
    this.writingPart2Answer.img = img;
  }

  async testResultsUpdate(querryParam: string, page: any) {
    try {
      this.isLoading = true;
      const response = await this.writingTestResultsApi.testResultsUpdate(querryParam, page);
      if (response.status === 200) {
        this.isLoading = false;
        this.testResults = response.data.data;
        this.isTestsFound = response.data.data.length === 0;
        return response;
      }
    } catch (e) {
      console.error(e);
    }
  }

  setActiveTabAiFeedback(index: number) {
    this.activeTabAiFeedback = index;
  }

  async setTestCompleted(tId: any, tType: any) {
    this.completedTestId = tId;
    this.completedTestType = tType;
  }

  async freeTestCompletedClicked(bool: boolean) {
    this.isFreeTestCompletedClicked = bool;
  }

  async getGraphData() {
    return await this.writingTestResultsApi.getAverageScoreData();
  }

  // async getAverageTestScore() {
  //   try {
  //     this.isGrapghDataLoading = true;
  //     const res = await this.writingTestResultsApi.getAverageScoreData();
  //     if (res.status === 200) {
  //       this.averageScore = res.data.avg_score || 0;
  //       const transformedData = res.data.data.reduce((result: any, item: any) => {
  //         result.scores.push(item.score);
  //         const dateObject = new Date(item.date);
  //         result.dates.push(formatDateToCustomFormat(dateObject));
  //         return result;
  //       }, { scores: [] as string[], dates: [] as number[] });
  //       this.graphData = transformedData;
  //       this.isGrapghDataLoading = false;
  //       this.hasUserTakenAnyTest = res.data.data.length > 0;
  //       return res.data.avg_score;
  //     }
  //   } catch (e) {
  //     console.log(e, "ERROR");
  //     this.isGrapghDataLoading = false;
  //   }
  // }

  async getTestScoreWriting(tstId: any, type: any) {
    this.isLoading = true;
    this.partType = type;
    this.isResultsLoading = true;
    try {
      const res = await this.writingTestResultsApi.getTestScoreApiWriting(tstId);
      if (res.status === 200) {
        this.isLoading = false;
        this.isResultsLoading = false;
        if (type === 'part1') {
          this.individualTestScorePart1 = res.data.part1;
        } else if (type === 'part2') {
          this.individualTestScorePart2 = res.data.part2;
        } else {
          this.individualTestScorePart1 = res.data.part1;
          this.individualTestScorePart2 = res.data.part2;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getQuestionAnalysis(tstId: any, typeOfTest: any) {
    if (typeOfTest === "full") {
      try {
        const res = await this.writingTestResultsApi.getQuestionAnalysisApi(tstId, "part1");
        if (res.status === 200) {
          this.getQuestionAnalysisPartOne = res.data;
        }
        const response = await this.writingTestResultsApi.getQuestionAnalysisApi(tstId, "part2");
        if (response.status === 200) {
          this.getQuestionAnalysisPartTwo = response.data;
        }
      } catch (e) {
        console.error(e);
      }
    } else if (typeOfTest === "part1") {
      try {
        const res = await this.writingTestResultsApi.getQuestionAnalysisApi(tstId, "part1");
        if (res.status === 200) {
          this.getQuestionAnalysisPartOne = res.data;
        }
      } catch (e) {
        console.error(e);
      }
    } else if (typeOfTest === "part2") {
      try {
        const res = await this.writingTestResultsApi.getQuestionAnalysisApi(tstId, "part2");
        if (res.status === 200) {
          this.getQuestionAnalysisPartTwo = res.data;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  isFreeTestCompleted() {
    this.isFreeTestCompletedClicked = true;
  }
}
