import apiSauce from "./api-sauce";

export class WritingTestResultsApi{
    async testResultsUpdate(params:any,page:number):Promise<any> {
        if(params){
            return await apiSauce.apisauce?.get(`/writing-progress?page=${page}&limit=9&type=${params}`)
        }else{
            return await apiSauce.apisauce?.get(`/writing-progress?page=${page}&limit=9`)
        }
        
    }


    async fetchFreeTestData():Promise<any>{
        return await apiSauce.apisauce?.get('/free-writing-tests')

    }

    async getAverageScoreData():Promise<any>{

        return await apiSauce.apisauce?.get('/get-writing-chart')
    }

    async getTestScoreApiWriting(tstId:any) :Promise<any>{
        return await apiSauce.apisauce?.get(`get-writing-test-score?test_id=${tstId}`)
    }

     async getQuestionAnalysisApi(tsId:any,part:any) :Promise<any> {
        return await apiSauce.apisauce?.get(`get-writing-question-analysis?test_id=${tsId}&type=${part}`)
     }
     async isFreeTestCompletedWriting(tId:any):Promise<any>{
       // return await apiSauce.apisauce?.get(`is_test_completed?test_id=${tId}`)
     }
}


