import React from 'react'
import { useStore } from '../../../Hooks/use-store'
import ListeningPart from './ReusableListeningPart';
import { observer } from "mobx-react-lite";

const ListeningPart4 = observer(({ nextStep } : any) => {
  const { startTestStoreListening } = useStore();
  const { part4Questions } = startTestStoreListening;
  const part4StateData = {
    singleAnswer : startTestStoreListening.part4SingleAnswer,
    setSingleAnswer : startTestStoreListening.setPart4SingleAnswer,
    multipleAnswer : startTestStoreListening.part4MultipleAnswer,
    setMultipleAnswer : startTestStoreListening.setPart4MultipleAnswer,
    taskIdentifyingItemAnswers : startTestStoreListening.part4TaskIdentifyingItemAnswers,
    setTaskIdentifyingItemAnswers : startTestStoreListening.setPart4TaskIdentifyingItemAnswers,
    noteCompletionAnswer : startTestStoreListening.part4NoteCompletionAnswer,
    setNoteCompletionAnswer : startTestStoreListening.setPart4NoteCompletionAnswer,
    planDiagramAnswer : startTestStoreListening.part4PlanDiagramAnswer,
    setPlanDiagramAnswer : startTestStoreListening.setPart4PlanDiagramAnswer,
    shortAnswer : startTestStoreListening.part4ShortAnswer,
    setShortAnswer : startTestStoreListening.setPart4ShortAnswer,
    senetenceComplete : startTestStoreListening.part4SenetenceComplete,
    setSenetenceComplete : startTestStoreListening.setPart4SenetenceComplete,
    summaryCompletionOneWordAnswers : startTestStoreListening.part4SummaryCompletionOneWordAnswers,
    setSummaryCompletionOneWordAnswers : startTestStoreListening.setPart4SummaryCompletionOneWordAnswers,
    dragAndDropAnswers : startTestStoreListening.part4DragAndDropAnswers,
    setDragAndDropAnswers : startTestStoreListening.setPart4DragAndDropAnswers,
    draggedCurrentSentence : startTestStoreListening.part4DraggedCurrentSentence,
    setDraggedCurrentSentence : startTestStoreListening.setPart4DraggedCurrentSentence,
    tableCompletionAnswer : startTestStoreListening.part4TableCompletionAnswer,
    setTableCompletionAnswer : startTestStoreListening.setPart4TableCompletionAnswer
  }
  
  return (
      <ListeningPart store={startTestStoreListening} partType={'part4'} stateData={part4StateData} paraQuestions={part4Questions} nextStep={nextStep}/>
  )
})

export default ListeningPart4;