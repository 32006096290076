import { UserApi } from "../api/userapi";
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import {  makeAutoObservable } from "mobx";
import {toast} from "react-toastify";

export class UserStore {
  isLoggedIn: boolean = false
  token: string = "";
  refreshToken:string = "";
  getRefreshTokenApiCalled = false;
  loading: boolean = false
  alreadyRegistered : boolean = true;
  refreshTokenPromise: Promise<string | null> | null = null;
  operationalUser:boolean = false;
  // mobileNumber: string = ''
  //studentName: string = ''
  //selectedCountry: string = ''
  //selectedMonth: string = ''
  isProUser: boolean = false
  //emailId: string = ''
  //when_Exam: string = ''

  studentDetails: any = {
    studentName: '',
    mobileNumber:'',
    emailId:'',
    selectedCountry:'',
    selectedMonth:'',
    when_Exam:''
  }

  userDashboards : Array<any> = [];
  dashboards : Array<any> = [];

  profilePic:string = '';
  isProfilePicExists:boolean = false;
  openEditProfile : boolean = false;
  //items:{label:string;value:string}[] = [] //updated items need to send update end point
  
  constructor(private userApi: UserApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'UserStore', properties: ['isLoggedIn','userDashboards', 'token', 'refreshToken', 'studentDetails', 'isProUser','profilePic','dashboards','operationalUser'], storage: window.localStorage });
  }

  
  updateStudentDetails(data:any){
    this.studentDetails = {...this.studentDetails,...data}
    }

  async request_otp(mobile: string) {
    this.loading = true;
    const payload = { mobile: mobile };
    const response = await this.userApi.requestOtp(payload);
    if(response.status === 200){
      this.loading = false;
    }
    return response

  }

 async setEditProfile(bool:boolean) {
 this.openEditProfile = bool;
 }

  async clearStoredDate() {
    this.isLoggedIn = false;
    this.token = "";
    this.studentDetails.mobileNumber = "";
    this.studentDetails.studentName = "";
    this.studentDetails.emailId =''
    await clearPersistedStore(this);
  }
async clearIsLoggedIn(){
  this.isLoggedIn = false;
}
  async verify_otp(mobile: string, otp: any) {
    try {
      this.studentDetails.mobileNumber = mobile;
      const payload = {
        mobile: mobile,
        hash: "",
        otp: otp,
        email:this.studentDetails.emailId
      }
      const response = await this.userApi.verifyOtp(payload);
      if (response.status === 200) {
        this.updateToken(response.data.token);
        this.updateRefreshToken(response?.data?.refresh_token);
        this.isLoggedIn = response.data.already_registered;
        this.dashboards = response.data?.user?.dashboard;
        this.userDashboards = this.decodeDashboards(response.data?.user?.dashboard); //updated user dashboard based on data ["WRITING","SPEAKING"]//
        let res = await this.userApi.loggedInUserApi();
        if (res.status === 200) {
          this.isProUser = res.data.is_pro;
          this.profilePic = res.data.profile_pic;
          this.studentDetails.studentName = res.data.name;
          this.studentDetails.emailId = res.data.email;
          this.studentDetails.when_Exam = res.data.when_exam_processed;
          this.studentDetails.selectedMonth = res.data.when_exam_processed; 
        }
      }
      return response
    } catch (e) {
      console.error(e, "error")
    }


  }

  decodeDashboards(dashboards : Array<any>){
    return dashboards.map(element => {
      switch (element) {
        case 4001:
          return 'SPEAKING';
        case 4002:
          return 'WRITING';
        case 4003:
          return 'READING';
        case 4004:
          return 'LISTENING';
        default:
          return 'UNKNOWN';
      }
    });
  }

  updateMobileNumber(phone: string) {
    this.studentDetails.mobileNumber= phone;
  }
  updateCountry(country: string) {
    this.studentDetails.selectedCountry=country;
  }
  updateMonth(month: string) {
    this.studentDetails.selectedMonth = month;
  }
  updateStudentName(sName: string) {
    this.studentDetails.studentName = sName;
  }

  updateEmailId(email: string) {
    this.studentDetails.emailId = email;
  }

  updateExamDay(exam: string) {
    this.studentDetails.when_Exam =exam;
  }

  updateAlreadyRegistered(value : boolean){
    this.alreadyRegistered = value;
  }
  updateToken(token:string){
    this.token = token;
  }
  updateRefreshToken(refreshToken:string){
    this.refreshToken = refreshToken;
  }
  updateGetRefreshTokenApiCalled(call : boolean){
    this.getRefreshTokenApiCalled = call;
  }
  updateRefreshTokenPromise(tokenPromise: Promise<string | null> | null){
   this.refreshTokenPromise = tokenPromise;
  }
  updateOperationalUser(value : boolean){
    this.operationalUser = value;
  }

  async updateUser() {
    this.loading = true
    if(Object.keys(this.studentDetails).length > 0){
      let itemsOfUser = [
        { field: "name", value:  this.studentDetails.studentName },
        { field: "country", value:  this.studentDetails.selectedCountry },
        { field: "mobile", value:  this.studentDetails.mobileNumber },
        { field: "email", value: this.studentDetails.emailId ? this.studentDetails.emailId : '' },
        {field:'when_exam', value:this.studentDetails.when_Exam ? this.studentDetails.when_Exam :''}
      ];
      if(this.studentDetails.when_Exam){
        itemsOfUser=[...itemsOfUser,{ field: "when_exam", value: this.studentDetails.when_Exam}];
      }
      let payloadItems = { items: itemsOfUser };
      let firstLogin = !this.alreadyRegistered;
      const response = await this.userApi.updateUserApi(payloadItems,firstLogin);
      if (response.status === 200) {
        this.isLoggedIn = true;
        this.loading = false;
        this.updateToken(response?.data?.token);
        this.updateRefreshToken(response?.data?.refresh_token);
      }
      this.alreadyRegistered = true;
      return response
    }
    
  }

  async deleteUser(){
    this.loading = true
    const response = await this.userApi.deleteUserApi();
    if(response.status === 200 ){
      toast.success("Account Deleted Successfully");
      this.loading = false;
    }
    return response;
  }

async uploadProfilePicture(file:any){
  this.loading = true
  try{
    const res = await this.userApi.uploadProfilePictureApi(file);
    if(res.status === 200){
      this.profilePic = res.data.url;
      this.isProfilePicExists =true;
      this.loading=false
    }
    return res
  }catch(e){
    console.error(e);
    this.loading = false
  }
  
}
async updateOAuthMobile (payLoad:any){
  try{
    this.studentDetails.emailId = payLoad.email
  this.studentDetails.mobileNumber = payLoad.mobile
  let res = await this.userApi.updateOAuthMobile(payLoad)
  if(res.status === 200){
    this.updateToken(res?.data?.token);
    this.updateRefreshToken(res?.data?.refresh_token);
    this.dashboards = res.data?.user?.dashboard;
    this.userDashboards = this.decodeDashboards(res.data?.user?.dashboard);
    this.isLoggedIn = res.data.already_registered;
    if(!res.data.isNavigateOTP){
      let loggedInRes = await this.userApi.loggedInUserApi();
      if (loggedInRes.status === 200) {
        this.isProUser = loggedInRes.data.is_pro;
        this.profilePic = loggedInRes.data.profile_pic;
        this.studentDetails.studentName = loggedInRes.data.name;
        this.studentDetails.emailId = loggedInRes.data.email;
        this.studentDetails.when_Exam = loggedInRes.data.when_exam_processed;
        this.studentDetails.selectedMonth = loggedInRes.data.when_exam_processed; 
      }
    }
    
       
  }
  return res
  }catch(e){
    console.log(e,"OAUTH ERROR")
  }
  
  }

async deleteProfilePic(){
  this.loading = true;
  const res = await this.userApi.deleteProfilePicApi();
  if(res.status === 200 ){
    this.profilePic = "";
    this.loading = false;
  }
  return res
}
async uploadPicAgain(){
  this.isProfilePicExists = false
}
  async getRefreshToken(refreshToken:string){
    this.updateGetRefreshTokenApiCalled(true);
    let payload = {
      "token": refreshToken
    }
    let newToken = null;
    try{
      let response = await this.userApi.getRefreshToken(payload);
      if(response.status === 200){
        this.updateRefreshToken(response?.data?.refresh_token);
        this.updateToken(response?.data?.token);
        newToken = response?.data?.token;
      }else{
        window.location.href = '/login';
        console.error("refresh token failed");
      }
    }catch(err){
      window.location.href = '/login';
      console.error("refresh token failed",err);
    }finally{
      setTimeout(() => this.updateGetRefreshTokenApiCalled(false),10000);
      this.updateRefreshTokenPromise(null); 
    }
    return newToken;
  }
  async getMemoizedRefreshToken(refreshToken:string){
    if(!this.getRefreshTokenApiCalled || !this.refreshTokenPromise){ //to avoid the unnecessary new calls
      this.refreshTokenPromise =  this.getRefreshToken(refreshToken);
    }
    return this.refreshTokenPromise;
  }
}